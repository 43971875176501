<template>
  <div
    v-tc-loader-bar="usingSkeletonLoader"
    class="goal-result-bar-graph tc-loader-bar-wrapper p-card"
  >
    <p
      class="mb-4"
      style="font-size: 1.2rem; display: inline-flex;"
    >
      <span :title="$pgettext('Tooltip — Goal Icon', 'Målet är att:')">
        <GoalIcon class="mr-2" />
      </span>
      <span
        style="line-height: 1.75rem;"
        v-html="goalText"
      />
    </p>
    <div class="tc-card-message-wrapper">
      <div
        v-if=" hasNoGraph"
        class="tc-card-message-overlay px-2"
      >
        <template v-if="timeUntilGoalStarts">
          <p
            class="mb-2"
            v-text="`${$gettext('Mätningen av målet börjar')} ${timeUntilGoalStarts}.`"
          />
          <p v-text="$gettext('Vänta eller ställ in ett tidigare startdatum för att se en graf.')" />
        </template>
        <template v-else-if="endVal?.value === null">
          <p
            class="mb-2"
            v-text="$gettext('Inget mål inställt ännu.')"
          />
          <p v-text="$gettext('Ställ in ett målvärde för att se en graf.')" />
        </template>
        <div v-else-if="usingSkeletonLoader" />
        <template v-else>
          <p
            class="mb-2"
            v-text="$gettext('Det finns ingen data.')"
          />
          <p v-text="$gettext('Prova att byta filter för att se en graf.')" />
        </template>
      </div>
      <div class="tc-card-message-underlay">
        <transition name="slide-right-fade">
          <p
            v-if="goalProgressText"
            class="mb-0 ml-4"
            :class="{ 'tc-loading-text tc-loading-text-inherit tc-loading-text-on-dark size-medium': hasNoGraph }"
            v-html="hasNoGraph ? '&nbsp;' : goalProgressText"
          />
        </transition>
        <GoalBar
          v-if="hasNoGraph"
          :current="{ value: 5, displayValue: '&nbsp;' }"
          :start="{ value: 0, displayValue: '—' }"
          :end="{ value: 10, displayValue: '—' }"
          :skeleton-loader="true"
        />
        <GoalBar
          v-else
          :current="goalVals?.currentVal || {}"
          :start="goalVals?.startVal || {}"
          :end="endVal"
          :goal-unit="metadataGoal.unit"
          :skeleton-loader="usingSkeletonLoader"
        />
        <p
          class="tc-ranking-gauge-description mt-6"
          :class="{ 'tc-loading-text': hasNoGraph }"
          :style="hasNoGraph ? 'width: 100%;' : ''"
          v-text="goalHelpText"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { set, isPast } from 'date-fns';
import { dateLabels, getKeyByDate, getAbsoluteDatesFromDate } from 'Utils/date';
import { format, formatDistanceToNowStrict } from 'Utils/dateHelpers';
import { getGoalTypeFromDate } from 'Utils/goal';
import { wrapGoalVal } from 'Components/parts/explore/GoalResult';
import GoalBar from 'Components/parts/graph/GoalBar';
import GoalIcon from 'Components/parts/icons/GoalIcon';

export default {
  name: 'GoalResultBarGraph',
  components: {
    GoalBar,
    GoalIcon,
  },
  props: {
    card: {
      type: Object,
      required: true,
    },
    board: Object, // Unused
    loading: {
      type: Boolean,
      default: true,
    },
    hasData: {
      type: Boolean,
      default: false,
    },
    previewMode: { // Unused
      type: Boolean,
      default: false,
    },
    question: Object,
    contextFilter: Object,
    compiledFilter: Object,
    compiledBenchmark: Object,
    goalVals: Object,
  },
  emits: ['update-drag-area'],
  computed: {
    ...mapGetters([
      'segmentId',
      'segmentName',
      'customerName',
    ]),
    goalType() { return getGoalTypeFromDate(this.compiledFilter?.date); },
    graphType() { return this.card.metadata.graphType.selected; },
    metadataGoal() { return this.card.metadata?.goal || {}; },
    endVal() {
      const score = this.metadataGoal?.endVal ?? null;
      const date = this.goalType === 'oneTime' ? format(new Date(getAbsoluteDatesFromDate(this.compiledFilter.date).dateLte), 'yyyy-MM') : null;
      return wrapGoalVal({ score, respondent_count: score, date }, this.metadataGoal?.unit);
    },
    timeUntilGoalStarts() {
      const { date } = this.goalVals?.startVal ?? {};
      const startDate = date && new Date(date);
      if (startDate && !isPast(startDate)) {
        return formatDistanceToNowStrict(startDate, { addSuffix: true });
      }
      return false;
    },
    readableDate() {
      const filterDate = this.compiledFilter?.date;
      if (!filterDate) return '';

      if (filterDate.type === 'relative') {
        if (filterDate.span?.allTime === true) return this.$pgettext('Concat — readable date', 'sen ni började samla in data');
        return this.$pgettext('Concat — readable date', 'under %{dateSpan}', { dateSpan: dateLabels(true)[getKeyByDate(filterDate)] });
      } if (filterDate.dateGte && filterDate.dateLte) {
        const dateGte = format(set(new Date(filterDate.dateGte), { date: 1 }), 'yyyy-MM');
        const dateLte = format(set(new Date(filterDate.dateLte), { date: 1 }), 'yyyy-MM');
        return this.$pgettext('Concat — readable date', 'under perioden %{dateGte} — %{dateLte}', { dateGte, dateLte });
      } if ( // ? Never happens, as we always get eg. gte: 2022-XX-01, lte: 2022-XX-31 (one full month)
        (filterDate.dateGte && !filterDate.dateLte)
        || (filterDate.dateGte === filterDate.dateLte && filterDate.dateGte !== null)
      ) {
        const dateGte = format(set(new Date(filterDate.dateGte), { date: 1 }), 'yyyy-MM');
        return this.$pgettext('Concat — readable date', 'från %{dateGte}', { dateGte });
      }
      return '';
    },
    goalText() {
      const { unit } = this.metadataGoal;
      if (this.endVal?.value === undefined || unit === undefined) return '';
      if (this.endVal.value === null) return this.$pgettext('Goal Text — No endVal', 'Inget mål inställt');

      let goalUnit = '';
      let isAvg = false;
      if (unit === 'promoter') goalUnit = this.$npgettext('Concat — goalUnit', 'ambassadör', 'ambassadörer', this.endVal.value);
      else if (unit === 'passive') goalUnit = this.$npgettext('Concat — goalUnit', 'neutral', 'neutrala', this.endVal.value);
      else if (unit === 'detractor') goalUnit = this.$npgettext('Concat — goalUnit', 'kritiker', 'kritiker', this.endVal.value);
      else if (unit === 'cnpsValue' && this.goalType === 'rollingAvg') {
        goalUnit = this.$pgettext('Concat — goalUnit', 'i genomsnitt');
        isAvg = true;
      }
      const avgConcat = isAvg ? this.$pgettext('Concat — isAvg', '​') : ''; // 'a ' in eng, zero-width space in swe

      return this.$pgettext(
        'Goal Text — Concatenated with dateSpan',
        'Nå %{avgConcat}<span class="tc-color-geyser strong">%{endVal} %{goalUnit}</span> <span style="text-transform: lowercase;">%{dateSpan}</span>',
        { avgConcat, endVal: this.endVal.displayValue, goalUnit, dateSpan: this.readableDate },
      );
    },
    trendDirection() {
      if (this.goalVals?.trendVal?.value !== undefined) {
        if (this.goalVals.trendVal.value > 0) return 1; // positive
        if (this.goalVals.trendVal.value < 0) return -1; // negative
        return 0; // neutral
      }
      return null; // can’t decide
    },
    goalProgressText() {
      if (this.trendDirection === null) return '';
      const trendVal = this.goalVals?.trendVal || null;
      const goalVal = this.goalVals?.currentVal || null;
      if (goalVal?.value === null || trendVal?.value === null) return '';

      trendVal.absValue = Math.abs(trendVal.value);// ? Mutation! Turn signed numbers into unsigned. The difference should be lingual instead.
      let unit = this.$npgettext('Concat — goalUnit', 'poäng', 'poäng', trendVal.absValue);
      if (this.metadataGoal.unit === 'promoter') unit = this.$npgettext('Concat — goalUnit', 'ambassadör', 'ambassadörer', trendVal.absValue);
      if (this.metadataGoal.unit === 'passive') unit = this.$npgettext('Concat — goalUnit', 'passiv', 'passiva', trendVal.absValue);
      if (this.metadataGoal.unit === 'detractor') unit = this.$npgettext('Concat — goalUnit', 'kritiker', 'kritiker', trendVal.absValue);

      /* eslint-disable brace-style */
      if (this.trendDirection === 1) { return this.$pgettext(
        'Concat — Goal progress text, positive',
        '<span class="tc-color-green"><i class="zmdi zmdi-trending-up mr-1"></i>Ökade %{val} %{unit}</span> senaste månaden',
        { val: trendVal.absValue, unit },
      ); }
      if (this.trendDirection === -1) { return this.$pgettext(
        'Concat — Goal progress text, negative',
        '<span class="tc-color-red"><i class="zmdi zmdi-trending-down mr-1"></i>Minskade %{val} %{unit}</span> senaste månaden',
        { val: trendVal.absValue, unit },
      ); }
      if (this.trendDirection === 0) { return this.$pgettext(
        'Concat — Goal progress text, neutral',
        '<span class="tc-color-grey"><i class="zmdi zmdi-trending-flat mr-1"></i>Samma antal %{unit}</span> senaste månaden',
        { unit },
      ); }
      return '';
      /* eslint-enable brace-style */
    },
    goalHelpText() {
      const { currentVal, startVal } = this.goalVals || {};
      if (
        this.loading
        || this.timeUntilGoalStarts
        || currentVal?.value === undefined
        || startVal?.value === undefined
        || this.endVal?.value === undefined
        || this.endVal.value === null
      ) {
        return null;
      }

      let trendText = '';
      if (currentVal.date !== startVal.date) { // ? No need to show if they just started measuring
        if (this.trendDirection === 1) trendText = this.$pgettext('Help — Goal trend positive', 'Ni ökade sen förra månaden.');
        else if (this.trendDirection === -1) trendText = this.$pgettext('Help — Goal trend negative', 'Ni minskade jämfört med förra månaden.');
        else if (this.trendDirection === 0) trendText = this.$pgettext('Help — Goal trend neutral', 'Ni ligger på samma poäng som förra månaden.');
      }

      if (currentVal.value >= this.endVal.value) {
        if (this.metadataGoal?.unit === 'cnpsValue' && this.endVal?.date > this.goalVals?.currentVal?.date) return this.$pgettext('Help — Goal gte endVal currDate lt startDate CnpsValue', 'I fas, fortsätt likadant för att nå målet!');
        return this.$pgettext('Help — Goal gte endVal', 'Grymt jobbat, ni har klarat målet!');
      }
      if (currentVal.value > startVal.value) return this.$pgettext('Help — Goal gt startVal', 'Fortsätt som ni gör, det går generellt sett i rätt riktning. %{trendText}', { trendText });
      if (currentVal.value < startVal.value) return this.$pgettext('Help — Goal lt startVal', 'Kolla över ifall ni kan förbättra er, ni ligger under startvärdet. %{trendText}', { trendText });
      return this.$pgettext('Help — Goal === startVal', 'Leta efter sätt att förbättra på och ge det lite tid. %{trendText}', { trendText });
    },
    hasNoGraph() {
      return !this.hasData
        && !this.loading
        || (
          this.timeUntilGoalStarts
          || this.goalVals?.currentVal?.value === undefined
          || this.goalVals.currentVal.value === null
          || this.goalVals?.startVal?.value === undefined
          || this.goalVals.startVal.value === null
          || this.endVal?.value === null
        );
    },
    usingSkeletonLoader() {
      return this.loading || this.goalVals?.currentVal === undefined;
    },
  },
  watch: {
    async usingSkeletonLoader() {
      await this.$nextTick();
      this.$emit('update-drag-area');
    },
    loading(newVal, oldVal) {
      if (newVal !== oldVal) this.$emit('update-drag-area');
    },
  },
};
</script>
