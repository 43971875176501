<template>
  <thead>
    <tr>
      <th
        v-tooltip="{ content: labelHeaderTranslationError?.toString?.() || '' }"
        class="cursor-pointer"
        :title="`${sortText('label')}${labelHeader}`"
        @click="sort('label', order, false)"
      >
        {{ labelHeader }}
        <i-material-symbols-translate
          v-if="labelHeaderTranslationError"
          aria-label="Error"
          class="ml-1 mr-1 valign-middle tc-color-red"
        />
        <sort-icon
          :active="sortSettings.sortBy === 'label'"
          :desc="isDesc"
        />
      </th>
      <th
        v-for="column in columns"
        :key="column.value"
        v-tooltip.bottom-start="{
          content: column.numberCell
            ? `${sortText(column.value)}${column.title || column.label}`
            : '',
          delay: { show: 100, hide: 0 }
        }"
        :class="cellClasses(column)"
        :title="`${sortText(column.value)}${column.title || column.label}`"
        @click="sort(column.value, order, column.showPercentages)"
      >
        <sort-icon
          :active="sortSettings.sortBy === column.value"
          :desc="isDesc"
        />
        {{ column.label }}
      </th>
    </tr>
  </thead>
</template>

<script>
import { pick } from 'lodash-es';
import { klona } from 'klona';
import { rowSort } from 'Utils/stats';
import SortIcon from 'Components/parts/icons/SortIcon';

export default {
  components: {
    SortIcon,
  },
  model: {
    prop: 'rows',
    event: 'update:rows',
  },

  props: {
    labelHeader: String,
    labelHeaderTranslationError: [Error, String, null],
    columns: Array,
    rows: Array,
    benchmarks: Array,
    sortSettings: {
      type: Object,
      default: () => ({ sortBy: 'cnps', direction: 'desc', usePercentages: false }),
    },
  },
  emits: ['update:rows', 'update:benchmarks', 'update:sort'],
  data() {
    return {
      originalRows: [],
      originalBenchmarks: [],
    };
  },
  computed: {
    order() {
      return this.sortSettings.direction;
    },
    isDesc() {
      return this.sortSettings.direction === 'desc';
    },
    watchedTrigger() {
      return {
        labelHeader: this.labelHeader,
        columns: this.columns,
      };
    },
  },
  watch: {
    watchedTrigger: {
      handler(newVal, oldVal) {
        if (JSON.stringify(newVal) === JSON.stringify(oldVal)) return;
        this.setup(this.sortSettings);
      },
      deep: true,
    },
    sortSettings: {
      handler(newVal, oldVal) {
        if (newVal.sortBy === oldVal.sortBy && newVal.direction === oldVal.direction) return;
        this.setup(newVal, false);
      },
      deep: true,
    },
  },
  created() {
    this.setup(this.sortSettings, true);
  },
  methods: {
    sortText(columnValue) {
      if (this.sortSettings.sortBy === columnValue && !this.isDesc) return this.$pgettext('Sort — desc', '(Sorteras fallande) ');
      if (this.sortSettings.sortBy === columnValue && this.isDesc) return this.$pgettext('Sort — asc', '(Sorteras stigande) ');
      return '';
    },
    resetSort() {
      this.sortEmits(this.originalRows, this.originalBenchmarks, '', 'desc', false);
    },

    sort(key, order = 'desc', percentages = false) {
      const { sortBy } = this.sortSettings;
      if (['cnpsBar', 'benchmarks'].includes(key)) return;
      if (key === sortBy && order !== 'asc') {
        this.resetSort();
        return;
      }

      const newOrder = key === sortBy ? 'desc' : 'asc';
      const isNewOrderDesc = newOrder === 'desc';
      const { rows, benchmarks } = this.getSortedData(key, isNewOrderDesc, percentages);
      this.sortEmits(rows, benchmarks, key, newOrder, percentages);
    },
    getSortedData(key, isDesc, percentages) {
      const benchmarks = this.benchmarks.map((v, i) => Object.assign(v, this.rows[i])) // koppla på alla props från rows
        .sort(rowSort(key, !isDesc, percentages)) // sortera på samma vis som rows
        .map((o) => pick(o, ['global', 'customer'])); // och filtrera sedan bort alla props från rows
      const rows = [...this.rows].sort(rowSort(key, !isDesc, percentages));
      return { rows, benchmarks };
    },
    sortEmits(rows, benchmarks, key, order = 'desc', percentages = false) {
      this.$emit('update:sort', { sortBy: key, direction: order, usePercentages: percentages });
      this.$emit('update:rows', rows);
      this.$emit('update:benchmarks', benchmarks);
    },
    cellClasses(column) {
      return {
        'sort-asc': this.sortSettings.sortBy === column.value && !this.isDesc,
        'sort-desc': this.sortSettings.sortBy === column.value && this.isDesc,
        'tc-cnps-cell': column.value === 'cnps',
        'tc-cnps-bar-cell': column.value === 'cnpsBar',
        'tc-benchmarks-cell': column.value === 'benchmarks',
        'tc-table-number-cell': column.numberCell,
        'tc-table-expanded-header': column.numberCell && column.expandedHeader,
        'sort-btn': column.value !== 'cnpsBar',
      };
    },
    setOriginals(rows, benchmarks) {
      this.originalRows = klona(rows);
      this.originalBenchmarks = klona(benchmarks);
    },
    setup(sortSettings = {}, shouldSetOriginals = false) {
      if (shouldSetOriginals) {
        this.setOriginals(this.rows, this.benchmarks);
      }
      if (sortSettings.sortBy) {
        const { rows, benchmarks } = this.getSortedData(sortSettings.sortBy, this.isDesc, sortSettings.usePercentages);
        this.$emit('update:rows', rows);
        this.$emit('update:benchmarks', benchmarks);
      }
    },
  },
};
</script>
