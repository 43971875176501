<template>
  <li
    class="tc-query-item"
    :class="{ 'tc-query-item-editable': removable || editable }"
  >
    <div
      v-if="!isGettingTranslation"
      class="tc-query-item-inner py-2"
      :class="{ 'collection': isCollection}"
    >
      <div class="flex-apart gap-8">
        <div class="tc-query-item-actions">
          <template v-if="editable && name !== 'or'">
            <button
              v-if="isEditing"
              key="tc-query-item-action-save"
              v-tooltip="$pgettext('Tooltip — Query Item done', 'Klar')"
              class="btn btn-icon btn-icon-sm"
              @click.prevent.stop="edit(name)"
            >
              <i class="zmdi zmdi-check" />
            </button>
            <button
              v-else
              key="tc-query-item-action-edit"
              v-tooltip="$pgettext('Tooltip — Query Item edit', 'Ändra')"
              class="btn btn-icon btn-icon-sm"
              @click.prevent.stop="edit(name)"
            >
              <i class="zmdi zmdi-edit" />
            </button>
          </template>
          <button
            v-if="removable"
            v-tooltip="$pgettext('Tooltip — Query Item delete', 'Ta bort')"
            class="btn btn-icon btn-icon-sm"
            @click.prevent.stop="remove(name)"
          >
            <i class="zmdi zmdi-delete" />
          </button>
        </div>
        <tabs-navigation
          v-if="isEditing"
          v-model="activeTab"
          class="tabs-navigation-xs relative"
          :tabs="tabs"
        />
      </div>

      <template v-if="isCollection">
        <ul :class="`tc-query-list-${name}`">
          <template v-for="(query, index) in value">
            <segment-query-item
              v-for="(val, key) in query"
              :key="key"
              :name="key"
              :value="val"
              :depth="depth + 1"
              :editable="false"
              :removable="false"
              concat-value="or"
              :is-last="index === value.length - 1"
              @convert="convert"
            />
          </template>
        </ul>
      </template>

      <template v-else-if="isArray">
        <template v-if="isEditing">
          <i-material-symbols-translate
            v-if="error"
            v-tooltip="{
              content: () => error,
              strategy: 'fixed',
              container: 'body',
            }"
            aria-label="Error"
            class="mr-1 valign-middle tc-color-red"
          />
          <span class="tc-query-item-key">{{ cleanName }}</span> <span class="tc-query-item-operator subtle-text strong"> {{ condition }}: </span>
          <select-filter-type-value
            ref="selectFilterTypeValue"
            v-model="itemValue"
            :filter-type="filterType"
            :filter-type-key="cleanName"
            :customer="true"
          />
        </template>
        <template v-else>
          <i-material-symbols-translate
            v-if="error"
            v-tooltip="{
              content: () => error,
              strategy: 'fixed',
              container: 'body',
            }"
            aria-label="Error"
            class="mr-1 valign-middle tc-color-red"
          />
          <span class="tc-query-item-key">{{ cleanName }} <span class="tc-query-item-operator subtle-text"> {{ condition }}: </span> </span>
          <span
            v-for="(val, i) in value"
            :key="val.id"
          >
            <span />
            <span
              v-if="isProxy"
              class="tc-query-item-value"
            > {{ prettyProxy(val) }}</span>
            <span
              v-else-if="isLevel"
              class="tc-query-item-value"
            >{{ translateTerm(val) }}</span>
            <span
              v-else
              class="tc-query-item-value"
            >{{ val }}</span> <span
              v-if="i + 1 < value.length"
              class="tc-query-item-operator subtle-text"
            > {{ $gettext('eller') + ' ' }} </span>
          </span>
        </template>
      </template>

      <template v-else>
        <template v-if="isEditing">
          <span class="tc-query-item-key">{{ cleanName }}</span> <span class="tc-query-item-operator subtle-text"> {{ condition }}: </span>
          <select-filter-type-value
            ref="selectFilterTypeValue"
            v-model="itemValue"
            :filter-type="filterType"
            :filter-type-key="cleanName"
            :customer="true"
          />
        </template>
        <template v-else>
          <i-material-symbols-translate
            v-if="error"
            v-tooltip="{
              content: () => error,
              strategy: 'fixed',
              container: 'body',
            }"
            aria-label="Error"
            class="mr-1 valign-middle tc-color-red"
          />
          <span
            class="tc-query-item-key"
          >{{ cleanName }} <span class="tc-query-item-operator subtle-text"> {{ condition }}: </span> </span>
          <span
            class="tc-query-item-value"
          > {{ value }}</span>
        </template>
      </template>
    </div>
    <div
      v-if="isGettingTranslation"
      class="tc-query-item-inner py-2"
    >
      <span
        class="tc-query-item-key tc-loading-text tc-loading-text-inherit tc-loading-text-on-dark size-small"
      >{{ cleanName }}
        <span
          class="tc-query-item-operator tc-loading-text tc-loading-text-inherit tc-loading-text-on-dark size-small"
        >
          {{ condition }}:
        </span>
      </span>
      <span
        class="tc-query-item-value tc-loading-text tc-loading-text-inherit tc-loading-text-on-dark size-small"
      > {{ value }}</span>
    </div>
    <div
      v-if="!isLast"
      class="tc-hr-wrap"
    >
      <hr
        class="tc-hr-text"
        :data-text="collectionNames[concatValue]"
      >
      <button
        v-if="depth <= 1"
        v-tooltip="convertTooltip"
        class="tc-hr-action btn btn-link btn-icon-text"
        @click.prevent.stop="convert({ tag: name, depth })"
      >
        <i class="zmdi zmdi-swap" />
        {{ convertToConcat }}
      </button>
    </div>
  </li>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { isArray, isNumber } from 'lodash-es';
import { unprefixTag, unsuffixTag } from 'Utils/api';
import { translateTerm } from 'Utils/general';
import {
  FILTER_OPERATOR_EXCLUDE,
  FILTER_OPERATOR_INCLUDE,
  getPureFilterKey,
  getFilterOperator,
  getPrefixedFilterKey,
} from 'Utils/filterBox';
import SelectFilterTypeValue from 'Components/parts/filters/SelectFilterTypeValue';
import TabsNavigation from 'Components/parts/widgets/TabsNavigation';

export default {
  name: 'SegmentQueryItem',
  components: {
    SelectFilterTypeValue,
    TabsNavigation,
  },
  props: {
    name: String,
    value: [String, Object, Array, Number],
    isLast: Boolean,
    depth: {
      type: Number,
      default: 0,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    removable: {
      type: Boolean,
      default: true,
    },
    concatValue: {
      type: String,
      default() {
        return 'and';
      },
    },
  },
  expose: ['disableEdit'],
  emits: ['edit', 'remove', 'convert'],
  data() {
    return {
      itemValue: [],
      collectionNames: {
        and: this.$gettext('och'),
        or: this.$gettext('eller'),
      },
      conditionNames: {
        is: this.$gettext('är'),
        isNot: this.$gettext('är inte'),
      },
      isEditing: false,
      loadingTagKeyTranslation: new Set(),
      error: null,
      activeTab: getFilterOperator(this.name),
      tabs: [
        {
          value: FILTER_OPERATOR_INCLUDE,
          title: this.$gettext('Inkludera'),
        },
        {
          value: FILTER_OPERATOR_EXCLUDE,
          title: this.$gettext('Exkludera'),
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'getProxyById',
      'customerTagKeyVal',
    ]),
    isGettingTranslation() {
      if (this.isProxy || this.isLevel) return false;
      const cleanTag = unsuffixTag(unprefixTag(getPureFilterKey(this.name)));
      if (['or', 'and'].includes(cleanTag)) return false;
      return !this.customerTagKeyVal?.[cleanTag] || false;
    },
    isProxy() {
      return getPureFilterKey(this.name) === 'customer_proxy_id__in';
    },
    isLevel() {
      return ['level__in', 'level'].includes(getPureFilterKey(this.name));
    },
    filterType() {
      if (this.isProxy) return 'customerProxies';
      if (this.isLevel) return 'level';
      return 'tags';
    },
    isCollection() {
      return typeof this.name === 'string' && this.collectionNames[this.name] !== undefined;
    },
    isArray() {
      return isArray(this.value);
    },
    convertToConcat() {
      return this.concatValue === 'and' ? this.collectionNames.or : this.collectionNames.and;
    },
    convertTooltip() {
      return this.$gettextInterpolate(
        this.$gettext('Byt till %{concat}'),
        { concat: String(this.convertToConcat).toUpperCase() },
      );
    },
    condition() {
      if (this.isCollection) return '';
      if (this.activeTab === FILTER_OPERATOR_INCLUDE) return this.conditionNames.is;
      if (this.activeTab === FILTER_OPERATOR_EXCLUDE) return this.conditionNames.isNot;
      return this.name.startsWith('!') ? this.conditionNames.isNot : this.conditionNames.is;
    },
    cleanName() {
      if (this.isProxy) return this.$pgettext('Title — QueryItem proxy cleanName', 'Proxy');
      if (this.isLevel) return this.$pgettext('Title — QueryItem level cleanName', 'Formulär');
      const cleanTag = unsuffixTag(unprefixTag(getPureFilterKey(this.name)));
      if (cleanTag === 'and') return this.collectionNames.and;
      if (cleanTag === 'or') return this.collectionNames.or;
      if (
        !this.loadingTagKeyTranslation.has(cleanTag)
        && !this.customerTagKeyVal?.[cleanTag]
        && !this.error
      ) {
        // eslint-disable-next-line vue/no-async-in-computed-properties
        this.customerTagKeyTranslation(cleanTag).catch((err) => { this.error = err; }); // ! Runs request to get translation
        this.loadingTagKeyTranslation.add(cleanTag);
      }
      return this.customerTagKeyVal?.[cleanTag] || cleanTag;
    },
  },
  watch: {
    itemValue: {
      deep: true,
      handler() {
        this.updateEdition(this.name);
      },
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions(['customerTagKeyTranslation']),
    translateTerm,
    prettyProxy(id) {
      const prox = isNumber(id) && this.getProxyById(id);
      return prox && (prox?.internal_name || prox.name) || id;
    },
    disableEdit() {
      this.isEditing = false;
    },
    updateEdition(key) {
      const value = this.itemValue?.map?.((tag) => (tag.value ? tag.value : tag)) || this.itemValue;
      this.$emit('edit', { key, value });
    },
    edit(key) {
      this.isEditing = !this.isEditing;
      this.init();

      const pureKey = getPureFilterKey(key);
      const newKey = getPrefixedFilterKey(pureKey, this.activeTab);
      const oppositeTab = this.activeTab === FILTER_OPERATOR_INCLUDE ? FILTER_OPERATOR_EXCLUDE : FILTER_OPERATOR_INCLUDE;
      const oppositeKey = getPrefixedFilterKey(pureKey, oppositeTab);

      this.remove(oppositeKey);
      this.updateEdition(newKey);
    },
    remove(key) {
      this.$emit('remove', key);
    },
    convert({ tag, path, depth }) {
      const newPath = this.depth !== depth ? `${this.name}.${tag}` : tag; // ? Recursively add object dot notation
      this.$emit('convert', { tag, path: newPath, depth });
    },
    init() {
      this.itemValue = this.isArray ? [...this.value] : this.value;
    },
  },
};
</script>
