<template>
  <div class="tc-card-takeover">
    <div class="tc-card tc-card-primary">
      <div class="tc-card-header p-card">
        <h3>{{ $gettext('Acceptera användarvillkor') }}</h3>
      </div>
      <div
        class="tc-card-body p-card"
      >
        <VForm @submit="handleSubmit">
          <p>
            {{ $gettext('Du måste acceptera de allmänna villkoren för att fortsätta.') }}
          </p>

          <div class="form-group form-check">
            <VField
              v-slot="{ field }"
              v-model="haveReadAndAccepted"
              name="gdpr"
              type="checkbox"
              :value="true"
              :unchecked-value="false"
              :rules="acceptedTermsRule"
            >
              <input
                id="gdpr"
                ref="gdpr"
                v-bind="field"
                name="gdpr"
                :value="true"
                type="checkbox"
                class="form-check-input"
                tabindex="3"
              >
              <label
                class="form-check-label"
                for="gdpr"
              >{{ $gettext('Jag har läst och godkänt villkoren i') }}</label><br><a
                class="link"
                tabindex="4"
                style="text-decoration: underline"
                @click.stop="openGDPRModal()"
                @keypress.enter.stop="openGDPRModal()"
              >{{ $gettext('Trustruits Privacy policy') }}</a>
              <VErrorMessage
                name="gdpr"
                as="p"
                class="help-block validation-message is-invalid"
              />
            </VField>
          </div>
          <div class="tc-card-footer py-6">
            <button
              id="submitNewUser"
              type="submit"
              :class="{'btn-loading': loading, 'btn-loaded': finishedLoading}"
              tabindex="5"
              :aria-disabled="!isValidForm || loading || null"
              class="btn btn-block"
            >
              <!-- "Dashboard" på svenska? -->
              {{ $gettext('Fortsätt till dashboard') }}
            </button>
            <p
              v-if="errorMessage"
              class="help-block validation-message is-invalid"
            >
              {{ errorMessage }}
            </p>
          </div>
        </VForm>
      </div>
    </div>
    <modalception />
  </div>
</template>

<script setup>
// import { logout } from 'Utils/authHelpers';
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import {
  Form as VForm,
  Field as VField,
  ErrorMessage as VErrorMessage,
} from 'vee-validate';
import loginAPI from 'API/login';
import { localStorageIsAvailable } from 'Utils/storage';
import dynamicImport from 'Utils/dynamicImport';
import Modalception from 'Modal/Modalception';
import gettext from '@/gettext';

const { $gettext, $pgettext } = gettext;

const store = useStore();
const emailAddress = ref('');

emailAddress.value = localStorageIsAvailable && localStorage.getItem('emailAddress');

const acceptedTermsRule = (value) => {
  if (value === true) return true;
  return $pgettext('Validation rule - acceptTerms', 'Du måste godkänna villkoren');
};
const haveReadAndAccepted = ref(false);
const isValidForm = computed(() => haveReadAndAccepted.value === true);

const openGDPRModal = () => {
  const PrivacyPolicyDetails = dynamicImport(() => import(/* webpackChunkName: "PrivacyPolicyDetails", webpackPrefetch: true */ 'Components/parts/details/PrivacyPolicyDetails'));
  store.dispatch('openModal', {
    name: 'PrivacyPolicyDetails',
    component: PrivacyPolicyDetails,
    size: 'large',
  });
};

const loading = ref(false);
const finishedLoading = ref(false);
const errorMessage = ref('');
const requestLogin = async () => {
  const { AUTH_ERROR, AUTH_TYPE } = loginAPI;

  try {
    if (!emailAddress.value) throw new Error('No email address');
    const res = await loginAPI.requestLogin(emailAddress.value, haveReadAndAccepted.value);

    // If gdpr somehow is still not accepted, requestLogin should throw error
    if (localStorageIsAvailable) localStorage.setItem('gdprAccepted', true);
    // auth_type should always be SSO, but check just in case
    if (res.auth_type === AUTH_TYPE.SSO) window.location.href = res.redirect_url;
  } catch (err) {
    switch (err.message) {
      case AUTH_ERROR.NO_GDPR_FOR_SSO:
        throw new Error('GDPR not accepted'); // Really should not happen...
      default:
        if (err?.response?.body) {
          throw err.response.body?.error || err.response.body;
        }
    }
  } finally {
    loading.value = false;
    finishedLoading.value = true;
  }
};

const handleSubmit = async () => {
  loading.value = true;
  if (isValidForm.value) {
    errorMessage.value = '';
    try { await requestLogin(); } catch (err) {
      errorMessage.value = $pgettext('New user error', 'Något gick fel, prova igen eller kontakta vår support');
      loading.value = false;
    }
  }
  loading.value = false;
};
</script>
