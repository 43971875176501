<template>
  <div :class="{'tc-loader-bar-wrapper': !isInBoard }">
    <CardDiv
      v-tc-loader-bar="(loading || loadingQuestion) && (isInBoard || previewMode)"
      :loading="loading || loadingQuestion || null"
    >
      <template #body>
        <div class="ranking-guage-wrapper p-card">
          <p class="text-center h3 mb-6">
            {{ processedQuestionText }}
          </p>
          <ranking-gauge-indicator
            :score="ranking"
            :respondent-count="respondentCount"
            :position="position"
            :excluded="excluded"
            :trend="trend"
            :loading-trend="loadingTrend"
            :applicants-left="applicantsLeft"
            :comment="comment"
            :benchmark-label="benchmarkLabel"
          />
          <!-- <p class="text-center strong big-text mb-4">
            {{ usedStep.label }}
          </p> -->
          <div class="tc-ranking-gauge-description">
            <p class="mb-2">
              {{ loading ? '' : description }}
            </p>
            <div class="help-block text-left">
              <span class="tc-sw-help-btn">
                <button
                  class="help-button btn btn-link hide-print inline-block"
                  @click="showHelp = !showHelp"
                >
                  {{ $gettext('Hjälp att förstå') }} <i class="zmdi zmdi-help" />
                </button>
              </span>
              <span v-if="showHelp">
                {{ $pgettext(
                  'Helptext - Explain Ranking 1',
                  'Företagets ranking för %{step}-formuläret baseras på följande ställda fråga: ”%{question}”.',
                  { step: usedStep.label, question: processedQuestionText }
                ) }}
                <br>
                {{ $pgettext(
                  'Helptext - Explain Ranking 2',
                  'Benchmarken är alltid i mitten på 50%. Om ni har över 50% så presterar ni bättre än snittet, under 50% så presterar ni sämre än snittet. Trenden visar skillnaden gentemot föregående datumperiod.', // eslint-disable-line max-len
                ) }}
              </span>
            </div>
          </div>
        </div>
      </template>
    </CardDiv>
  </div>
</template>

<script>
import { isEmpty, debounce, isEqual } from 'lodash-es';
import { mapGetters, mapActions } from 'vuex';
import { requestRanking } from 'API/analysis';
import { requestData } from 'API/data';
import { processText, translateTermOption, equalLiteral } from 'Utils/general';
import { KEY_METRIC_GRAPH_TYPES } from 'Utils/graph';
import { getDateFilterPrev } from 'Utils/date';
import eventBus from 'Utils/eventBus';
import RankingGaugeIndicator from 'Components/parts/graph/RankingGaugeIndicator';
import CardDiv from 'Components/parts/CardDiv';

const DEFAULT_RANKING = null;
const DEFAULT_POSITION = null;
const DEFAULT_EXCLUDED = true;
const DEFAULT_APPLICANTS_LEFT = 0;

// ? Hardcoded CNPS Question IDs
export const STEP1_QID = 397;
export const STEP1EB_QID = 1671; // Employerbranding
export const STEP2_QID = 405;
export const STEP5_QID = 502;
export const STEP5P1_QID = 1122;

export const CNPSISH_QUESTION_IDS = [STEP1_QID, STEP1EB_QID, STEP2_QID, STEP5_QID, STEP5P1_QID];

export function setQuestionOpts(questions, step) {
  if (step !== 'step1') return [];
  const opts = questions.filter((q) => [STEP1_QID, STEP1EB_QID].includes(q.value)); // 1671 for employerbranding
  return opts;
}

export default {
  name: 'RankingGauge',
  components: {
    RankingGaugeIndicator,
    CardDiv,
  },
  props: {
    board: Object,
    card: Object,
    compiledFilter: Object,
    compiledBenchmark: Object,
    contextBenchmark: Object,
    isInBoard: {
      type: Boolean,
      default: false,
    },
    previewMode: {
      type: Boolean,
      default: false,
    },
    hydrateProps: { // ? Fill in comp from outside
      type: [Object, null],
      default: null,
    },
  },
  emits: ['loading', 'update-drag-area', 'hydrate-props'],
  data() {
    return {
      abortToken: Math.random().toString(10).substring(2),
      loading: false,
      loadingTrend: false,
      loadingQuestion: false,
      rankingResponse: [],
      trendResponse: [],
      respondentCount: null,
      ranking: DEFAULT_RANKING,
      position: DEFAULT_POSITION,
      excluded: DEFAULT_EXCLUDED,
      applicantsLeft: DEFAULT_APPLICANTS_LEFT,
      question: '',
      comment: '',
      trend: null,
      showHelp: false,
      getRanking: () => {},
      allQuestions: [],
    };
  },
  computed: {
    ...mapGetters([
      'customerId',
      'segmentFilter',
      'modalCard',
    ]),
    questionIdChoosen() {
      return !!this.localCard?.metadata?.show?.question?.[0];
    },
    availableQuestions() {
      if (!this.allQuestions.length) return [];
      const questionIds = [STEP1_QID, STEP1EB_QID, STEP2_QID, STEP5_QID, STEP5P1_QID];
      return questionIds.reduce((acc, id) => {
        const q = this.allQuestions.find((question) => question.id === id);
        if (q?.translation?.question && q?.id) {
          acc.push({ label: processText(q.translation.question), value: q.id });
        }
        return acc;
      }, []);
    },
    EBisChosen() {
      return this.questionIdChoosen && this.localCard.metadata.show.question[0] === STEP1EB_QID;
    },
    useSTEP1EB_QID() {
      return this.availableQuestions.length
          && this.availableQuestions.find((q) => q.value === STEP1EB_QID)
          && this.availableQuestions.filter((q) => q.value === STEP1_QID).length === 0;
    },
    questionId() {
      if (this.questionIdChoosen && this.usedStep?.value === 'step1') return this.localCard.metadata.show.question[0];
      switch (this.usedStep?.value) {
        case 'step1':
          if (this.useSTEP1EB_QID) return STEP1EB_QID;
          return STEP1_QID;
        case 'step2':
          return STEP2_QID;
        case 'step5':
          // ? 1122 for step5.1
          return STEP5_QID;
        default:
          return null;
      }
    },
    localCard() {
      return this.previewMode ? this.modalCard : this.card;
    },
    benchmarkLabel() {
      return isEmpty(this.compiledBenchmark) || this.compiledBenchmark?.general ? 'Global' : 'Benchmark';
    },
    processedQuestionText() {
      return this.question && processText(this.question) || '';
    },
    usedStep() { return translateTermOption(this.localCard?.metadata?.filter?.step?.[0]); },
    currentFilter() {
      return {
        customer_proxy: { customer_id: this.customerId },
        question: {
          ...(this.usedStep?.value && { // Add ['step'] if not falsy
            step: this.usedStep.value,
            question_id: CNPSISH_QUESTION_IDS,
          }),
        },
        date: this.compiledFilter?.date || {},
      };
    },
    compareToFilter() {
      return {
        customer_proxy: this.formatBenchmarkFilter(this.compiledBenchmark),
        date: this.compiledFilter?.date || this.segmentFilter?.date || {},
      };
    },
    cardFilter() {
      return {
        filter: this.compiledFilter,
        benchmark: this.formatBenchmarkFilter(this.compiledBenchmark),
      };
    },
    trafficLight() {
      if (this.ranking === null) return 'nodata';
      if (this.ranking < 25) return 'red';
      if (this.ranking >= 50) return 'green';
      return 'orange';
    },
    description() {
      const stepMappings = {
        ansökan: 'ansöknings',
        avslag: 'avslags',
      };
      let step = stepMappings[this.usedStep?.label?.toLowerCase()] || this.usedStep?.label?.toLowerCase() || '';

      if (this.EBisChosen) {
        step = this.$pgettext('ranking-gauge - description', 'attraktionskraft');
      } else {
        step += (this.position === 2 ? this.$pgettext('ranking-gauge - description', 'processen') : this.$pgettext('ranking-gauge - description', 'process'));
      }

      switch (this.position) {
        case 1:
          return this.$gettextInterpolate(this.$pgettext('ranking-gauge - description', 'Grattis! Er %{step} är den bästa!'), { step });
        case 2:
          return this.$gettextInterpolate(this.$pgettext('ranking-gauge - description', 'Bra jobbat! Ert företag har den näst bästa %{step}. Det är imponerande!'), { step });
        case 3:
          return this.$gettextInterpolate(this.$pgettext('ranking-gauge - description', 'Wow! Ert företags %{step} är den tredje bästa!'), { step });
        case null:
          switch (this.trafficLight) {
            case 'red':
              if (this.ranking <= 10) return this.$gettextInterpolate(this.$pgettext('ranking-gauge - description', 'Ert företags %{step} är långt under snittet. Fokusera på era svagaste delar och ta itu med dem för att förbättra er ranking.'), { step });
              return this.$gettextInterpolate(this.$pgettext('ranking-gauge - description', 'Ert företags %{step} är långt under snittet och behöver lite kärlek. Kolla in er feedback för att ta reda på hur ni kan förbättra er.'), { step });
            case 'orange':
              if (this.ranking >= 45) return this.$gettextInterpolate(this.$pgettext('ranking-gauge - description', 'Ert företags %{step} ligger nära snittet. Fortsätt förbättra er för att nå det gröna.'), { step });
              return this.$gettextInterpolate(this.$pgettext('ranking-gauge - description', 'Ert företags %{step} ligger under snittet. Fortsätt arbeta på förbättringar.'), { step });
            case 'green':
              if (this.ranking <= 55) return this.$gettextInterpolate(this.$pgettext('ranking-gauge - description', 'Ert företags %{step} presterar bra, men ligger nära snittet. Fortsätt förbättra er för att säkra er prestation.'), { step });
              return this.$gettextInterpolate(this.$pgettext('ranking-gauge - description', 'Imponerande, ert företags %{step} rankas bland de högst presterande!'), { step });
            case 'nodata':
              if (this.comment) return this.comment;
              return this.$gettextInterpolate(this.$pgettext('ranking-gauge - description', 'Ert företag behöver %{num} fler svar för att se er ranking.'), { num: this.applicantsLeft || '' });
            default:
              return '';
          }
        default:
          return this.$gettext('Det var ett problem med databasförfrågan. Vänligen ladda om sidan för att försöka igen.');
      }
    },
  },
  watch: {
    async questionId(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getSetQuestionById(newVal);
        this.getRanking();
        this.respondentCount = await this.getRespondentCount(newVal);
      }
    },
    loading(newVal) { if (!newVal && this.isInBoard) this.$emit('loading', newVal); }, // ? Triggers fullyLoadedCards so Sejda knows when to print
    cardFilter(newVal, oldVal) {
      if (this.isInBoard && !this.previewMode && !equalLiteral(newVal, oldVal)) { // ? Is run after CardEditDetails clicked save (Save all metadata that needs to be used here)
        this.getRanking();
      }
    },
    compareToFilter(newVal, oldVal) {
      this.$nextTick(() => { if (!equalLiteral(newVal, oldVal)) this.getRanking(); });
    },
    customerId(newVal, oldVal) { if (newVal !== oldVal) this.setup(); },
    compiledFilter(newVal, oldVal) { if (!equalLiteral(newVal.date, oldVal.date)) this.getRanking(); },
    usedStep(newVal, oldVal) {
      if (newVal && oldVal && !equalLiteral(newVal, oldVal)) this.getRanking();
    },

  },
  beforeUnmount() {
    if (typeof this.getRanking?.cancel === 'function') this.getRanking.cancel(); // ? Cancel all pending debounced requests
    eventBus.$emit(`abortRequest:${this.abortToken}`);
  },
  beforeMount() {
    // ? Debounced fn needs to be in data and not in methods to support more instances of this component
    this.getRanking = debounce(async (filter = this.currentFilter, compareToFilter = this.compareToFilter) => {
      this.loading = true;
      const hierarchy = 1;
      const groupBy = ['question'];
      const query = { hierarchy, groupBy, filter, compareToFilter };
      const trendQuery = {
        hierarchy,
        groupBy,
        filter: {
          ...filter,
          ...getDateFilterPrev(filter),
        },
        compareToFilter: {
          ...getDateFilterPrev(compareToFilter),
          customer_proxy: { ...compareToFilter?.customer_proxy },
        },
      };
      let hierarchyRankingResponse;
      try {
        const hydrateRankingProps = this.hydrateProps?.[KEY_METRIC_GRAPH_TYPES.RankingGauge];
        const useSavedResponse = isEqual(hydrateRankingProps?.query, query)
        && !isEmpty(hydrateRankingProps?.rankingResponse)
        && hydrateRankingProps?.rankingResponse?.[this.questionId]?.question_id === this.questionId;

        if (useSavedResponse) {
          hierarchyRankingResponse = { [hydrateRankingProps.rankingResponse?.[this.questionId]?.question_id]: hydrateRankingProps.rankingResponse?.[this.questionId] }; // eslint-disable-line object-curly-newline,max-len
        } else {
          hierarchyRankingResponse = await requestRanking(query, this.abortToken); // eslint-disable-line max-len
        }
        this.rankingResponse = this.returnBestHierarchyResponse(hierarchyRankingResponse);
        if (this.rankingResponse?.question_id !== undefined && this.rankingResponse?.question_id !== this.questionId) {
          await this.getSetQuestionById(this.rankingResponse?.question_id);
        }

        await this.getTrend(trendQuery);
      } catch (err) {
        console.error('[TC] Couldn’t load ranking', err); // eslint-disable-line no-console
      } finally {
        this.ranking = this.rankingResponse?.ranking || DEFAULT_RANKING;
        this.position = this.rankingResponse?.position || DEFAULT_POSITION;
        this.excluded = this.rankingResponse?.excluded ?? DEFAULT_EXCLUDED;
        this.applicantsLeft = this.rankingResponse?.excluded !== undefined
          ? this.rankingResponse.more_answers_needed
          : DEFAULT_APPLICANTS_LEFT;
        this.comment = this.rankingResponse?.comment || '';
        if (this.isInBoard) {
          this.$emit('hydrate-props', {
            [KEY_METRIC_GRAPH_TYPES.RankingGauge]: {
              rankingResponse: hierarchyRankingResponse,
              trendResponse: this.trendResponse,
              query,
              trendQuery,
            },
          });
        }

        this.loading = false;
        this.$emit('update-drag-area');
      }
    }, 100);
  },
  async created() {
    try {
      this.allQuestions = await this.getAllQuestions();
    } catch (err) {
      this.allQuestions = [];
    }
  },
  mounted() {
    if (this.score !== null) this.setup();
  },
  methods: {
    ...mapActions(['getQuestionById', 'getAllQuestions']),
    async getRespondentCount(id) {
      const query = {
        filter: {
          ...this.currentFilter,
          question: {
            ...this.currentFilter.question,
            question_id: [id],
          },
        },
      };
      const res = await requestData([query]);
      return res[0]?.respondent_count || null;
    },
    formatBenchmarkFilter(benchmark) {
      const { location, general, ...rest } = benchmark || {};
      if (general) return {};
      return { ...rest, country: location };
    },
    getSetQuestionById(id = this.questionId) {
      this.loadingQuestion = true;
      this.getQuestionById(id)
        .then((q) => { this.question = q?.translation.question || ''; })
        .catch(() => { this.question = null; })
        .finally(() => { this.loadingQuestion = false; });
    },
    returnBestHierarchyResponse(resp) {
      if (this.questionIdChoosen) resp = { [this.questionId]: resp[this.questionId] };
      return resp && Object.values(resp).reduce((acc, questionRank) => {
        if (questionRank?.excluded === false && acc?.excluded !== false) { // ? 1. If `excluded` is false: choose that latest one
          acc = questionRank;
        } else if (questionRank?.more_answers_needed) { // ? 2. If all `excluded` is true: look for the one with the least `more_answers_needed`, or at least set acc
          if (
            (acc?.more_answers_needed && (questionRank.more_answers_needed < acc.more_answers_needed))
            || !acc?.more_answers_needed && acc?.excluded
          ) acc = questionRank;
        } else if (questionRank?.failedResponse || questionRank?.comment || questionRank === null) { // ? 3. If no else, at least set acc
          acc = questionRank;
        }
        return acc;
      }, {}) || {};
    },
    calculateTrend(newDate, oldDate) {
      if (!newDate || !oldDate) return null;
      let isExcluded = newDate?.excluded === null || oldDate?.excluded === null;
      let noRank = newDate?.ranking === null || oldDate?.ranking === null;
      if (noRank || isExcluded) return null;
      return newDate.ranking - oldDate.ranking;
    },
    async getTrend(trendQuery) {
      this.loadingTrend = true;
      try {
        let hierarchyTrendResponse;
        const hydrateRankingProps = this.hydrateProps?.[KEY_METRIC_GRAPH_TYPES.RankingGauge];
        const useSavedResponse = isEqual(hydrateRankingProps?.trendQuery, trendQuery)
        && !isEmpty(hydrateRankingProps?.trendResponse[this.questionId]);
        if (useSavedResponse) {
          hierarchyTrendResponse = [hydrateRankingProps.trendResponse[this.questionId]];
        } else {
          hierarchyTrendResponse = await requestRanking(
            trendQuery,
            this.abortToken,
          );
        }
        if (hierarchyTrendResponse) {
          this.trendResponse = hierarchyTrendResponse;
          this.trend = this.calculateTrend(
            this.rankingResponse,
            this.returnBestTrendHierarchyResponse(hierarchyTrendResponse),
          );
        }
      } catch (err) {
        console.error('[TC] Couldn’t load ranking trend', err); // eslint-disable-line no-console
      } finally {
        this.loadingTrend = false;
      }
    },
    returnBestTrendHierarchyResponse(hierarchyTrendResponse) {
      return (this.rankingResponse?.question_id && Object.values(hierarchyTrendResponse)
        .find((questionRank) => questionRank?.question_id === this.rankingResponse?.question_id))
            || Object.values(hierarchyTrendResponse)[0];
    },
    async setup() {
      if (this.questionId) {
        this.respondentCount = await this.getRespondentCount(this.questionId);
        this.getSetQuestionById(this.questionId);
      }
      this.getRanking();
    },
  },
};
</script>
