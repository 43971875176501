<template>
  <div class="tc-loader-bar-wrapper">
    <div
      v-tc-loader-bar="loadingSegment"
      class="container container-narrow"
      :class="{ 'paywall-underlay': showPaywalls }"
    >
      <invite-paywall v-if="showPaywalls" />
      <div class="hgroup">
        <h2>{{ $gettext('Skicka inbjudningar') }}</h2>
        <h5 v-html="translations.giveAccess" />
        <p
          v-if="isCustomerUserAdmin"
          class="alert alert-default mt-2"
        >
          <i class="zmdi zmdi-info" />
          <span
            class="mr-1"
            v-html="translations.inviteConfirmationText"
          />
          <router-link :to="`/customer/${customerId}/settings/invite`">
            <span>{{ $gettext('Gå hit') }}</span>&nbsp;<i class="zmdi zmdi-long-arrow-right" />
          </router-link>
        </p>
      </div>
      <invite-form
        type="segment"
        :single-segment-id="segmentId"
        :disabled-form="showPaywalls"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { localStorageIsAvailable } from 'Utils/storage';
import dynamicImport from 'Utils/dynamicImport';
import InviteForm from 'Components/parts/user/UserInviteForm';
import { store } from '@/store';

export default {
  name: 'SegmentSettingsInvite',
  components: {
    InviteForm,
    InvitePaywall: dynamicImport(() => import(/* webpackChunkName: "InvitePaywall", webpackPrefetch: true */'Components/parts/paywalls/InvitePaywall')),
  },
  async beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      try {
        if (!to.params.segmentId) { // Should likely never happen, but here just in case
          const currSegmentId = store.state.router.hiddenParams?.segmentId || Number(localStorageIsAvailable && localStorage.getItem('currentSegmentId')) || null;
          try {
            const me = await vm.$store.dispatch('fetchMe');
            const currSegIsAvailableInMe = !!me?.segments?.find((seg) => seg.id === currSegmentId);

            let usedSegmentId = currSegmentId; // ? 1. Use localStorage if available
            if (!currSegIsAvailableInMe) usedSegmentId = me?.segments?.[0]?.id; // ? 2. Use the first listed segment if localStorage id not found in available segments
            const segment = await vm.$store.dispatch('fetchSegment', usedSegmentId); // ? 3. Use apiSegment.getFirst if null

            if (segment?.id) {
              if (!to.meta.segmentHidden) to.params.segmentId = Number(segment.id);
              vm.$store.dispatch('setHiddenParams', { segmentId: segment.id });
              localStorage.setItem('currentSegmentId', segment.id);
            } else throw new Error(vm.$pgettext('Error — beforeRouteEnter reroute', 'Kunde inte hitta segment'));
          } catch (error) {
            store.dispatch('setHiddenParams', { error });
            next({ name: 'login' });
          }
        } else {
          const segId = Number(to.params.segmentId) || null;
          const fetchOrGetSegment = segId ? 'getSegment' : 'fetchSegment'; // Because getSegment requires a number
          await vm.$store.dispatch(fetchOrGetSegment, segId);
        }
      } catch (error) {
        store.dispatch('setHiddenParams', { error });
        next({ name: 'login' });
      }
    });
  },
  computed: {
    ...mapState({
      hiddenParams: (state) => state.router.hiddenParams,
    }),
    ...mapGetters([
      'showPaywalls',
      'customerName',
      'segmentName',
      'segmentId',
      'role',
      'customerId',
    ]),
    translations() {
      return {
        inviteConfirmationText: this.$gettext('Vill du bjuda in en användare som har tillgång till <i>alla</i> segment.'),
        giveAccess: this.$gettext(
          'För att ge tillgång till segment <strong>%{name}</strong> på <strong>%{customer}</strong>',
          { name: this.loadingSegment ? '…' : this.segmentName, customer: this.customerName },
        ),
      };
    },
    isCustomerUserAdmin() {
      return this.role === 'admin';
    },
    loadingSegment() {
      const currSegmentId = Number(this.$route.params.segmentId) || this.hiddenParams.segmentId || null;
      return currSegmentId !== this.segmentId;
    },
  },
};
</script>
