<template>
  <div class="query-result-free-text tc-loader-bar-wrapper py-8 px-4">
    <div
      v-tc-loader-bar="loading"
      class="tc-free-text-list-wrapper"
    >
      <header
        v-if="count && results?.length > 0 || loading"
        class="tc-free-text-list-header flexy-row justify-space-between"
      >
        <small
          class="subtle-text"
        >{{ $pgettext('FreeText - Total amount of answers', 'Antal svar:') }} {{ count ?? '—' }}</small>
        <small
          class="subtle-text"
        >{{ $pgettext('FreeText - Free text answers per page', 'Svar per sida:') }} {{ pageSize }}</small>
      </header>
      <ul
        v-if="results?.length > 0 || loading"
        class="tc-free-text-list"
      >
        <template v-if="results?.length > 0">
          <li
            v-for="answer in results"
            :key="answer.id"
            class="tc-free-text-item"
            tabindex="0"
          >
            <RespondentAvatar
              :id="answer.id"
              mask="squircle"
              :is-sadface="getNPSCatFromRating(answer.cnps_rating) === -1
                && getNPSCatFromRating(answer.cnps_rating) !== null"
              :nps-category="getNPSCatFromRating(answer.cnps_rating)"
              :include-mask="false"
              :size="36"
            />
            <div class="tc-free-text-bubble-tail" />
            <header>
              <small class="date">{{ prettyDate(answer?.applicant?.survey_sending_date) }}</small>
              <div
                v-if="answer.applicant.job_ad_title !== null && showLabel"
                class="small-text"
              >
                <span class="subtle-text">{{ $gettext('Annons:') }}</span>
                {{ displayTag(answer) }}
              </div>
              <small v-if="answer.cnps_rating !== null">
                <span
                  class="subtle-text"
                >{{ $gettext('Kandidaten svarade:') }}</span> {{ answer.cnps_rating }}</small>
            </header>
            <div class="item-body">
              <p class="mb-2">
                {{
                  translatedText(answer, 'value_translation.translation', answer.value )
                }}
                <span
                  v-if="answer.applicant.email"
                  class="subtle-text small-text"
                >
                  <br>
                  <span class="no-user-select">–</span><a
                    :href="mailto(answer.applicant.email)"
                  >{{ applicantEmail(answer.applicant.email) }}
                  </a>
                </span>
              </p>
              <p
                v-if="card.metadata.show.translate
                  && translatedText(answer,'value_translation.translation')
                  && !translationIsSameLang(answer)"
                v-tooltip="{
                  content: () => valueTranslatedFromTooltip(answer),
                  html: true,
                  delay: { show: 400, hide: 0 }
                }"
                class="small-text subtle-text cursor-help mb-0"
              >
                <i class="zmdi zmdi-translate mr-1" />
                <span>{{ $gettext('Översatt från') }}</span>
                {{ translatedText(answer,'value_translation.original_language') }}
              </p>
              <TopicsList
                v-if="showTopics && answer?.topics?.length > 0"
                :topics="answer.topics"
                class="inline-list"
              />
            </div>
          </li>
        </template>
        <template v-else-if="loading">
          <li
            v-for="skeletonResult in skeletonResults"
            :key="skeletonResult"
            class="tc-free-text-item"
            tabindex="0"
          >
            <RespondentAvatar
              :id="skeletonResult"
              id-is-hashed
              mask="squircle"
              :is-sadface="false"
              :include-mask="false"
              :size="36"
            />
            <div class="tc-free-text-bubble-tail" />
            <header>
              <small
                class="date"
                v-text="'&thinsp;'"
              />
            </header>
            <div class="item-body">
              <p
                class="mb-2"
                v-text="'&thinsp;'"
              />
            </div>
          </li>
        </template>
      </ul>
      <div
        v-else
        class="alert alert-info mb-0"
      >
        <i class="zmdi zmdi-info" />
        {{ $gettext('Inga svar finns på frågan med nuvarande inställda filter.') }}
        <br>
        {{ $gettext('Om det finns färre än 3 svar så anonymiseras sökningen.') }}
      </div>
      <div
        v-if="paginator && results?.length > 0"
        class="tc-pagination"
      >
        <button
          v-if="paginator.hasPrevious() && !loading"
          class="btn-link btn valign-middle"
          :tabindex="disableTabindex ? -1 : null"
          @click.stop="previous"
        >
          <i class="zmdi zmdi-chevron-left" />
        </button>
        <span class="page-counter valign-middle">
          <strong>{{ currentIndex }}</strong>&nbsp;/&nbsp;{{ pageAmount || 1 }}
        </span>
        <button
          v-if="paginator.hasNext() && !loading"
          class="btn-link btn valign-middle"
          :tabindex="disableTabindex ? -1 : null"
          @click.stop="next"
        >
          <i class="zmdi zmdi-chevron-right" />
        </button>
      </div>
      <template v-if="isTopicable(card?.metadata?.question)">
        <div
          v-if="results?.length !== 0 && !loading"
          class="tc-sw-help-btn"
        >
          <button
            class="help-button btn btn-link"
            @click="showHelp = !showHelp"
          >
            {{ $gettext('Hjälp att förstå') }} <i class="zmdi zmdi-help" />
          </button>
        </div>
        <div v-if="showHelp || (isPrintViewRoute && results?.length !== 0 )">
          <p class="my-2">
            <TopicsIcon class="tc-color-purple mr-1" />
            {{
              $pgettext(
                'Help — Topics explanation title',
                'Dessa svar har kategoriserats med hjälp av AI, för att lättare kunna tyda vad kommentarer handlar om.'
              )
            }}
            {{
              $pgettext(
                'Help — Topics explanation legend',
                'Förklaringar för kategorierna ovan:'
              )
            }}
          </p>
          <TopicsList
            class="inline-list"
            :topics="topics"
            :is-explanatory="true"
          />
          <p class="my-2 tc-color-purple">
            {{
              $pgettext(
                'Help — Topics explanation beta',
                'Textkategoriseringar är för tillfället i ett experimentellt stadie och kan därför innehålla viss felaktig kategorisering' // eslint-disable-line max-len
              )
            }}
            <span class="pill pill-beta ml-1">Beta</span>
          </p>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { isEqual, debounce, get, isEmpty } from 'lodash-es';
import { mapGetters } from 'vuex';
import { klona } from 'klona';
import answersApi from 'API/answers';
import { isTopicable } from 'API/topics';
import Paginator from 'Utils/Paginator';
import { getNPSCatFromRating } from 'Utils/general';
import { format } from 'Utils/dateHelpers';
import eventBus from 'Utils/eventBus';
import TopicsList from 'Components/parts/badges/TopicsList';
import TopicsIcon from 'Components/parts/icons/TopicsIcon';
import RespondentAvatar from 'Components/parts/avatars/RespondentAvatar';

const fnSortById = (a, b) => a.id - b.id;

export default {
  name: 'QueryResultFreeText',
  components: {
    TopicsList,
    TopicsIcon,
    RespondentAvatar,
  },
  props: {
    card: {
      type: Object,
      required: true,
    },
    contextFilter: Object,
    compiledFilter: Object,
    disableTabindex: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update-drag-area', 'loading'],
  data() {
    return {
      currentIndex: 1,
      count: null,
      results: [],
      paginator: null,
      loading: true,
      abortToken: Math.random().toString(10).substring(2),
      showHelp: false,
      getAnswers: () => {},
    };
  },
  computed: {
    ...mapGetters([
      'segmentId',
      'segment',
      'topics',
    ]),
    shouldTranslate() { return this.card.metadata.show?.translate ?? false; },
    showLabel() { return this.card.metadata.show?.showLabel ?? true; },
    showTopics() { return this.card.metadata.show?.showTopics ?? true; },
    pageAmount() {
      return this.count > this.pageSize ? Math.ceil(this.count / this.pageSize) : 1;
    },
    pageSize() {
      return this.card?.metadata?.show?.pageSize || 10;
    },
    skeletonResults() {
      return Array.from({ length: this.pageSize }, (_, i) => String((i + 1) * 111));
    },
    freeTextSpecificFilter() {
      const filter = klona(this.compiledFilter);
      delete filter.topics;
      delete filter['!topics'];
      delete filter.segment;
      delete filter.customerProxies;
      return filter;
    },
    isPrintViewRoute() {
      return this.$route?.name?.indexOf('print-') > -1;
    },
  },
  watch: {
    async loading() {
      await this.$nextTick();
      this.$emit('update-drag-area');
    },
    'card.metadata.show.translate': {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) this.getAnswers(this.card.metadata.question, newVal);
      },
    },
    'card.metadata.question': {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal && oldVal && newVal !== oldVal) this.getAnswers(newVal, this.shouldTranslate);
      },
    },
    compiledFilter: {
      handler(newFilter, oldFilter) {
        if (!isEmpty(newFilter) && !isEqual(newFilter, oldFilter)) {
          this.getAnswers(this.card.metadata.question, this.shouldTranslate);
        }
      },
      deep: true,
      immediate: true,
    },
    pageSize(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getAnswers(this.card.metadata.question, this.shouldTranslate);
      }
    },
  },
  created() {
    // ? Debounced fn needs to be in data and not in methods to support more instances of this component
    this.getAnswers = debounce(async (question, translate = this.shouldTranslate) => {
      const segment = this.compiledFilter?.segment || this.segmentId;
      const customerProxies = this.compiledFilter?.customerProxies || [];
      const topicFilterKey = this.compiledFilter?.['!topics'] ? '!topics' : 'topics';
      const topic = isTopicable(this.card.metadata.question) && this.compiledFilter?.[topicFilterKey] || [];

      if (!question) return;
      this.loading = true;
      this.$emit('loading', true);
      try {
        this.paginator = new Paginator(answersApi.text(
          { query: {
            question,
            translate,
            segment,
            customerProxies,
            [topicFilterKey]: topic,
          },
          filter: { pageSize: this.pageSize, ...this.freeTextSpecificFilter },
          abortToken: this.abortToken },
        ));
        await this.paginator.promise;

        this.results = this.sortTopics(this.paginator?.response?.results || []);
        this.count = this.paginator?.response?.count || 0;
        this.currentIndex = 1;
      } catch (err) {
        console.error('[TC] Couldn’t get answers', err); // eslint-disable-line no-console
      } finally {
        this.loading = false;
        this.$emit('loading', false); // ? Triggers fullyLoadedCards so Sejda knows when to print
        this.$emit('update-drag-area');
      }
    }, 100);
  },
  mounted() {
    if (!isEmpty(this.compiledFilter)) this.getAnswers(this.card.metadata.question, this.shouldTranslate);
    this.$emit('update-drag-area');
  },
  beforeUnmount() {
    eventBus.$emit(`abortRequest:${this.abortToken}`);
  },
  methods: {
    isTopicable,
    getNPSCatFromRating,
    displayTag(answer) {
      const displayTag = answer?.applicant?.display_tag && Object.values(answer.applicant.display_tag)?.[0] || '';
      if (displayTag) return displayTag?.length > 0 ? (displayTag?.join(', ') ?? displayTag) : '';
      return this.translatedText(answer, 'applicant.job_ad_title_translation.translation', answer.applicant.job_ad_title);
    },
    valueTranslatedFromTooltip(answer) {
      const jobAdText = answer?.applicant?.job_ad_title && this.showLabel ? `${this.$gettext('Annons')} – ${answer.applicant.job_ad_title} <br>` : '';
      const answerValueText = answer?.value ? `${this.$gettext('Svar')} – ${answer.value}` : '';
      return jobAdText + answerValueText || '';
    },
    translationIsSameLang(answer) {
      if (answer?.value_translation?.translated_language === answer?.value_translation?.original_language) return true;
      return false;
    },
    translatedText(answer, path, orgVal) {
      if (this.shouldTranslate) return get(answer, path) || orgVal;
      return orgVal;
    },
    prettyDate(value) {
      return value ? format(new Date(value), 'yyyy-MM-dd') : '';
    },
    mailto(email) {
      return email === null ? '' : `mailto:${email}`;
    },
    applicantEmail(email) {
      return email ?? '';
    },
    sortTopics(results) {
      return results?.map((result) => {
        result.topics.sort(fnSortById);
        return result;
      }) ?? [];
    },
    next() {
      this.loading = true;
      this.paginator.next()
        .then(({ results }) => {
          this.results = this.sortTopics(results);
          this.$emit('update-drag-area');
          this.loading = false;
          this.currentIndex += 1;
        });
    },
    previous() {
      this.loading = true;
      this.paginator.previous()
        .then(({ results }) => {
          this.results = this.sortTopics(results);
          this.$emit('update-drag-area');
          this.loading = false;
          this.currentIndex -= 1;
        });
    },
  },
};
</script>
