// import { getBaseUrl, postUrl } from 'Utils/api';
import { handleAuthError } from 'Utils/authHelpers';
import _mockData from './ats_mock_data';

/**
 * @typedef {Object} ATSMetric
 * @property {number} value - Metric value (e.g. time to fill in days)
 * @property {number} count - Count of occurrences
 * @property {string} date_str - Short date string (YYYY-MM)
 * @property {string} date_dt - Full date string (YYYY-MM-DD)
 */

/**
 * Mock ATS data with consistent format
 * @type {ATSMetric[]}
 */
const mockData = [
  {
    value: 20,
    count: 34,
    date_str: '2024-02',
    date_dt: '2024-02-01',
  },
  {
    value: 25,
    count: 19,
    date_str: '2024-03',
    date_dt: '2024-03-01',
  },
  {
    value: 31,
    count: 13,
    date_str: '2024-04',
    date_dt: '2024-04-01',
  },
  {
    value: 24,
    count: 21,
    date_str: '2024-05',
    date_dt: '2024-05-01',
  },
  {
    value: 30,
    count: 9,
    date_str: '2024-06',
    date_dt: '2024-06-01',
  },
];

const mapCandidatesOverTime = (data) => data.map(({ applied, copied, date_str }) => ({
  date: date_str,
  applied,
  copied,
}));

/**
 * Returns mocked candidates over time data
 * @returns {Promise<ATSMetric[]>}
 * @throws {Error}
 *  */

const getCandidatesOverTime = async () => {
  try {
    return mapCandidatesOverTime(_mockData.candidatesOverTime);
  } catch (error) {
    handleAuthError(error);
    throw error;
  }
};

/**
 * Returns mocked dropoff rates data
 * @returns {Promise<ATSMetric[]>}
 */

const getDropoffRates = async () => {
  try {
    return _mockData.dropoffRates;
  } catch (error) {
    handleAuthError(error);
    throw error;
  }
};

/**
 * Returns mocked time to fill data
 * @returns {Promise<ATSMetric[]>}
 */
const getTimeToFill = async () => {
  try {
    return _mockData.timeToFill;
  } catch (error) {
    handleAuthError(error);
    throw error;
  }
};

// const mapTimeInWorkflow = (data) => [{ ...data, status: 'Status' }];

/**
   * Returns mocked workflow data
   * @returns {Promise<ATSMetric[]>}
   */
const getTimeInWorkflow = async ({ groupBy }) => {
  try {
    if (groupBy === 'status type') {
      return _mockData.timeInWorkflowByType;
    }
    return _mockData.timeInWorkflow;
  } catch (error) {
    handleAuthError(error);
    throw error;
  }
};

const mapJobAdViews = (data) => data.map(({ views, applications, date_dt }) => ({
  date: new Date(date_dt),
  views,
  applications,
}));

/**
   * Returns mocked job ad performance data
   * @returns {Promise<ATSMetric[]>}
   */
const getJobAdViews = async () => {
  try {
    return mapJobAdViews(_mockData.jobApplicationsOverTime);
  } catch (error) {
    handleAuthError(error);
    throw error;
  }
};

/**
   * Returns mocked pass through rate data
   * @returns {Promise<ATSMetric[]>}
   */
const getPassThroughRate = async ({ groupBy }) => {
  try {
    if (groupBy === 'status type') {
      return _mockData.dropoffRates.toSpliced(5).map((item) => {
        const { benchmarkPerc, ...rest } = item;
        return rest;
      });
    }
    return mockData.toSpliced(4).map((item, i) => ({
      ...item,
      count: item.count * 4,
      key: ['new', 'screening', 'interview', 'offer'][i],
    }));
  } catch (error) {
    handleAuthError(error);
    throw error;
  }
};

const getTopSources = async ({ groupBy } = {}) => {
  if (groupBy && _mockData.topSourcesByType[groupBy]) {
    return _mockData.topSourcesByType[groupBy];
  }
  return _mockData.topSources;
};

const mapMockData = (data) => Object.entries(data).map(([key, value]) => ({
  status: key,
  value,
}));

const getEventsConducted = async ({ groupBy } = {}) => {
  try {
    if (groupBy === 'status type') {
      return [
        { status: 'active', screening: 45, testSent: 20, testCompleted: 13, firstInterview: 10, interviewCompleted: 5, secondInterview: 2 },
        { status: 'rejected', rejected: 10, withdrawn: 5 },
        { status: 'hired', hired: 30 },
      ];
    }
    // Default to status grouping
    return mapMockData(_mockData.eventsConducted);
  } catch (error) {
    handleAuthError(error);
    throw error;
  }
};

const getLostReasons = async () => {
  try {
    return _mockData.lostReasons;
  } catch (error) {
    handleAuthError(error);
    throw error;
  }
};

const getDevices = async ({ groupBy } = {}) => {
  try {
    // Always return fresh data based on groupBy
    const baseData = _mockData.devices;
    if (groupBy === 'Applicants') {
      return baseData.map((item) => ({
        ...item,
        count: Math.floor(item.count * 1.5),
      }));
    }
    if (groupBy === 'Views') {
      return baseData.map((item) => ({
        ...item,
        count: Math.floor(item.count * 3.2),
      }));
    }
    return baseData;
  } catch (error) {
    handleAuthError(error);
    throw error;
  }
};

const getTimeToHire = async () => {
  try {
    return _mockData.timeToHire;
  } catch (error) {
    handleAuthError(error);
    throw error;
  }
};

const getTotalHires = async () => {
  try {
    return _mockData.totalHires;
  } catch (error) {
    handleAuthError(error);
    throw error;
  }
};
const getPublishedJobs = async () => {
  try {
    return _mockData.publishedJobs;
  } catch (error) {
    handleAuthError(error);
    throw error;
  }
};

const getRejectionCommunicated = async () => {
  try {
    return _mockData.rejectionCommunicated;
  } catch (error) {
    handleAuthError(error);
    throw error;
  }
};

const getCandidatesPerJob = async () => {
  try {
    return _mockData.candidatesPerJob;
  } catch (error) {
    handleAuthError(error);
    throw error;
  }
};

const getOpenJobs = async () => {
  try {
    return _mockData.openJobs;
  } catch (error) {
    handleAuthError(error);
    throw error;
  }
};

const getApplicationCompletion = async () => {
  try {
    return _mockData.applicationCompletion;
  } catch (error) {
    handleAuthError(error);
    throw error;
  }
};

const getTotalCandidates = async () => {
  try {
    return _mockData.totalCandidates;
  } catch (error) {
    handleAuthError(error);
    throw error;
  }
};

const fetchATSFns = {
  'candidates-over-time': getCandidatesOverTime,
  'dropoff-rates': getDropoffRates,
  'time-to-fill': getTimeToFill,
  'time-to-hire': getTimeToHire,
  'published-jobs': getPublishedJobs,
  'total-hires': getTotalHires,
  'total-candidates': getTotalCandidates,
  'time-in-workflow': getTimeInWorkflow,
  'job-ad-views': getJobAdViews,
  'pass-through-rate': getPassThroughRate,
  'top-sources': getTopSources,
  'events-conducted': getEventsConducted,
  'lost-reasons': getLostReasons,
  'rejection-communicated': getRejectionCommunicated,
  'candidates-per-job': getCandidatesPerJob,
  'application-completion': getApplicationCompletion,
  'open-jobs': getOpenJobs,
  devices: getDevices,
};

/**
 * Request ATS data based on metric
 * @param {string} metric - ATS metric
 * @returns {Promise<ATSMetric[]>}
 */

const requestATSData = async (metric, { groupBy } = {}) => {
  if (!fetchATSFns[metric]) {
    throw new Error(`Invalid ATS metric: ${metric}`);
  }
  return fetchATSFns[metric]({ groupBy });
};

export default requestATSData;
