<template>
  <div>
    <div
      v-tc-loader-bar="loading"
      class="query-result-single-value p-card"
    >
      <div v-if="hasData">
        <p
          v-if="isPrintViewRoute || show('count')"
          class="query-result-totalcount subtle-text"
        >
          <span>
            {{ $pgettext('Title - totalCount','Antal svar:') }}
          </span> {{ totalCount.segment }}
        </p>
        <div class="text-center">
          <p
            v-tooltip="{
              content: () => trendTitle ,
              strategy: 'fixed',
              container: 'body',
            }"
          >
            <i
              class="tc-color-grey zmdi"
              :class="trendIcon"
            /> <span class="hidden-small-down">
              Trend {{ decoratedTrendVal }}
            </span> <span class="hidden-small-up">
              {{ decoratedTrendVal }}
            </span>
          </p>
        </div>
        <apexchart
          v-if="!apexImg"
          ref="apex"
          height="200"
          width="100%"
          type="area"
          :options="chartOptions"
          :series="ampedSeries"
          @animation-end="isPrintViewRoute && generateApexPng($refs?.apex)"
        />
        <img
          v-if="isPrintViewRoute && apexImg"
          :src="apexImg"
        >
        <div class="compare-widget">
          <p
            v-tooltip="benchmarkTitle"
            class="tc-color-grey h2 mb-0"
            :title="benchmarkTitle"
          >
            <i class="zmdi zmdi-globe pr-2" /> {{ formattedValues.global }}
          </p>
          <p
            v-tooltip="$gettext('Genomsnittet för ert företag (alla segment)')"
            class="tc-color-grey h2 mb-0"
            :title="$gettext('Genomsnittet för ert företag (alla segment)')"
          >
            <i class="zmdi zmdi-city pr-2" />  {{ formattedValues.customer }}
          </p>
        </div>

        <div class="tc-single-value-box">
          <div
            v-tooltip="{ content: boxedNumberTooltip, html: true }"
            class="boxed-number"
            :class="boxedNumberClass"
          >
            {{ formattedValues.segment }}
            <p
              v-if="questionType === 'yesno'"
              class="small-text tc-color-grey"
            >
              {{ yesOrNo }}
            </p>
          </div>
        </div>
      </div>
      <div
        v-if="!loading && !hasData"
        class="mx-8"
      >
        <p class="alert alert-info">
          <i class="zmdi zmdi-info" />
          <span>{{ infoMessage }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Apexchart from 'vue3-apexcharts';
import { merge, isNumber, isBoolean, compact, forOwn, isEmpty } from 'lodash-es';
import eventBus from 'Utils/eventBus';
import trendLine from 'Utils/trendLine';
import { brighten } from 'Utils/chartColors';
import { show, GRAPH_TYPES, QUESTION_GRAPH_TYPE_SCALES } from 'Utils/graph';
import { /* hydrateMissingStatsKeys, */ equalLiteral, translateBaseValue, getNPSColorClass, prefixNPSValue, roundNumber, globalTuplets } from 'Utils/general';
import { getApexChartBase64 } from 'Utils/apexchart';

export default {
  name: 'SingleValue',
  components: {
    Apexchart,
  },
  props: {
    card: Object,
    contextFilter: Object,
    compiledFilter: Object,
    compiledBenchmark: Object,
    hasData: Boolean,
    loading: Boolean,
    columnLabels: Object,
    columnTitles: Object,
    question: {
      type: Object,
      default: () => ({ options: { cnps: false } }),
    },
    toqResponse: Array,
    toqGlobalResponse: Array,
  },
  emits: ['update-drag-area'],
  data() {
    return {
      trendline: [],
      trendSeries: [],
      noData: false,
      amplification: 10,
      overwriteChartData: {},
      reverseStats: null,
      infoMessage: '',
      yellow: {
        cnps: { lo: -0.01, hi: 0.01 },
        yesno: { lo: -0.025, hi: 0.025 },
        rating: { lo: -0.1, hi: 0.1 },
      },
      abortToken: Math.random().toString(10).substring(2),
      apexImg: '',
    };
  },
  computed: {
    ...mapGetters([
      'segmentId',
      'segmentName',
      'customerId',
      'customerName',
    ]),
    isPrintViewRoute() { return this.$route.name?.indexOf('print-') > -1; },
    totalCount() {
      return {
        segment: this.toqResponse?.[4]?.respondent_count ?? this.toqResponse?.[4]?.count ?? null,
        customer: this.toqResponse?.[3]?.respondent_count ?? this.toqResponse?.[4]?.count ?? null,
      };
    },
    boxedNumberTooltip() {
      const benchmark = this.formattedValues.global;
      const scale = QUESTION_GRAPH_TYPE_SCALES?.[this.questionType] || '';
      switch (this.boxedNumberClass) {
        case 'tc-color-green':
          return this.$pgettext('tooltip — boxedNumberClass green', 'Ni presterar bättre jämfört med benchmarken: %{benchmark}.<br> På en skala från %{scale}', { benchmark, scale }, { html: true });
        case 'tc-color-yellow':
        case 'tc-color-yellow-dark':
          return this.$pgettext('tooltip — boxedNumberClass yellow', 'Ni presterar nära snittet jämfört med benchmarken: %{benchmark}.<br> På en skala från %{scale}', { benchmark, scale }, { html: true });
        case 'tc-color-red':
          return this.$pgettext('tooltip — boxedNumberClass rerd', 'Ni presterar sämre jämfört med benchmarken: %{benchmark}.<br> På en skala från %{scale}', { benchmark, scale }, { html: true });
        case 'tc-color-grey':
        default:
          // return this.$pgettext('tooltip — boxedNumberClass grey', 'Kan inte jämföra med benchmarken');
          return '';
      }
    },
    benchmarkTitle() {
      return isEmpty(this.compiledBenchmark)
        ? this.$gettext('Genomsnittet för Global data')
        : this.$gettext('Genomsnittet för Benchmark data');
    },
    singleValue() {
      return this.hasData
        ? {
          segment: roundNumber(this.toqResponse?.[4]?.score ?? NaN, 5),
          customer: roundNumber(this.toqResponse?.[3]?.score ?? NaN, 5),
          global: roundNumber(this.toqGlobalResponse?.[1]?.score ?? NaN, 5),
        }
        : { segment: null, customer: null, global: null };
    },
    chartData() {
      const id = `${this.questionId}-${this.abortToken}`;
      const questionType = this.questionType;
      const baseValues = translateBaseValue(this.showBaseValues, this.question);
      const segmentSerie = this.serie(this.toqResponse[0], this.segmentName);
      const series = [segmentSerie];
      const labels = segmentSerie?.label || [];

      return {
        id, series, questionType, baseValues, labels,
      };
    },
    yesOrNo() {
      return translateBaseValue(this.chartData.baseValues, this.question);
    },
    ampedSeries() {
      if (this.trendSeries[0] === undefined || this.trendSeries[0].data[0] === -1) return [{ data: [200, 200], name: 'Trend' }];
      return this.amplify([{ ...this.trendSeries[0] }]);
    },
    formattedValues() {
      const values = { segment: '–', customer: '–', global: '–' };
      forOwn({ ...this.singleValue }, (value, key) => {
        if (value === null) {
          values[key] = '–';
          return;
        }
        if (Number.isNaN(value)) {
          values[key] = '–';
          return;
        }
        values[key] = Math.abs(value); //! what about minus?
        switch (this.questionType) {
          case 'rating':
            values[key] = roundNumber(value, 1);
            if (value === 10.0 || value === '10.0') values[key] = 10;
            break;
          case 'yesno':
            if ((this.showBaseValues === 'ja' && this.reverseStats)
             || (this.showBaseValues === 'nej' && !this.reverseStats)) {
              values[key] = `${roundNumber((1 - value) * 100, 0)}%`;
            } else {
              values[key] = `${roundNumber(value * 100, 0)}%`;
            }
            // values[key] = `${roundNumber(hydrateMissingStatsKeys(value)[this.showBaseValues], 0)}%`;
            break;
          case 'cnps':
            values[key] = prefixNPSValue(roundNumber(value * 100, 0));
            break;
          default:
            values[key] = roundNumber(value, 0);
            break;
        }
      });
      return values;
    },
    showBaseValues() {
      const baseValues = this.card?.metadata?.show?.columns?.baseValues;
      if (baseValues?.length === 1) return baseValues[0];
      if (this.questionType === 'yesno') return this.reverseStats ? 'nej' : 'ja';
      return '';
    },
    trendVal() {
      if (this.trendSeries[0] === undefined || this.trendSeries[0].data.length <= 1) return '–';
      let trend = this.trendSeries[0].data;
      return (trend[1] - trend[0]).toFixed(1);
    },
    decoratedTrendVal() {
      if (this.trendVal === '–') return '–';
      if (Number.isNaN(this.trendVal) || this.trendVal === 'NaN') return '–';
      let val = this.plusMinus(this.trendVal) + this.trendVal;
      return this.questionType === 'yesno' ? `${val}%` : val;
    },
    boxedNumberClass() {
      if (Number.isNaN(this.singleValue.segment) || Number.isNaN(this.singleValue.global)) return 'tc-color-grey';
      const val = this.singleValue.segment - this.singleValue.global;
      if (this.isYellow(val, this.questionType)) return 'tc-color-yellow-dark';
      return getNPSColorClass(val);
    },
    trendIcon() {
      if (this.trendVal > 0) return 'zmdi-trending-up';
      if (this.trendVal < 0) return 'zmdi-trending-down';
      return 'zmdi-trending-flat';
    },
    trendTitle() {
      if (this.trendSeries[0] === undefined || this.trendSeries[0]?.data.length <= 1) return this.$pgettext('Tooltip - single value trend', 'Det finns inte tillräcklig data för att räkna ut trenden');
      return this.$pgettext(
        'Tooltip - single value trend',
        'Trenden är skillnaden från början till slut för valda perioden, beräknad på det linjära medelvärdet för perioden. En positiv siffra för trenden menar en uppåtgående trend.',
      );
    },
    questionId() { return this.card.metadata.question; },
    filter() { return this.card.metadata.filter; },
    questionType() { return this.question?.options?.cnps ? 'cnps' : this.question?.question_type; },
    chartOptions() {
      return merge({}, this.overwriteChartData, {
        chart: {
          sparkline: {
            enabled: true,
          },
          id: this.chartData.id || '0',
        },
        colors: [brighten('#769ba3', 80)],
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.8,
            opacityTo: 0.6,
            stops: [0, 60, 100],
          },
        },
        stroke: {
          show: true,
          curve: 'straight', // ['smooth', 'straight', 'stepline']
          lineCap: 'butt', // round, butt, square
          width: 2,
        },
      });
    },
  },
  watch: {
    'card.metadata.show.columns.baseValues': {
      deep: true,
      handler(newVal, oldVal) {
        this.chartData.baseValues = translateBaseValue(this.showBaseValues, this.question);
        if (!equalLiteral(newVal, oldVal)) this.drawGraph();
      },
    },
    'card.metadata.filter': {
      immediate: true,
      deep: true,
      handler(newFilter, oldFilter) {
        if (!equalLiteral(newFilter, oldFilter)) this.setup();
      },
    },
    compiledFilter(newFilter, oldFilter) {
      if (!equalLiteral(newFilter, oldFilter)) this.setup();
    },
    toqResponse(response) {
      if (this.questionType === 'yesno') {
        forOwn(response[0], (o) => {
          // eslint-disable-next-line max-len
          if (o !== null && !isBoolean(this.reverseStats) && isBoolean(o.reverse_stats)) this.reverseStats = o.reverse_stats;
        });
      }
    },
    'chartData.series': {
      handler(series) {
        if (series[0]?.data) this.drawGraph();
      },
    },
    async loading(state) {
      if (state === true) {
        this.infoMessage = this.$pgettext('Info - SingleValue', 'Laddar data…');
      } else {
        this.infoMessage = this.$pgettext('Info - SingleValue', 'Det finns ingen data. Prova att ändra filter.');
      }
      await this.$nextTick();
      this.$emit('update-drag-area');
    },
    hasData(state) {
      if (state) this.$nextTick(() => { this.$emit('update-drag-area'); });
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.setup();
      // this.chartData = merge({}, this.chartData, { baseValues: this.showBaseValues });
    });
  },
  beforeUnmount() {
    eventBus.$emit(`abortRequest:${this.abortToken}`);
    if (this.$refs.apex) this.$refs.apex.destroy();
  },
  methods: {
    async generateApexPng(chartInstance) {
      this.apexImg = await getApexChartBase64(chartInstance);
    },
    roundIfNum(number, qtype) {
      let decimal;
      switch (qtype) {
        case 'cnps':
        case 'yesno':
          decimal = 0;
          break;
        case 'rating':
          decimal = 2;
          break;
        default:
          decimal = 0;
      }
      if (isNumber(number)) return roundNumber(number, decimal);
      return null;
    },
    serie(response, name) {
      const count = [];
      const data = [];
      const label = [];
      forOwn(response, (val, key) => {
        switch (this.questionType) {
          case 'cnps':
            data.push(val?.score != null ? this.roundIfNum(val.score * 100, this.questionType) : null);
            break;
          case 'rating':
            data.push(val?.score != null ? this.roundIfNum(val.score, this.questionType) : null);
            break;
          case 'yesno':
            if (val?.score === undefined && (val?.ja || val?.nej)) {
              // eslint-disable-next-line max-len
              data.push(this.roundIfNum(globalTuplets(this.calcTuplet(val.ja, val.nej), this.reverseStats)[this.showBaseValues], this.questionType));
            } else {
              // eslint-disable-next-line max-len
              data.push(val?.score != null ? this.roundIfNum(globalTuplets(val.score, this.reverseStats)[this.showBaseValues], this.questionType) : null);
            }
            break;
          default:
        }
        count.push(val?.respondent_count ?? val?.count ?? null);
        label.push(val?.date_str || key);
      });
      return {
        count, data, label, name,
      };
    },
    isYellow(val, questionType) {
      if (questionType === 'cnps' && val <= this.yellow.cnps.hi && val >= this.yellow.cnps.lo) return true;
      if (questionType === 'yesno' && val <= this.yellow.yesno.hi && val >= this.yellow.yesno.lo) return true;
      if (questionType === 'rating' && val <= this.yellow.rating.hi && val >= this.yellow.rating.lo) return true;
      return false;
    },
    amplify(series) {
      const amp = ((100 - this.amplification) / 100);
      const arr = [...series[0].data];
      if (arr[0] < arr[1]) {
        arr[0] *= amp;
      } else if (arr[0] > arr[1]) {
        arr[1] *= amp;
      }
      series[0].data = arr;
      return series;
    },
    plusMinus(value) {
      if (Number.isNaN(value)) return '';
      if (value > 0) return '+';
      if (value < 0) return '';
      return '±';
    },
    getTrendline(series) {
      const stripNull = compact(series[0].data);
      let invalidSingleValue = this.formattedValues.segment === null;
      if ((series[0].data.length <= 1 || stripNull.length <= 1) && invalidSingleValue) { // ? is this enough: stripNull.length <= 1
        this.infoMessage = this.$pgettext('Info - SingleValue', 'Det finns ingen data. Prova att ändra filter.');
        return [-1];
      }

      // ? add +100 to solve problem with area chart turned upside down for negative values. y-axis min/max is set to 0-200
      const cnpsOffset = this.questionType === 'cnps' ? 100 : 0;
      const segmentSerie = stripNull.map((val, i) => [i + 1, val + cnpsOffset]); // series[0] ==> segment

      const trendObject = trendLine.polynomial(segmentSerie, { precision: 1, order: 1 });
      const li = trendObject.points.length - 1;

      if (Number.isNaN(trendObject.r2)
      || Number.isNaN(trendObject.points[0][1])
      || Number.isNaN(trendObject.points[li][1])) {
        return [-1];
      }
      return trendObject.points.map((el) => el[1]);
    },
    drawGraph() {
      let empty = false;
      this.chartData.series.forEach((serie) => {
        if (empty) return;
        if ((serie.data.length <= 1 || compact(serie.data).length === 0)) empty = true;
      });
      if (!empty) {
        this.trendline = this.getTrendline(this.chartData.series);
        const { 0: trendlineFirst, [this.trendline.length - 1]: trendlineLast } = this.trendline;
        this.trendSeries = [{ data: [trendlineFirst, trendlineLast], name: 'Trend' }];
        this.overwriteChartData = merge({}, this.overwriteChartData, {
          xaxis: {
            categories: [1, 2],
          },
        });
        switch (this.questionType) {
          case 'rating':
            this.amplification = 7.5;
            this.overwriteChartData = merge({}, this.overwriteChartData, {
              yaxis: {
                max: 10,
                min: 0,
                forceNiceScale: false,
                show: false,
              },
            });
            break;
          case 'yesno':
            this.amplification = 10;
            this.overwriteChartData = merge({}, this.overwriteChartData, {
              yaxis: {
                max: 100,
                min: 0,
                forceNiceScale: false,
                show: false,
              },
            });
            break;
          case 'cnps':
            this.amplification = 10;
            this.overwriteChartData = merge({}, this.overwriteChartData, {
              yaxis: {
                max: 200,
                min: 0,
                forceNiceScale: false,
                show: false,
              },
            });
            break;
          default:
            break;
        }
        if (this.formattedValues.segment === '–') {
          this.noData = true;
          this.infoMessage = this.$pgettext('Info - SingleValue', 'Det finns ingen data. Prova att ändra filter.');
        } else {
          this.noData = false;
        }
        this.$emit('update-drag-area');
      } else {
        this.infoMessage = this.$pgettext('Info - SingleValue', 'Det finns ingen data. Prova att ändra filter.');
      }
    },
    show(key) {
      return show(key, this.question, GRAPH_TYPES.singleValue, {
        ...this.card.metadata.show,
        ...this.card.metadata.show.columns,
        ...this.card.metadata.show.rows,
      });
    },
    setup() {
      this.infoMessage = this.$pgettext('Info - SingleValue', 'Laddar data…');
      this.drawGraph();
    },
  },
};
</script>
<style>
.query-result-single-value g.apexcharts-yaxis-texts-g, .query-result-single-value g.apexcharts-yaxis {
  display: none !important;
}
</style>
