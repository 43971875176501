<template>
  <div class="baloo-notifications">
    <transition-group
      name="slide-fade"
      tag="div"
    >
      <div
        v-for="msg in notifications"
        :key="msg.id"
        class="alert dismissable"
        role="alert"
        :class="classes(msg)"
        :data-timer-paused="!!msg.timerPaused || null"
        @mouseenter="pauseTimer(msg)"
        @mouseleave="resumeTimer(msg)"
      >
        <div class="alert-title dismissable">
          <button
            class="btn btn-icon close"
            @click="remove(msg)"
          >
            <i class="zmdi zmdi-close" />
          </button>
          <i
            v-if="msg.level"
            class="zmdi"
            :class="iconClass(msg.level)"
          />
          {{ levelTitle(msg) }}
        </div>
        <p
          class="alert-text"
          :data-private="!!msg.scrub || null"
        >
          {{ translatedMessage(msg.text) }}
        </p>
        <div
          v-if="msg.actions && msg.actions.length > 0"
          class="alert-actions"
        >
          <template v-for="(action, index) in msg.actions">
            <button
              v-if="index < 2"
              :key="action.id"
              class="btn btn-ghost"
              @click="action.removeAfter
                ? action.value($event) || remove(msg)
                : action.value($event)"
            >
              {{ action.label }}
            </button>
          </template>
        </div>
      </div>
    </transition-group>
    <transition
      v-if="notifications.length > 1"
      name="slide-fade"
      tag="div"
    >
      <div
        v-if="notifications.length > 1"
        class="btn-group float-right"
      >
        <button
          class="btn-clear alert mt-2 cursor-pointer bold-text"
          @click="clear"
        >
          {{ clearAllText }}
          <i class="zmdi zmdi-close" />
        </button>
      </div>
    </transition>
  </div>
</template>

<script>
import { unescape } from 'lodash-es';
import { mapGetters, mapActions } from 'vuex';

const levelIconMap = {
  info: 'zmdi-info',
  warning: 'zmdi-alert-triangle',
  error: 'zmdi-block',
  success: 'zmdi-check',
};

export default {
  computed: {
    ...mapGetters([
      'notifications',
      'segmentId',
    ]),
    levelTitleMap() {
      return {
        info: this.$pgettext('Notification title — Info', 'Info'),
        warning: this.$pgettext('Notification title — Warning', 'Varning!'),
        error: this.$pgettext('Notification title — Error', 'Error'),
        success: this.$pgettext('Notification title — Success', 'Goda nyheter!'),
      };
    },
    clearAllText() {
      return this.$pgettext('Clear all notifications - Baloo button', 'Rensa alla');
    },
    translatedMessageMap() { // ? dispatch a notification.text with a key below to use translation instead
      return {
        'TM-401-retry': this.$pgettext('Notification TM-401-retry', 'Kunde inte skicka förfrågan, du hade loggats ut. Var god prova igen!'),
      };
    },
  },
  watch: {
    segmentId() {
      this.clear();
    },
  },
  mounted() {
    this.clear();
  },
  methods: {
    ...mapActions({
      clear: 'clearNotifications',
      remove: 'removeNotification',
      pauseTimer: 'pauseTimer',
      resumeTimer: 'resumeTimer',
    }),
    levelTitle({ level, title }) {
      if (title) return title;
      if (this.levelTitleMap[level] === undefined) return false;
      return this.levelTitleMap[level];
    },
    translatedMessage(text = '') {
      if (this.translatedMessageMap[text] === undefined) return unescape(text);
      return this.translatedMessageMap[text];
    },
    iconClass(level) {
      if (levelIconMap[level] === undefined) return false;
      return `${levelIconMap[level]}`;
    },
    classes(msg) {
      if (!msg.level) {
        return [];
      }
      let classNames = [];
      classNames.push(`alert-${msg.level}`);
      if (msg.type === 'pop') {
        classNames.push('tc-alert-timeout');
      } else if (msg.type === 'slow') {
        classNames.push('tc-alert-timeout-slow');
      }
      return classNames;
    },
  },
};
</script>
