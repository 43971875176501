<template>
  <div
    v-if="!loading && !hasData"
    class="alert alert-info mb-0"
  >
    <i class="zmdi zmdi-info" />
    {{ $pgettext('Funnel graph - No data', 'För lite data') }}
  </div>
  <div
    v-else
    ref="containerEl"
    class="funnel-container"
    :style="{ '--funnel-color-primary': colors[0] }"
  >
    <template v-if="loading">
      <div class="funnel-skeleton-container">
        <svg class="funnel-skeleton">
          <path
            v-for="({ segment }, i) in skeletonSegments"
            :key="i"
            class="funnel-skeleton-segment"
            :d="segment"
          />
        </svg>
      </div>
    </template>
    <template v-else>
      <template
        v-for="({ dropoff }, i) in segments"
        :key="i"
      >
        <span
          v-if="dropoff.label"
          class="funnel-dropoff-label"
          :class="{
            'hovered': hoveredSegment?.index === i,
            'blurred': hoveredSegment != null && hoveredSegment?.index !== i
          }"
          :style="{ top: `${dropoff.label.y}px`, left: `${dropoff.label.x}px` }"
        >
          <i-material-symbols-arrow-cool-down-rounded />
          {{ dropoff.label.textContent }}
        </span>
      </template>
      <svg>
        <defs>
          <linearGradient
            id="dropoff-gradient"
            x1="0"
            x2="1"
            y1="0"
            y2="0"
          >
            <stop
              offset="50%"
              stop-opacity="25%"
            />
            <stop
              offset="80%"
              stop-opacity="0%"
            />
          </linearGradient>
        </defs>
        <g
          v-for="({ segment, dropoff, benchmark }, i) in segments"
          :key="i"
        >
          <g>
            <path
              :d="dropoff.path"
              fill="url(#dropoff-gradient)"
            />
            <line
              v-if="dropoff.line"
              class="funnel-dropoff-line"
              :class="{
                'hovered': hoveredSegment?.index === i,
                'blurred': hoveredSegment != null && hoveredSegment?.index !== i
              }"
              v-bind="dropoff.line"
              :stroke-width="strokeWidth"
              :stroke-dasharray="dash"
            />
          </g>
          <path
            class="funnel-segment"
            :class="{
              'hovered': hoveredLegend != null && hoveredLegend === 0,
              'blurred': hoveredLegend != null && hoveredLegend !== 0
            }"
            :d="segment"
            :stroke-width="SEGMENT_STROKE_WIDTH"
            fill-opacity="0.8"
          />
          <path
            v-if="!_hideBenchmark"
            class="funnel-segment-benchmark"
            :class="{
              'hovered': hoveredLegend != null && hoveredLegend === 1,
              'blurred': hoveredLegend != null && hoveredLegend !== 1
            }"
            :d="benchmark"
            :stroke-width="strokeWidth"
            :stroke-dasharray="dash"
            :stroke="benchmarkColor"
            fill="transparent"
          />
        </g>
      </svg>
      <span
        v-if="!_hideBenchmark"
        class="funnel-benchmark-icon"
        :style="{
          width: `${benchmarkIconSize}px`,
          top: `${benchmarkIconPos.y}px`,
          left: `${benchmarkIconPos.x}px`
        }"
      >
        <i-material-symbols-globe />
      </span>
      <div
        class="funnel-overlay"
      >
        <div
          v-for="({ label, value, dropoff, benchmark }, index) in props.data"
          :key="index"
          v-element-hover="(state) => onHoverSegment(state, index)"
          v-tooltip="{
            content: `
              <div class='funnel-tooltip'>
                <span>${label}: <b>${value}%</b></span>
            ${
              !_hideBenchmark
                ? `<span>${$pgettext('Funnel graph - Benchmark', 'Benchmark')}: <b>${benchmark}%</b></span>`
                : ''
            }
                ${dropoff ? `<span>${$pgettext('Funnel graph - Dropoff', 'Dropoff')}: <b>${dropoff}%</b></span>` : ''}
              </div>
            `,
            html: true,
            placement: 'bottom',
          }"
          class="funnel-overlay-section"
          :class="{ 'blurred': hoveredSegment && hoveredSegment?.index !== index }"
        />
      </div>
    </template>
  </div>
  <div
    v-if="loading"
    class="funnel-legend funnel-legend-skeleton"
  >
    <div
      v-for="(_, index) in skeletonData"
      :key="index"
      class="funnel-legend-item-skeleton"
      :style="{ width: `${100 / skeletonData.length}%` }"
    />
  </div>
  <template v-else-if="hasData">
    <div class="funnel-legend">
      <div
        v-for="({ label: segmentLabel, value, benchmark, dropoff }, index) in props.data"
        :key="index"
        class="funnel-legend-item"
        :style="{ width: `${100 / props.data.length}%` }"
      >
        <div class="funnel-description">
          {{ segmentLabel }}
        </div>
        <div class="funnel-legend-row">
          <div class="flex flex-wrap gap-1">
            <span class="funnel-value">
              {{ value }}%
            </span>
            <span
              v-if="!_hideBenchmark"
              class="funnel-benchmark"
            >
              (<i-material-symbols-globe />{{ benchmark }}%)
            </span>
          </div>
          <span
            v-if="dropoff"
            class="funnel-dropoff"
          >
            <i-material-symbols-arrow-cool-down-rounded />
            {{ `${dropoff}%` }}
          </span>
        </div>
      </div>
    </div>
    <div class="apexcharts-legend tc-chip-container tc-chip-container--padded tc-chip-container--nonclickable">
      <div
        v-for="(label, i) in _labels"
        :key="i"
        v-element-hover="(state) => onHoverLegend(state, i)"
        class="apexcharts-legend-series"
      >
        <span
          class="apexcharts-legend-marker"
          :style="colors?.[i] ? `background-color: ${colors[i]}` : ''"
        />
        <div
          class="apexcharts-legend-text"
          v-text="label"
        />
      </div>
    </div>
  </template>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useElementSize, useBreakpoints } from '@vueuse/core';
import { vElementHover } from '@vueuse/components';
import { creamColors } from 'Utils/chartColors';
import gettext from '@/gettext';

const { $pgettext } = gettext;

// data: Array<{ label: string, value: number, benchmark: number | null }>
const props = defineProps({
  data: Array,
  labels: Array,
  loading: Boolean,
  hideBenchmark: { type: Boolean, default: false },
});

const breakpoints = useBreakpoints({
  small: 544,
});

const containerEl = ref(null);
const { width, height } = useElementSize(containerEl);

const hoveredSegment = ref(null);
const onHoverSegment = (state, index) => {
  if (state) hoveredSegment.value = { index, ...props.data[index] };
  else hoveredSegment.value = null;
};

const hoveredLegend = ref(null);
const onHoverLegend = (state, index) => {
  if (state) hoveredLegend.value = index;
  else hoveredLegend.value = null;
};

const isMobile = breakpoints.smallerOrEqual('small');
const strokeWidth = computed(() => (isMobile.value ? 1 : 2));
const dash = computed(() => (isMobile.value ? 3 : 5));

const benchmarkIconSize = computed(() => (isMobile.value ? 10 : 16));

const SEGMENT_GAP = 5; // Gap between funnel segments
const SEGMENT_STROKE_WIDTH = 2;
const SEGMENT_CORNER_RADIUS = 5;

const hasData = computed(() => props.data?.length > 0);

const segmentHeights = computed(() => props.data.map((item) => (item.value / 100) * height.value));
const benchmarkHeights = computed(() => props.data.map((item) => (item.benchmark / 100) * height.value));
const segmentWidth = computed(() => width.value / props.data.length);

const colors = computed(() => creamColors[2]);
const benchmarkColor = computed(() => colors.value[1]);

const benchmarkIconPos = computed(() => {
  const benchmarkEndHeight = benchmarkHeights.value[benchmarkHeights.value.length - 1];
  return { x: width.value, y: height.value - benchmarkEndHeight };
});

const _hideBenchmark = computed(() => props.hideBenchmark || props.data.some((item) => !item.benchmark));
const _labels = computed(() => (_hideBenchmark.value ? props.labels?.slice(0, -1) : props.labels));

const bezierCtrlPointX = (x, nextX) => x + (nextX - x) * 0.5;

const createFunnelPath = (x, nextX, y, val, nextVal, gap, strokeThickness, cornerRadius) => {
  const halfGap = gap * 0.5;
  const adjustedX = x + halfGap;
  const adjustedNextX = nextX - halfGap;
  const dropoffOffset = (nextX - x) * 0.5;
  const bottomY = y - (strokeThickness * 0.5);

  const calculateCornerRadius = (_val, maxRadius) => Math.min(_val * 0.5, maxRadius);

  // Dynamically calculate corner radii based on segment values
  const cornerRadiusTopLeft = calculateCornerRadius(val, cornerRadius);
  const cornerRadiusTopRight = calculateCornerRadius(nextVal, cornerRadius);
  const cornerRadiusBottomLeft = calculateCornerRadius(val, cornerRadius);
  const cornerRadiusBottomRight = calculateCornerRadius(nextVal, cornerRadius);

  const ctrlPointX = bezierCtrlPointX(adjustedX, adjustedNextX);

  // Create the path for the funnel segment
  let segment = `M${adjustedX},${y - val + cornerRadiusTopLeft}`; // Start at the top-left with a gap for the corner
  // Top-left rounded corner
  segment += ` A${cornerRadiusTopLeft},${cornerRadiusTopLeft} 0 0 1 ${adjustedX + cornerRadiusTopLeft},${y - val}`; // Arc to the top-left corner
  // Top curvature
  segment += ` C${ctrlPointX},${y - val},${ctrlPointX},${y - nextVal},${adjustedNextX - cornerRadiusTopRight},${y - nextVal}`; // Top curve, with rounded ends
  // Top-right rounded corner
  segment += ` A${cornerRadiusTopRight},${cornerRadiusTopRight} 0 0 1 ${adjustedNextX},${y - nextVal + cornerRadiusTopRight}`;
  // Line down the right side
  segment += ` L${adjustedNextX},${y - cornerRadiusBottomRight}`;
  // Bottom-right rounded corner
  segment += ` A${cornerRadiusBottomRight},${cornerRadiusBottomRight} 0 0 1 ${adjustedNextX - cornerRadiusBottomRight},${bottomY}`;
  // Line across the bottom
  segment += ` L${adjustedX + cornerRadiusBottomLeft},${bottomY}`;
  // Bottom-left rounded corner
  segment += ` A${cornerRadiusBottomLeft},${cornerRadiusBottomLeft} 0 0 1 ${adjustedX},${y - cornerRadiusBottomLeft}`;
  // Close the path
  segment += 'Z';

  let dropoff = `M${adjustedX},${y - val}`;
  dropoff += ` C${ctrlPointX},${y - val},${ctrlPointX},${y - nextVal},${adjustedNextX - cornerRadiusTopRight},${y - nextVal}`; // Top curve, with rounded ends
  dropoff += ` L${adjustedNextX + dropoffOffset},${y - nextVal}`;
  dropoff += ` L${nextX + dropoffOffset},${y - val}`;
  dropoff += 'Z';

  return { segment, dropoff };
};

const createBenchmarkPath = (x, nextX, y, val, nextVal) => {
  const ctrlPointX = bezierCtrlPointX(x, nextX);

  let line = `M${x},${y - val}`;
  line += ` C${ctrlPointX},${y - val},${ctrlPointX},${y - nextVal},${nextX},${y - nextVal}`;

  return line;
};

const segments = computed(() => {
  let x = 0;
  let paths = [];

  for (let i = 0; i < props.data.length; i++) {
    const nextX = x + segmentWidth.value;
    const value = segmentHeights.value[i];
    const nextValue = segmentHeights.value[i + 1] || value;

    const prevVal = i > 0 ? segmentHeights.value[i - 1] : null;

    const startY = height.value;

    const {
      segment,
      dropoff: dropoffPath,
    } = createFunnelPath(x, nextX, startY, value, nextValue, SEGMENT_GAP, SEGMENT_STROKE_WIDTH, SEGMENT_CORNER_RADIUS);

    let benchmark;
    const benchmarkValue = benchmarkHeights.value[i];
    if (benchmarkValue) {
      const nextBenchmarkValue = benchmarkHeights.value[i + 1] || benchmarkValue;
      const benchmarkX = i === 0 ? x + SEGMENT_GAP : x;
      const benchmarkNextX = i + 1 === props.data.length ? nextX - SEGMENT_GAP : nextX;
      benchmark = createBenchmarkPath(benchmarkX, benchmarkNextX, startY, benchmarkValue, nextBenchmarkValue);
    }

    let dropoff = {
      label: null,
      line: null,
      path: dropoffPath,
    };

    // Only show label & line if has dropoff data
    // in practice, this should be the first segment (100% opened emails)
    if (props.data[i].dropoff) {
      dropoff = {
        ...dropoff,
        label: {
          x,
          y: height.value - prevVal,
          textContent: `${props.data[i].dropoff}%`,
        },
        line: {
          x1: x,
          y1: height.value - prevVal,
          x2: x,
          y2: startY - value,
        },
      };
    }

    x = nextX;
    paths.push({
      segment,
      dropoff,
      benchmark: benchmark || null,
    });
  }

  return paths;
});

// Skeleton segments
const skeletonData = [100, 60, 30];
const skeletonSegmentWidth = computed(() => width.value / skeletonData.length);
const skeletonSegmentHeights = computed(() => skeletonData.map((item) => (item / 100) * height.value));

const skeletonSegments = computed(() => {
  let x = 0;
  let paths = [];

  for (let i = 0; i < skeletonData.length; i++) {
    const nextX = x + skeletonSegmentWidth.value;
    const value = skeletonSegmentHeights.value[i];
    const nextValue = skeletonSegmentHeights.value[i + 1] || value;

    const startY = height.value;

    const {
      segment,
    } = createFunnelPath(x, nextX, startY, value, nextValue, SEGMENT_GAP, SEGMENT_STROKE_WIDTH, SEGMENT_CORNER_RADIUS);

    x = nextX;
    paths.push({ segment });
  }

  return paths;
});
</script>
