<template>
  <div class="tc-card-overlay-body">
    <button
      v-for="colKey in Object.keys(colClasses)"
      :key="colKey"
      class="tc-card-overlay-btn"
      :disabled="currentColClass === colClasses[colKey]"
      :title="titleText(colKey)"
      @click.prevent="changeColClass(colKey)"
    >
      <p
        class="mb-2"
        v-text="colClasses?.[colKey]?.name?.() || ''"
      />
      <div
        class="tc-card-col-icon"
        :class="cardColIconClasses(colKey)"
      >
        <span /><span /><span />
      </div>
      <p
        v-if="currentColClass === colClasses[colKey]"
        class="mt-2"
      >
        <i class="zmdi zmdi-check mr-1" />
        <span v-text="$gettext('Vald')" />
      </p>
    </button>
  </div>
</template>

<script>
import gettext from '@/gettext';

const { $pgettext } = gettext;

const COLCLASS_OBJECTS = {
  'col-md-4': {
    name: () => $pgettext('Name — Card size sm', 'Liten'),
    sizeClass: 'sm',
  },
  'col-md-6': {
    name: () => $pgettext('Name — Card size md', 'Mellan'),
    sizeClass: 'md',
  },
  'col-md-8': {
    name: () => $pgettext('Name — Card size lg', 'Stor'),
    sizeClass: 'lg',
  },
  'col-xs-12': {
    name: () => $pgettext('Name — Card size xl', 'Fullbredd'),
    sizeClass: 'xl',
  },
};

export default {
  name: 'CardSizeButtons',
  props: {
    colClass: {
      type: String,
      default: '',
    },
  },
  emits: ['update:col-class'],
  data() {
    return {
      currentColClass: COLCLASS_OBJECTS[this.colClass] || COLCLASS_OBJECTS['col-xs-12'],
      colClasses: COLCLASS_OBJECTS,
    };
  },
  methods: {
    cardColIconClasses(colKey) {
      return {
        [`size-${this.colClasses[colKey].sizeClass}`]: true,
        active: this.currentColClass === this.colClasses[colKey],
        'mb-6': this.currentColClass !== this.colClasses[colKey],
      };
    },
    titleText(colKey) {
      const currentText = this.currentColClass === this.colClasses[colKey] ? this.$gettext('(nuvarande)') : '';
      return $pgettext(
        'Title — Change size help',
        'Byt storlek till %{className} %{currentText}',
        { className: this.colClasses?.[colKey].name() || '', currentText },
      );
    },
    changeColClass(colKey) {
      this.currentColClass = this.colClasses[colKey];
      this.$emit('update:col-class', colKey);
      if (typeof window !== 'undefined') window.dispatchEvent(new Event('resize')); // ? Bugfix for vue3-apexcharts not always updating size nicely
    },
  },
};
</script>
