const candidatesOverTime = [
  {
    applied: 20,
    copied: 9,
    date_str: '2024-01',
    date_dt: '2024-01-01',
  },
  {
    applied: 25,
    copied: 13,
    date_str: '2024-02',
    date_dt: '2024-02-01',
  },
  {
    applied: 14,
    copied: 3,
    date_str: '2024-03',
    date_dt: '2024-03-01',
  },
  {
    applied: 22,
    copied: 10,
    date_str: '2024-04',
    date_dt: '2024-04-01',
  },
  {
    applied: 21,
    copied: 6,
    date_str: '2024-05',
    date_dt: '2024-05-01',
  },
  {
    applied: 9,
    copied: 3,
    date_str: '2024-06',
    date_dt: '2024-06-01',
  },
  {
    applied: 14,
    copied: 5,
    date_str: '2024-07',
    date_dt: '2024-07-01',
  },
  {
    applied: 18,
    copied: 5,
    date_str: '2024-08',
    date_dt: '2024-08-01',
  },
  {
    applied: 4,
    copied: 0,
    date_str: '2024-09',
    date_dt: '2024-09-01',
  },
  {
    applied: 7,
    copied: 2,
    date_str: '2024-10',
    date_dt: '2024-10-01',
  },
  {
    applied: 13,
    copied: 5,
    date_str: '2024-11',
    date_dt: '2024-11-01',
  },
  {
    applied: 19,
    copied: 10,
    date_str: '2024-12',
    date_dt: '2024-12-01',
  },
];

const jobApplicationsOverTime = [
  {
    views: 20,
    applications: 2,
    date_str: '2024-01',
    date_dt: '2024-01-01',
  },
  {
    views: 18,
    applications: 3,
    date_str: '2024-02',
    date_dt: '2024-02-01',
  },
  {
    views: 24,
    applications: 4,
    date_str: '2024-03',
    date_dt: '2024-03-01',
  },
  {
    views: 18,
    applications: 9,
    date_str: '2024-04',
    date_dt: '2024-04-01',
  },
  {
    views: 22,
    applications: 6,
    date_str: '2024-05',
    date_dt: '2024-05-01',
  },
  {
    views: 27,
    applications: 12,
    date_str: '2024-06',
    date_dt: '2024-06-01',
  },
  {
    views: 30,
    applications: 18,
    date_str: '2024-07',
    date_dt: '2024-07-01',
  },
  {
    views: 28,
    applications: 9,
    date_str: '2024-08',
    date_dt: '2024-08-01',
  },
  {
    views: 45,
    applications: 14,
    date_str: '2024-09',
    date_dt: '2024-09-01',
  },
  {
    views: 32,
    applications: 9,
    date_str: '2024-10',
    date_dt: '2024-10-01',
  },
  {
    views: 24,
    applications: 2,
    date_str: '2024-11',
    date_dt: '2024-11-01',
  },
  {
    views: 39,
    applications: 16,
    date_str: '2024-12',
    date_dt: '2024-12-01',
  },
];

const timeToFill = [{
  key: 'hired',
  count: 53,
}];

const timeInWorkflow = [
  { key: 'new', count: 9 },
  { key: 'screening', count: 5 },
  { key: 'test_complete', count: 1 },
  { key: 'interview', count: 6 },
];

const topSources = [
  {
    key: 'arbetsformedlingen',
    count: 49,
  },
  {
    key: 'direct',
    count: 19,
  },
  {
    key: 'linkedin',
    count: 15,
  },
  {
    key: 'ledigajobb',
    count: 10,
  },
];

const lostReasons = [
  {
    key: 'Other offer',
    count: 9,
  },
  {
    key: 'Not interested',
    count: 1,
  },
  {
    key: 'Salary expectations',
    count: 2,
  },
  {
    key: 'Other',
    count: 4,
  },
];

const devices = [
  {
    key: 'desktop',
    count: 40,
  },
  {
    key: 'mobile',
    count: 10,
  },
  {
    key: 'other',
    count: 20,
  },
];

const timeToHire = {
  key: 'Time to hire',
  value: 15,
  trend: -0.15,
};

const totalCandidates = {
  key: 'Total candidates',
  value: 200,
  trend: 0.1,
};

const totalHires = {
  key: 'Total hires',
  value: 8,
  trend: 0.2,
};

const publishedJobs = {
  key: 'Published jobs',
  value: 12,
  trend: 0.1,
};

const rejectionCommunicated = {
  key: 'Rejection communicated',
  value: 70,
  trend: 0.1,
};

const candidatesPerJob = {
  key: 'Candidates per job',
  value: 20,
  trend: 0.1,
};

const applicationCompletion = {
  key: 'Application completion',
  value: 90,
  trend: 0.1,
};

const openJobs = {
  key: 'Open jobs',
  value: 10,
  trend: 0.1,
};

const passThroughRates = [
  { key: 'New', count: 74 },
  { key: 'Active', count: 78 },
  { key: 'Hired', count: 5 },
];

// const dropoffRates = [
//   { key: 'Skickade email', count: 200, benchmarkPerc: 1 },
//   { key: 'Öppnade email', count: 100, benchmarkPerc: 0.6 },
//   { key: 'Öppnade formulär', count: 125, benchmarkPerc: 0.5 },
//   { key: 'Svarade på formulär', count: 50, benchmarkPerc: 0.2 },
// ];
const dropoffRates = [
  { key: 'New', count: 74, benchmarkPerc: 1 },
  { key: 'Screening', count: 22, benchmarkPerc: 0.65 },
  { key: 'Test sent', count: 12, benchmarkPerc: 0.45 },
  { key: 'Test completed', count: 13, benchmarkPerc: 0.40 },
  { key: 'First interview', count: 13, benchmarkPerc: 0.30 },
  { key: 'Interview completed', count: 12, benchmarkPerc: 0.25 },
  { key: 'Second interview', count: 6, benchmarkPerc: 0.15 },
];

const timeInWorkflowByType = [
  {
    key: 'new',
    count: 9,
  }, {
    key: 'active',
    count: 5,
  },
];

const topSourcesByType = {
  Applicants: [
    { key: 'linkedin', count: 85 },
    { key: 'direct', count: 45 },
    { key: 'arbetsformedlingen', count: 35 },
    { key: 'ledigajobb', count: 25 },
  ],
  Hired: [
    { key: 'linkedin', count: 12 },
    { key: 'direct', count: 8 },
    { key: 'arbetsformedlingen', count: 5 },
    { key: 'ledigajobb', count: 3 },
  ],
  Rejected: [
    { key: 'linkedin', count: 40 },
    { key: 'direct', count: 25 },
    { key: 'arbetsformedlingen', count: 20 },
    { key: 'ledigajobb', count: 15 },
  ],
  Views: [
    { key: 'linkedin', count: 250 },
    { key: 'direct', count: 180 },
    { key: 'arbetsformedlingen', count: 150 },
    { key: 'ledigajobb', count: 90 },
  ],
  'In progress': [
    { key: 'linkedin', count: 33 },
    { key: 'direct', count: 12 },
    { key: 'arbetsformedlingen', count: 10 },
    { key: 'ledigajobb', count: 7 },
  ],
};

const devicesByType = {
  Applicants: [
    { key: 'desktop', count: 150 },
    { key: 'mobile', count: 85 },
    { key: 'other', count: 15 },
  ],
  Views: [
    { key: 'desktop', count: 450 },
    { key: 'mobile', count: 380 },
    { key: 'other', count: 70 },
  ],
};

const eventsConducted = {
  active: 78,
  rejected: 70,
  hired: 8,
};

const eventsConductedByType = {
  interview: 45,
  assessment: 35,
  screening: 76,
};

export default {
  candidatesOverTime,
  jobApplicationsOverTime,
  timeToFill,
  timeInWorkflow,
  topSources,
  lostReasons,
  devices,
  timeToHire,
  eventsConducted,
  passThroughRates,
  dropoffRates,
  totalCandidates,
  totalHires,
  publishedJobs,
  rejectionCommunicated,
  candidatesPerJob,
  applicationCompletion,
  openJobs,
  timeInWorkflowByType,
  topSourcesByType,
  devicesByType,
  eventsConductedByType,
};
