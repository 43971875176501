<template>
  <div>
    <TopicsIcon
      v-if="!props.isExplanatory"
      v-tooltip="{
        content: () => $npgettext(
          'Tooltip — Topic title',
          'Vår AI har automatiskt kategoriserat detta svar enligt följande kategori',
          'Vår AI har automatiskt kategoriserat detta svar enligt följande kategorier',
          props.topics.length,
        ),
        distance: 12,
      }"
      class="tc-color-purple valign-middle cursor-help mr-2"
      style="margin-top: -2px"
    />
    <ul
      class="badge-list badge-list-topics"
      v-bind="$attrs"
    >
      <li
        v-for="topic in props.topics"
        :key="topic.id"
        v-tooltip="{
          content: () => $pgettext(
            'Tooltip - Topic description',
            'Automatiskt kategoriserat som <b>%{name}</b><br/><br/>%{desc}',
            { name: topic.name, desc: topic.description },
            { html: true },
          ),
          html: true,
          distance: 12,
        }"
        class="badge badge-topic"
      >
        <i :class="`zmdi zmdi-circle mr-1 badge-topic--${topic.id}`" />
        <span class="badge-topic-value">{{ topic.name }}</span>
        <span v-if="props.isExplanatory">&ensp;･&ensp;{{ topic.description }}</span>
      </li>
    </ul>
  </div>
</template>

<script setup>
import TopicsIcon from 'Components/parts/icons/TopicsIcon';

const props = defineProps({
  topics: Array,
  isExplanatory: {
    type: Boolean,
    default: false,
  },
});
</script>
