export default {
  state: {
    role: 'admin',
    userType: 'customerUser',
    me: {
      id: 1082,
      first_name: '',
      last_name: '',
      username: 'johan',
      email: 'johan@trustcruit.com',
      url: 'http://localhost:8000/feedback/accounts/api/user/1082/',
      customers: [],
      notification_preferences: {
        flagged_respondent: {
          single_send: true,
          summary_send: false,
        },
      },
      visual_preferences: {
        surveyPageView: 'table',
        segmentSelectWidth: 500,
      },
      related_customers: [
        {
          id: 9,
          name: 'Acme Recruiters',
        },
        {
          id: 138,
          name: 'Amendo',
        },
        {
          id: 139,
          name: 'Blocket',
        },
      ],
      segments: [
        {
          id: 257,
          name: 'All data',
          customer: 9,
        },
        {
          id: 1606,
          name: 'johans segment',
          customer: 9,
        },
        {
          id: 433,
          name: 'All data',
          customer: 138,
        },
        {
          id: 1127,
          name: 'All data (utan individ)',
          customer: 138,
        },
        {
          id: 1135,
          name: 'Angelo Jovic',
          customer: 138,
        },
        {
          id: 1133,
          name: 'Anna Kirsten',
          customer: 138,
        },
        {
          id: 1134,
          name: 'Anna Svensk',
          customer: 138,
        },
        {
          id: 1637,
          name: 'Carola Holgersson',
          customer: 138,
        },
        {
          id: 1130,
          name: 'Charlotte Umunna',
          customer: 138,
        },
        {
          id: 1140,
          name: 'Helene Hansson',
          customer: 138,
        },
        {
          id: 1326,
          name: 'Johanna Hallenberg',
          customer: 138,
        },
        {
          id: 1254,
          name: 'Katarina Joneström',
          customer: 138,
        },
        {
          id: 1137,
          name: 'Linn Oldenmark',
          customer: 138,
        },
        {
          id: 1136,
          name: 'Lisa Eliasson',
          customer: 138,
        },
        {
          id: 1139,
          name: 'Martin O. Carlbom',
          customer: 138,
        },
        {
          id: 1253,
          name: 'Molly Andersson',
          customer: 138,
        },
        {
          id: 1138,
          name: 'Oskar Windolf',
          customer: 138,
        },
        {
          id: 1251,
          name: 'Pernilla Ambratt',
          customer: 138,
        },
        {
          id: 1142,
          name: 'Pernilla Keusch',
          customer: 138,
        },
        {
          id: 1126,
          name: 'Region Norr',
          customer: 138,
        },
        {
          id: 1224,
          name: 'Region Stockholm',
          customer: 138,
        },
        {
          id: 1124,
          name: 'Region Väst',
          customer: 138,
        },
        {
          id: 1248,
          name: 'Renee Li',
          customer: 138,
        },
        {
          id: 1250,
          name: 'Shahrokh Afyouni',
          customer: 138,
        },
        {
          id: 1131,
          name: 'Shaun Ogden',
          customer: 138,
        },
        {
          id: 1123,
          name: 'Stockholm BoFF',
          customer: 138,
        },
        {
          id: 855,
          name: 'Stockholm Ekonomi',
          customer: 138,
        },
        {
          id: 1122,
          name: 'Stockholm Rekrytering',
          customer: 138,
        },
        {
          id: 461,
          name: 'All data',
          customer: 139,
        },
      ],
      is_staff: true,
    },
    textFilter: {
      customerName: 'Amendo',
    },
    loading: false,
    currentSegment: {
      id: 433,
      name: 'All data',
      customer: {
        all_steps: ['step1', 'step2', 'step5'],
        inactive_steps: ['step2'],
        id: 138,
        sprancher_id: 2109,
        name: 'Amendo',
        logo_image: 'https://s3-eu-west-1.amazonaws.com/sprancher-media/feedback/customer/Amendo_logotyp_svart.png',
        can_answer_comments: false,
      },
      query: {},
      tags: [
        'all_organisation_names',
        'City',
        'Department',
        'JobAdId',
        'Job ad title',
        'JobAdTitle',
        'Juridiskt bolag',
        'Kontor',
        'Kontorsnamn',
        'Kund',
        'Kundkontakt',
        'Leveransansvarig',
        'Location',
        'Organisationsenhet',
        'Org.träd nivå 2',
        'Org.träd nivå 3',
        'Personalansvarig',
        'Recruiter name',
        'Role',
        'Säljare KA',
        'Uppdragstyp',
        'Varumärke',
      ],
      permissions: {
        can_edit: true,
      },
      url: 'http://localhost:8000/feedback/api/segment/433/',
    },
  },
  customStringTranslations: {
    translations: {
      cnps: 'CNPS',
      cnps5: 'CNPS',
      rating: 'Rating',
      technicalErrors: 'Technical issues',
      step1: 'Application',
      step2: 'Interview',
      step3: 'Employment',
      step4: 'Onboarding',
      step5: 'Rejection',
      kundstep1: 'Customer specific',
      consultantstep1: 'Onboarding consultants',
      employeestep1: 'Employee',
      other: 'Other',
      table: 'Table',
      lineGraph: 'Line graph',
      barGraph: 'Bar graph',
      nej: 'No',
      ja: 'Yes',
    },
  },
  notifications: {
    notifications: [],
  },
  closeHandlers: {
    closeHandlers: [],
    closeHandlersInitiated: false,
  },
  customer: {
    customer: {
      138: {
        id: 138,
        sprancher_id: 2109,
        name: 'Amendo',
        logo_image: 'https://s3-eu-west-1.amazonaws.com/sprancher-media/feedback/customer/Amendo_logotyp_svart.png',
        plan: {
          start_date: '2021-09-13',
          end_date: null,
          plan_type: 'standard',
          time_remaining: null,
          used_free_trial: false,
        },
        step_display_names: {
          kundstep1: 'Kund vid tillsatt rekrytering',
          kundstep2: 'Kund vid avslutat konsultuppdrag',
          consultantstep1: 'Konsult efter avslutat uppdrag',
          step3: 'Slutkandidat efter tillsatt uppdrag',
          step4: 'Konsult 30 dagar in i uppdraget',
          step5: 'Rejection test custom display name',
        },
        all_steps: ['step1', 'step2', 'step5'],
        inactive_steps: ['step2'],
        industries: [{
          name: 'Consultancy',
          translations: [{
            language: 'sv-se',
            translation: 'Konsultverksamhet',
          }, {
            language: 'en-us',
            translation: 'Consultancy',
          }],
        }],
        sectors: ['recruitment'],
        countries: ['SE'],
        sizes: ['medium'],
        sizes_description: {
          medium: 'Employee count between 100 and 1000.',
        },
        used_features: {
          followup: false,
        },
      },
    },
    fetchingCustomer: false,
  },
  customerProxies: {
    customerProxies: {
      138: [
        {
          id: 144,
          sprancher_id: 2127,
          name: 'Amendo',
          customer: {
            all_steps: ['step1', 'step2', 'step5'],
            inactive_steps: ['step2'],
            id: 138,
            sprancher_id: 2109,
            name: 'Amendo',
            logo_image: 'https://s3-eu-west-1.amazonaws.com/sprancher-media/feedback/customer/Amendo_logotyp_svart.png',
            can_answer_comments: false,
          },
          custom_tags: {
            size: 'medium',
            country: 'SE',
            company_type: 'recruitment',
          },
        },
      ],
    },
    fetchingPromise: Promise.resolve(),
    fetchingProxies: false,
  },
  stepForms: {
    segmentForms: {
      433: Promise.resolve([{
        id: 509,
        customer_id: 138,
        customer_proxy_id: 144,
        customer_proxy: {
          id: 144,
          sprancher_id: 2127,
          name: 'Amendo',
          internal_name: 'Amendo',
          customer: {
            id: 138,
            sprancher_id: 2109,
            name: 'Amendo',
            logo_image: 'https://s3-eu-west-1.amazonaws.com/sprancher-media/feedback/customer/Amendo_logotyp_svart.png',
            step_display_names: {
              kundstep1: 'Kund vid tillsatt rekrytering',
              kundstep2: 'Kund vid avslutat konsultuppdrag',
              consultantstep1: 'Konsult efter avslutat uppdrag',
              step3: 'Slutkandidat efter tillsatt uppdrag',
              step4: 'Konsult 30 dagar in i uppdraget',
            },
            all_steps: [
              'step2',
              'consultantstep1',
              'step3',
              'step4',
              'step1',
              'kundstep1',
              'kundstep2',
              'step5',
            ],
            inactive_steps: ['step2'],
          },
          custom_tags: {
            size: 'medium',
            country: 'SE',
            company_type: 'recruitment',
          },
          country: 'SE',
          company_type: 'recruitment',
          size: 'medium',
          size_description: 'Employee count between 100 and 1000.',
          industries: [
            {
              name: 'Consultancy',
              translations: [
                {
                  language: 'en-us',
                  translation: 'Consultancy',
                },
                {
                  language: 'sv-se',
                  translation: 'Konsultverksamhet',
                },
              ],
            },
          ],
        },
        level: 'step2',
        step_display_name: null,
        form_prototype: {
          id: 211,
          options: {
            questions: [
              {
                form_prototype: 57,
                questions: [
                  {
                    form_prototype: 39,
                    questions: [
                      {
                        question: 405,
                      },
                      {
                        show: {
                          or: [
                            {
                              equals: {
                                value: '0',
                                question: 405,
                              },
                            },
                            {
                              equals: {
                                value: '1',
                                question: 405,
                              },
                            },
                            {
                              equals: {
                                value: '2',
                                question: 405,
                              },
                            },
                            {
                              equals: {
                                value: '3',
                                question: 405,
                              },
                            },
                            {
                              equals: {
                                value: '4',
                                question: 405,
                              },
                            },
                            {
                              equals: {
                                value: '5',
                                question: 405,
                              },
                            },
                            {
                              equals: {
                                value: '6',
                                question: 405,
                              },
                            },
                          ],
                        },
                        question: 455,
                        required: false,
                      },
                      {
                        show: {
                          or: [
                            {
                              equals: {
                                value: '7',
                                question: 405,
                              },
                            },
                            {
                              equals: {
                                value: '8',
                                question: 405,
                              },
                            },
                          ],
                        },
                        question: 456,
                        required: false,
                      },
                      {
                        show: {
                          or: [
                            {
                              equals: {
                                value: '9',
                                question: 405,
                              },
                            },
                            {
                              equals: {
                                value: '10',
                                question: 405,
                              },
                            },
                          ],
                        },
                        question: 457,
                        required: false,
                      },
                    ],
                  },
                  {
                    form_prototype: 67,
                    questions: [
                      {
                        question: 554,
                      },
                      {
                        show: {
                          equals: {
                            value: 'Annan jobbsajt',
                            question: 554,
                          },
                        },
                        question: 507,
                        required: false,
                      },
                      {
                        show: {
                          equals: {
                            value: 'Använde sökmotor',
                            question: 554,
                          },
                        },
                        question: 438,
                        required: false,
                      },
                      {
                        show: {
                          equals: {
                            value: 'Annat',
                            question: 554,
                          },
                        },
                        question: 491,
                        required: false,
                      },
                    ],
                  },
                  {
                    question: 398,
                  },
                  {
                    question: 399,
                  },
                  {
                    question: 400,
                  },
                  {
                    question: 401,
                  },
                  {
                    question: 402,
                  },
                  {
                    question: 403,
                    required: false,
                  },
                ],
              },
              {
                question: 960,
              },
              {
                show: {
                  equals: {
                    value: 'ja',
                    question: 960,
                  },
                },
                question: 961,
                required: false,
              },
            ],
            // include_base_values: [ // ! Removed 2023-08-31 bc. it's now in question.options.base_values instead
            //   {
            //     question: 554,
            //     base_values: [
            //       '{{customerName}}s hemsida',
            //       'Tipsad av en vän',
            //       'Blev kontaktad av {{customerName}}',
            //       'LinkedIn',
            //       'Monster',
            //       'Careerbuilder',
            //       'Indeed',
            //       'Arbetsförmedlingen',
            //       'Google',
            //       'Facebook',
            //       'Instagram',
            //       'Annat',
            //     ],
            //   },
            // ],
          },
        },
        questions: [
          {
            id: 405,
            question_type: 'rating',
            required: false,
            options: {
              cnps: true,
            },
            translation: {
              question: 'Based on your experience this far, how likely is it that you would recommend {{customerName}} to a friend or colleague?',
              language: 'en-us',
              options: {
                help_text: '0 = not likely, 10 = very likely',
              },
            },
          },
          {
            show: {
              or: [
                {
                  equals: {
                    value: '0',
                    question: 405,
                  },
                },
                {
                  equals: {
                    value: '1',
                    question: 405,
                  },
                },
                {
                  equals: {
                    value: '2',
                    question: 405,
                  },
                },
                {
                  equals: {
                    value: '3',
                    question: 405,
                  },
                },
                {
                  equals: {
                    value: '4',
                    question: 405,
                  },
                },
                {
                  equals: {
                    value: '5',
                    question: 405,
                  },
                },
                {
                  equals: {
                    value: '6',
                    question: 405,
                  },
                },
              ],
            },
            required: false,
            id: 455,
            question_type: 'text',
            options: {},
            translation: {
              question: 'What can we do to improve your recruiting experience',
              language: 'en-us',
              options: {
                placeholder: '',
              },
            },
          },
          {
            show: {
              or: [
                {
                  equals: {
                    value: '7',
                    question: 405,
                  },
                },
                {
                  equals: {
                    value: '8',
                    question: 405,
                  },
                },
              ],
            },
            required: false,
            id: 456,
            question_type: 'text',
            options: {},
            translation: {
              question: 'What would make you give us a top recommendation regarding the recruiting process?',
              language: 'en-us',
              options: {
                placeholder: '',
              },
            },
          },
          {
            show: {
              or: [
                {
                  equals: {
                    value: '9',
                    question: 405,
                  },
                },
                {
                  equals: {
                    value: '10',
                    question: 405,
                  },
                },
              ],
            },
            required: false,
            id: 457,
            question_type: 'text',
            options: {},
            translation: {
              question: 'What do you think has been your best experience with us regarding the recruiting process?',
              language: 'en-us',
              options: {
                placeholder: '',
              },
            },
          },
          {
            id: 554,
            question_type: 'list',
            required: false,
            options: {
              base_values: [
                '{{customerName}}s hemsida',
                'Tipsad av en vän',
                'Blev kontaktad av {{customerName}}',
                'LinkedIn',
                'Monster',
                'Careerbuilder',
                'Indeed',
                'Arbetsförmedlingen',
                'Google',
                'Facebook',
                'Instagram',
                'Annat',
              ],
              original_base_values: [
                '{{customerName}}s hemsida',
                'Tipsad av en vän',
                'Annan jobbsajt',
                'LokusJobb',
                'Facebook',
                'Jobbsafari',
                'LinkedIn',
                'Blocket jobb',
                'Arbetsförmedlingens hemsida',
                'Lokaltidning (webb/print)',
                'Blev kontaktad av {{customerName}}',
                'Använde sökmotor',
                'Instagram',
                'Indeed',
                'Mässa/mässor',
                'Nyhetsbrev/mejlutskick',
                'Google',
                'Arbetsförmedlingen',
                'Careereye',
                'Metrojobb',
                'Stepstone',
                'Careerbuilder',
                'Monster',
                'Glassdoor',
                'Genom rekryteringsföretag',
                'Twitter',
                'Annat',
                'FBIG',
              ],
            },
            translation: {
              question: 'Where did you first/initially find the job ad?',
              language: 'en-us',
              options: {
                values: {
                  FBIG: 'Facebook/Instagram',
                  Annat: 'Other',
                  Google: 'Google',
                  Indeed: 'Indeed',
                  Monster: 'Monster',
                  Twitter: 'Twitter',
                  Facebook: 'Facebook',
                  LinkedIn: 'LinkedIn',
                  Careereye: 'Careereye',
                  Glassdoor: 'Glassdoor',
                  Instagram: 'Instagram',
                  LokusJobb: 'LokusJobb',
                  Metrojobb: 'Metrojobb',
                  Stepstone: 'Stepstone',
                  Jobbsafari: 'Jobbsafari',
                  'Blocket jobb': 'Blocket jobb',
                  Careerbuilder: 'Careerbuilder',
                  'Annan jobbsajt': 'Other site',
                  'Mässa/mässor': 'Event/career fair',
                  'Tipsad av en vän': 'Tipped by a friend',
                  'Använde sökmotor': 'Search engine',
                  Arbetsförmedlingen: 'Arbetsförmedlingen/platsbanken',
                  'Nyhetsbrev/mejlutskick': 'Newsletter/mail',
                  'Lokaltidning (webb/print)': 'Newspaper',
                  '{{customerName}}s hemsida': '{{customerName}} career page',
                  'Genom rekryteringsföretag': 'Through a recruitment agency',
                  'Arbetsförmedlingens hemsida': 'Arbetsförmedlingens hemsida',
                  'Blev kontaktad av {{customerName}}': 'Contacted by {{customerName}}',
                },
              },
            },
          },
          {
            show: {
              equals: {
                value: 'Annan jobbsajt',
                question: 554,
              },
            },
            required: false,
            id: 507,
            question_type: 'text',
            options: {},
            translation: {
              question: 'Please tell us through which site for job ads',
              language: 'en-us',
              options: {
                placeholder: '',
              },
            },
          },
          {
            show: {
              equals: {
                value: 'Använde sökmotor',
                question: 554,
              },
            },
            required: false,
            id: 438,
            question_type: 'text',
            options: {},
            translation: {
              question: 'Write which search engine here',
              language: 'en-us',
              options: {
                placeholder: '',
              },
            },
          },
          {
            show: {
              equals: {
                value: 'Annat',
                question: 554,
              },
            },
            required: false,
            id: 491,
            question_type: 'text',
            options: {},
            translation: {
              question: 'Tell us where you found the ad',
              language: 'en-us',
              options: {
                placeholder: '',
              },
            },
          },
          {
            id: 398,
            question_type: 'rating',
            required: false,
            options: {
              cnps: false,
            },
            translation: {
              question: 'My interview was scheduled in a reasonable time frame',
              language: 'en-us',
              options: {
                help_text: '0 = I strongly disagree, 10 = I strongly agree',
              },
            },
          },
          {
            id: 399,
            question_type: 'rating',
            required: false,
            options: {
              cnps: false,
            },
            translation: {
              question: 'I had the opportunity to convey my personality and experiences during the interview',
              language: 'en-us',
              options: {
                help_text: '0 = I disagree strongly, 10 = I agree strongly',
              },
            },
          },
          {
            id: 400,
            question_type: 'rating',
            required: false,
            options: {
              cnps: false,
            },
            translation: {
              question: 'I was well treated by the interviewer',
              language: 'en-us',
              options: {
                help_text: '0 = I strongly disagree, 10 = I strongly agree',
              },
            },
          },
          {
            id: 401,
            question_type: 'rating',
            required: false,
            options: {
              cnps: false,
            },
            translation: {
              question: 'The people I met at {{customerName}} were well prepared in my background and experiences',
              language: 'en-us',
              options: {
                help_text: '0 = I strongly disagree, 10 = I strongly agree',
              },
            },
          },
          {
            id: 402,
            question_type: 'rating',
            required: false,
            options: {
              cnps: false,
            },
            translation: {
              question: 'The questions during the interview were relevant to the job',
              language: 'en-us',
              options: {
                help_text: '0 = I strongly disagree, 10 = I strongly agree',
              },
            },
          },
          {
            required: false,
            id: 403,
            question_type: 'text',
            options: {},
            translation: {
              question: 'What would make our hiring process better?',
              language: 'en-us',
              options: {
                placeholder: '',
              },
            },
          },
          {
            id: 960,
            question_type: 'yesno',
            required: false,
            options: {
              reverse_stats: false,
            },
            translation: {
              question: 'Have you had a digital interview?',
              language: 'en-us',
              options: {},
            },
          },
          {
            show: {
              equals: {
                value: 'ja',
                question: 960,
              },
            },
            required: false,
            id: 961,
            question_type: 'text',
            options: {},
            translation: {
              question: 'Describe how you experienced the digital interview',
              language: 'en-us',
              options: {
                placeholder: '',
              },
            },
          },
        ],
        test_url: '/shinyform/test/509/69d9bfb7b1f895bceb5d/',
        spam_filter_days: null,
      }]),
    },
    segmentFormsTiny: {
      433: Promise.resolve([{
        id: 780,
        customer_proxy_id: 144,
        customer_proxy_internal_name: 'Amendo',
        customer_proxy_name: 'Amendo',
        level: 'consultantstep1',
        active: true,
        test_url: '/shinyform/test/780/be5c0ed30ccac04458d3/',
      }, {
        id: 778,
        customer_proxy_id: 144,
        customer_proxy_internal_name: 'Amendo',
        customer_proxy_name: 'Amendo',
        level: 'kundstep1',
        active: true,
        test_url: '/shinyform/test/778/827e4be56367f60114f5/',
      }, {
        id: 782,
        customer_proxy_id: 144,
        customer_proxy_internal_name: 'Amendo',
        customer_proxy_name: 'Amendo',
        level: 'kundstep2',
        active: true,
        test_url: '/shinyform/test/782/4330ef244eb5b20448f5/',
      }, {
        id: 508,
        customer_proxy_id: 144,
        customer_proxy_internal_name: 'Amendo',
        customer_proxy_name: 'Amendo',
        level: 'step1',
        active: true,
        test_url: '/shinyform/test/508/29eb68589387978448dd/',
      }, {
        id: 509,
        customer_proxy_id: 144,
        customer_proxy_internal_name: 'Amendo',
        customer_proxy_name: 'Amendo',
        level: 'step2',
        active: false, // true live
        test_url: '/shinyform/test/509/69d9bfb7b1f895bceb5d/',
      }, {
        id: 779,
        customer_proxy_id: 144,
        customer_proxy_internal_name: 'Amendo',
        customer_proxy_name: 'Amendo',
        level: 'step3',
        active: true,
        test_url: '/shinyform/test/779/c735aefbe44746708658/',
      }, {
        id: 781,
        customer_proxy_id: 144,
        customer_proxy_internal_name: 'Amendo',
        customer_proxy_name: 'Amendo',
        level: 'step4',
        active: true,
        test_url: '/shinyform/test/781/7b62b5ffc59f72ad72c2/',
      }, {
        id: 510,
        customer_proxy_id: 144,
        customer_proxy_internal_name: 'Amendo',
        customer_proxy_name: 'Amendo',
        level: 'step5',
        active: true,
        test_url: '/shinyform/test/510/4fa0d1bef92277839828/',
      }]),
    },
  },
  questions: {
    questions: {
      224: {
        id: 224, question_type: 'yesno', required: undefined, options: { reverse_stats: true }, translation: { question: 'Opplevde du tekniske problemer da du søkte på stillingen?', language: 'nb-no', options: {} }, show: undefined, level: 'step1',
      },
      398: {
        id: 398, question_type: 'rating', required: undefined, options: { cnps: false }, translation: { question: 'Jeg fikk mitt intervju booket i rimelig tid', language: 'nb-no', options: { help_text: '0 = stemmer overhodet ikke, 10 = stemmer svært godt' } }, show: undefined, level: 'step2',
      },
      399: {
        id: 399, question_type: 'rating', required: undefined, options: { cnps: false }, translation: { question: 'Jeg fikk mulighet til å presentere et rettferdig bilde av meg selv under intervjuet', language: 'nb-no', options: { help_text: '0 = stemmer overhodet ikke, 10 = stemmer svært godt' } }, show: undefined, level: 'step2',
      },
      502: {
        id: 502, question_type: 'rating', required: undefined, options: { cnps: true }, translation: { question: 'Hvor sannsynlig er det at du ville anbefale {{customerName}} til en venn eller kollega?', language: 'nb-no', options: { help_text: '0 = overhodet ikke sannsynlig, 10 = svært sannsynlig' } }, show: undefined, level: 'step5',
      },
    },
  },
  filter: {
    segmentFilter: {
      date: {
        type: 'relative',
        offset: null,
        span: {
          months: 12,
        },
      },
      benchmark: {
        size: [],
        location: [],
        industry: [],
      },
    },
    fetchingFilters: false,
  },
  boards: {
    boards: {
      433: [
        {
          id: 1169,
          name: 'Ansökan',
          segment: 433,
          owner: {
            id: 202,
            first_name: 'Martin',
            last_name: 'Svensson',
            username: 'MartinSvensson',
            email: 'martin@trustcruit.com',
            url: 'http://localhost:8000/feedback/accounts/api/user/202/',
          },
          created: '2019-07-05T14:20:41.677859+02:00',
          metadata: {
            filter: {},
          },
          is_public: true,
          url: 'http://localhost:8000/databoard/api/boards/1169/',
          _promise: {},
          icon: '<i class="zmdi zmdi-key pr-2"></i>',
          label: 'Ansökan',
        },
        {
          id: 1172,
          name: 'Ansökan - Kommentarer',
          segment: 433,
          owner: {
            id: 202,
            first_name: 'Martin',
            last_name: 'Svensson',
            username: 'MartinSvensson',
            email: 'martin@trustcruit.com',
            url: 'http://localhost:8000/feedback/accounts/api/user/202/',
          },
          created: '2019-07-05T14:23:31.448989+02:00',
          metadata: {
            filter: {},
          },
          is_public: true,
          url: 'http://localhost:8000/databoard/api/boards/1172/',
          _promise: {},
          icon: '<i class="zmdi zmdi-key pr-2"></i>',
          label: 'Ansökan - Kommentarer',
        },
        {
          id: 7797,
          name: 'Ansökan - Total per fråga',
          segment: 433,
          owner: {
            id: 202,
            first_name: 'Martin',
            last_name: 'Svensson',
            username: 'MartinSvensson',
            email: 'martin@trustcruit.com',
            url: 'http://localhost:8000/feedback/accounts/api/user/202/',
          },
          created: '2020-08-18T09:05:26.678699+02:00',
          metadata: {
            filter: {
              date: {
                type: 'absolute',
                dateLte: '2020-06-30',
                dateGte: '2020-01-01',
              },
            },
          },
          is_public: true,
          url: 'http://localhost:8000/databoard/api/boards/7797/',
          _promise: {},
          icon: '<i class="zmdi zmdi-key pr-2"></i>',
          label: 'Ansökan - Total per fråga',
        },
        {
          id: 1171,
          name: 'Avslag',
          segment: 433,
          owner: {
            id: 202,
            first_name: 'Martin',
            last_name: 'Svensson',
            username: 'MartinSvensson',
            email: 'martin@trustcruit.com',
            url: 'http://localhost:8000/feedback/accounts/api/user/202/',
          },
          created: '2019-07-05T14:20:46.928967+02:00',
          metadata: {
            filter: {},
          },
          is_public: true,
          url: 'http://localhost:8000/databoard/api/boards/1171/',
          _promise: {},
          icon: '<i class="zmdi zmdi-key pr-2"></i>',
          label: 'Avslag',
        },
        {
          id: 1173,
          name: 'Avslag - Kommentarer',
          segment: 433,
          owner: {
            id: 202,
            first_name: 'Martin',
            last_name: 'Svensson',
            username: 'MartinSvensson',
            email: 'martin@trustcruit.com',
            url: 'http://localhost:8000/feedback/accounts/api/user/202/',
          },
          created: '2019-07-05T14:44:20.605547+02:00',
          metadata: {
            filter: {},
          },
          is_public: true,
          url: 'http://localhost:8000/databoard/api/boards/1173/',
          _promise: {},
          icon: '<i class="zmdi zmdi-key pr-2"></i>',
          label: 'Avslag - Kommentarer',
        },
        {
          id: 7796,
          name: 'Avslag - Total per fråga',
          segment: 433,
          owner: {
            id: 202,
            first_name: 'Martin',
            last_name: 'Svensson',
            username: 'MartinSvensson',
            email: 'martin@trustcruit.com',
            url: 'http://localhost:8000/feedback/accounts/api/user/202/',
          },
          created: '2020-08-18T09:05:19.536793+02:00',
          metadata: {
            filter: {},
          },
          is_public: true,
          url: 'http://localhost:8000/databoard/api/boards/7796/',
          _promise: {},
          icon: '<i class="zmdi zmdi-key pr-2"></i>',
          label: 'Avslag - Total per fråga',
        },
        {
          id: 1170,
          name: 'Intervju',
          segment: 433,
          owner: {
            id: 202,
            first_name: 'Martin',
            last_name: 'Svensson',
            username: 'MartinSvensson',
            email: 'martin@trustcruit.com',
            url: 'http://localhost:8000/feedback/accounts/api/user/202/',
          },
          created: '2019-07-05T14:20:44.223560+02:00',
          metadata: {
            filter: {},
          },
          is_public: true,
          url: 'http://localhost:8000/databoard/api/boards/1170/',
          _promise: {},
          icon: '<i class="zmdi zmdi-key pr-2"></i>',
          label: 'Intervju',
        },
        {
          id: 1175,
          name: 'Intervju - Kommentarer',
          segment: 433,
          owner: {
            id: 202,
            first_name: 'Martin',
            last_name: 'Svensson',
            username: 'MartinSvensson',
            email: 'martin@trustcruit.com',
            url: 'http://localhost:8000/feedback/accounts/api/user/202/',
          },
          created: '2019-07-05T14:47:53.500137+02:00',
          metadata: {
            filter: {},
          },
          is_public: true,
          url: 'http://localhost:8000/databoard/api/boards/1175/',
          _promise: {},
          icon: '<i class="zmdi zmdi-key pr-2"></i>',
          label: 'Intervju - Kommentarer',
        },
        {
          id: 7795,
          name: 'Intervju - Total per fråga',
          segment: 433,
          owner: {
            id: 202,
            first_name: 'Martin',
            last_name: 'Svensson',
            username: 'MartinSvensson',
            email: 'martin@trustcruit.com',
            url: 'http://localhost:8000/feedback/accounts/api/user/202/',
          },
          created: '2020-08-18T09:05:14.950518+02:00',
          metadata: {
            filter: {
              date: {
                type: 'absolute',
                dateLte: '2020-06-30',
                dateGte: '2020-01-01',
              },
            },
          },
          is_public: true,
          url: 'http://localhost:8000/databoard/api/boards/7795/',
          _promise: {},
          icon: '<i class="zmdi zmdi-key pr-2"></i>',
          label: 'Intervju - Total per fråga',
        },
        {
          id: 7398,
          name: 'Rapport',
          segment: 433,
          owner: {
            id: 622,
            first_name: '',
            last_name: '',
            username: 'Trustcruit',
            email: '',
            url: 'http://localhost:8000/feedback/accounts/api/user/622/',
          },
          created: '2020-06-05T14:08:40.737448+02:00',
          metadata: {
            filter: {},
          },
          is_public: true,
          url: 'http://localhost:8000/databoard/api/boards/7398/',
          _promise: {},
          icon: '<i class="zmdi zmdi-key pr-2"></i>',
          label: 'Rapport',
        },
        {
          id: 7799,
          name: 'Rapport - Totalt',
          segment: 433,
          owner: {
            id: 722,
            first_name: 'Urban',
            last_name: 'Stenberg',
            username: 'UrbanStenberg',
            email: 'urban.stenberg@amendo.se',
            url: 'http://localhost:8000/feedback/accounts/api/user/722/',
          },
          created: '2020-08-18T10:52:00.759512+02:00',
          metadata: {
            filter: {
              date: {
                type: 'absolute',
                dateLte: '2020-06-30',
                dateGte: '2020-01-01',
              },
            },
          },
          is_public: true,
          url: 'http://localhost:8000/databoard/api/boards/7799/',
          _promise: {},
          icon: '<i class="zmdi zmdi-key pr-2"></i>',
          label: 'Rapport - Totalt',
        },
        {
          id: 8556,
          name: 'Rapport - Övergripande',
          segment: 433,
          owner: {
            id: 622,
            first_name: '',
            last_name: '',
            username: 'Trustcruit',
            email: '',
            url: 'http://localhost:8000/feedback/accounts/api/user/622/',
          },
          created: '2020-10-13T13:53:20.123262+02:00',
          metadata: {
            filter: {},
          },
          is_public: true,
          url: 'http://localhost:8000/databoard/api/boards/8556/',
          _promise: {},
          icon: '<i class="zmdi zmdi-key pr-2"></i>',
          label: 'Rapport - Övergripande',
        },
        {
          id: 5956,
          name: 'demo',
          segment: 433,
          owner: {
            id: 1082,
            first_name: '',
            last_name: '',
            username: 'johan',
            email: 'johan@trustcruit.com',
            url: 'http://localhost:8000/feedback/accounts/api/user/1082/',
          },
          created: '2020-04-14T11:31:31.777170+02:00',
          metadata: {
            filter: {},
          },
          is_public: false,
          url: 'http://localhost:8000/databoard/api/boards/5956/',
          _promise: {},
          icon: '<i class="zmdi zmdi-key pr-2"></i>',
          label: '<i class="zmdi zmdi-key pr-2"></i>demo',
        },
      ],
    },
    fetchingBoards: false,
  },
  users: {
    customerUsers: {},
    segmentUsers: {},
    fetchingUsers: false,
  },
  tasks: {
    reportTasks: {},
    fetchingReportTasks: false,
    subscriptionTasks: {},
    fetchingSubscriptionTasks: false,
  },
  pings: {
    pings: [],
  },
  router: {
    hiddenParams: {},
  },
  tags: {
    segmentTags: {},
    customerTags: {},
    fetchingTags: {},
    segmentTagsLatestFetch: null,
    customerTagsLatestFetch: null,
  },
  languageSettings: {
    user: 1082,
    language: 'en-us',
    question_language: 'sv-se',
    browser_language: 'sv-se',
  },
  topics: {
    topics: [
      { id: 1, name: 'Job Ad', description: "Everything related to what's (not) written in the job ad." },
      { id: 2, name: 'Feedback & Communication', description: 'Everything related to feedback (not) given to the candidate and the communication between you and the candidate.' },
      { id: 3, name: 'Diversity & Inclusion', description: 'Everything related to diversity and inclusion matters.' },
      { id: 4, name: 'Application form & ATS', description: 'Everything related to your ATS and the application form.' },
      { id: 5, name: 'Other', description: 'Everything that is relevant, but does not fit in the other categories.' },
      { id: 6, name: 'Not relevant', description: 'Everything that is not relevant feedback to improve, like insults or positive comments.' },
      { id: 7, name: 'Undetermined', description: 'Everything that our AI was unable to determine the topic of.' },
    ],
    fetchingTopics: Promise.resolve(),
  },
};
