<template>
  <div
    v-if="!tinyFormLocal?.results?.length && loading || loading"
    v-tc-loader-spinner="loading"
    class="step-form-list-single on-hover on-hover-slide-right tc-loader-spinner-wrapper"
  >
    <BoardAreaSkeleton />
  </div>
  <div
    v-else-if="tinyFormLocal?.results?.length && !loading"
    v-tc-loader-spinner="loading"
    class="step-form-list-single on-hover on-hover-slide-right tc-loader-spinner-wrapper"
  >
    <div
      class="step-form-list-single-body"
      @mouseover="showCount = true"
      @mouseout="showCount = false"
    >
      <div class="step-form-list-single-header">
        <router-link
          class="block mb-0"
          tabindex="0"
          :to="{ name: 'form', params: { level: stepData.step}}"
        >
          <span v-tooltip.top-start="{ content: levelTooltip }">
            {{ translateTerm(stepData.step) }}
          </span>
          <i class="ml-1 on-hover-visible zmdi zmdi-arrow-right" />
        </router-link>
      </div>
      <VMenu
        v-if="isStepPaused"
        :distance="8"
        placement="top-start"
      >
        <span class="subtle-text">
          <i
            class="zmdi zmdi-pause-circle zmdi-hc-lg mr-1"
            :title="$gettext('Pausad')"
          />
          {{ $gettext('Pausad') }}
        </span>

        <template #popper>
          <p class="mb-1">
            {{ $pgettext(
              'Popper — Inactive step', 'Detta formulär är pausat, klicka för att be oss aktivera det i chatten'
            ) }}
          </p>
          <button
            class="btn btn-thin btn-block btn-activatewall medium-text mb-2"
            @click="reactivateStep(step)"
          >
            <i class="zmdi zmdi-play-circle-outline zmdi-hc-lg mr-1" />
            <span>{{ $pgettext('Button — Reactivate step', 'Återaktivera') }}</span>
          </button>
        </template>
      </VMenu>
      <div
        v-if="hasData"
        class="step-form-response-rates flex align-items-baseline justify-space-between"
      >
        <div>
          <p class="tc-color-blue-dark mb-0 flex gap-2 align-center">
            <span v-if="showCount">{{ submittedStats }}</span>
            <strong v-else>{{ roundNumber(stepData.stats.respond_perc * 100) }}% </strong>
            <span v-if="!showCount">({{ stepData.globalStats ? `${roundNumber(stepData.globalStats.respond_perc * 100)}%` : '–' }})</span>
          </p>
          <p
            v-if="showCount"
            class="subtle-text small-text mb-0"
          >
            {{ $gettext('Antal') }}
          </p>
          <p
            v-else
            class="subtle-text small-text mb-0"
          >
            {{ $gettext('Svarsfrekvens') }} {{ $gettext('(globalt)') }}
          </p>
        </div>
        <div>
          <p class="tc-color-blue-dark mb-0 flex gap-2 align-center">
            <strong>{{ roundNumber(stepData.stats.open_perc * 100) }}%</strong>
            <span v-tooltip="stepData.globalStats ? '' : notEnoughGlobalData">
              ({{ stepData.globalStats ? `${roundNumber(stepData.globalStats.open_perc * 100)}%` : '–' }})
            </span>
          </p>
          <p class="subtle-text small-text mb-0">
            {{ $gettext('Öppnat formuläret') }} {{ $gettext('(globalt)') }}
          </p>
        </div>
        <div>
          <p
            class="tc-color-blue-dark mb-0"
            v-text="stepData.stats.applicant_count ?? '–'"
          />
          <p
            class="subtle-text small-text mb-0"
            v-text="$gettext('Skickade formulär')"
          />
        </div>
      </div>
      <div v-else>
        <p class="mb-0 subtle-text small-text">
          {{ $gettext('Det finns inte tillräckligt med data för att visa svarsfrekvensen.') }}
        </p>
        <p class="subtle-text small-text">
          {{ $gettext('Testa att ändra datumfiltreringen eller vänta på att mer data samlas in.') }}
        </p>
      </div>

      <div class="mt-2">
        <small
          v-tooltip="surveyTooltip"
          class="subtle-text cursor-help"
        >
          {{ $gettext('Formulär') }} <i class="zmdi zmdi-info ml-1" />
        </small>
        <div
          class="step-form-select-wrapper"
        >
          <tc-select
            v-model="proxy"
            :on-change="onChange"
            :wrap-results="wrapResultsFn"
            :clean-paginator-fn="cleanPaginatorFn"
            :populate-store-fn="populateTiny"
            :placeholder="$gettext('Välj ett formulär att titta på.')"
          />
          <button
            v-if="!proxy?.length"
            class="btn"
            :disabled="!proxy?.length"
            butt
          >
            {{ $gettext('Visa formulär') }}
            <i class="ml-2 zmdi zmdi-open-in-new" />
          </button>
          <a
            v-else
            class="btn btn-primary"
            :href="`${BASE_URL}${proxy[0]?.test_url}` || ''"
            :title="proxy[0]?.label || ''"
            target="_blank"
            rel="noopener noreferrer"
          >
            {{ $gettext('Visa formulär') }}
            <i class="ml-2 zmdi zmdi-open-in-new" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isEmpty } from 'lodash-es';
import { mapGetters } from 'vuex';
import { BASE_URL } from 'Utils/urls';
import { translateTerm, roundNumber } from 'Utils/general';
// import useSurveyList from 'Composables/useSurveyList';
import BoardAreaSkeleton from 'Components/parts/board/BoardAreaSkeleton';

export default {
  components: {
    BoardAreaSkeleton,
  },
  props: {
    stepData: {
      type: Object,
      required: true,
    },
    gettingStats: {
      type: Boolean,
      default: false,
    },
    tinyForm: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      BASE_URL,
      showCount: false,
      proxy: [],
      tinyFormLocal: {},
    };
  },
  computed: {
    ...mapGetters([
      'segmentId',
      'segmentFilter',
      'customerId',
    ]),
    loading() {
      return this.gettingStats;
    },
    proxies() {
      if (this.stepData.step && this.tinyFormLocal?.results?.length) {
        return this.tinyFormLocal?.results?.map((form) => ({
          ...((!form.active && { icon: 'zmdi zmdi-pause-circle' }) ?? {}),
          value: form.customer_proxy_id,
          label: `${form.customer_proxy_name}${this.appendInternalName({ name: form.customer_proxy_name, internal_name: form.customer_proxy_internal_name })}`,
          test_url: form.test_url,
          ...((!form.active && { description: this.$pgettext(
            'Tooltip — Inactive stepform',
            '”%{survey}”-formuläret är pausat. ',
            { survey: this.appendInternalName({ name: form.customer_proxy_name, internal_name: form.customer_proxy_internal_name }) },
          ) })),
        })) ?? [];
      }
      return [];
    },
    notEnoughGlobalData() {
      return this.$pgettext('Tooltip - Not enough global data', 'Det finns inte tillräckligt med global data inom tidsperioden');
    },
    hasData() {
      return this.stepData.stats?.respond_perc != null;
    },
    isStepPaused() {
      return this.tinyForm?.results?.every((survey) => survey?.active === false);
    },
    submittedStats() {
      const { responded_count } = this.stepData.stats;
      if ((responded_count ?? null) === null) return '';

      return this.$gettextInterpolate(
        this.$pgettext('In showCount on Forms page', '%{stats} st'),
        { stats: responded_count },
      );
    },
    levelTooltip() {
      return this.$pgettext('Tooltip — Forms level', 'Visa alla frågor och svar från detta formulär');
    },
    surveyTooltip() {
      return this.$pgettext('Tooltip — Survey', 'Välj ett formulär att titta på.');
    },
  },
  watch: {
    gettingStats: {
      handler(newVal, oldVal) {
        if (newVal === false && oldVal === true) {
          this.tinyFormLocal = this.tinyForm || {};
        }
      },
      immediate: true,
    },
    proxies: {
      handler(newVal, oldVal) {
        if (newVal.length && isEmpty(oldVal)) this.proxy = [newVal?.[0]];
      },
      immediate: true,
    },
  },
  created() {
    this.tinyFormLocal = this.tinyForm || {};
  },
  methods: {
    isEmpty,
    translateTerm,
    roundNumber,
    reactivateStep(level) {
      const step = translateTerm(level);
      if (window?.Intercom) window.Intercom('showNewMessage', this.$pgettext('Intercom - reactivate step chat', 'Hej! Jag vill återaktivera formuläret %{step}. ', { step }));
    },
    onChange() {
      return Promise.resolve(this.tinyFormLocal);
    },
    appendInternalName(proxyObj) {
      if (proxyObj?.internal_name && proxyObj.name !== proxyObj.internal_name) return ` (${proxyObj.internal_name})`;
      return '';
    },
    cleanPaginatorFn(reqPag, populator) {
      return {
        ...reqPag._dataset,
        results: populator?.stack || [],
      };
    },
    wrapResultsFn(response) {
      if (response?.results?.length > 0) {
        return {
          ...response,
          results: response.results.map((form) => {
            if (form.value) return form;
            return ({
              ...((!form.active && { icon: 'zmdi zmdi-pause-circle' }) ?? {}),
              value: form.customer_proxy_id,
              label: `${form.customer_proxy_name}${this.appendInternalName({ name: form.customer_proxy_name, internal_name: form.customer_proxy_internal_name })}`,
              test_url: form.test_url,
              ...((!form.active && { description: this.$pgettext(
                'Tooltip — Inactive stepform',
                '”%{survey}”-formuläret är pausat. ',
                { survey: this.appendInternalName({ name: form.customer_proxy_name, internal_name: form.customer_proxy_internal_name }) },
              ) })),
            });
          }),
        };
      }
      return { next: null, prev: null, results: [], ...response };
    },
    populateTiny(paginator) {
      this.tinyFormLocal = paginator;
    },
  },
};
</script>
