<template>
  <div class="new-level-filter">
    <transition name="fade-in">
      <div
        v-if="isAdding"
        class="form-group form-group-highlight tc-pane-bleed mb-0"
      >
        <tabs-navigation
          v-model="activeTab"
          class="mb-4 tabs-navigation-sm relative"
          :tabs="tabs"
        />
        <label class="flex-apart">
          <span>{{ $pgettext('Label — Choose step', 'Formulär') }}</span>
          <i
            class="zmdi zmdi-close subtle-text cursor-pointer mr-1"
            :title="$gettext('Stäng')"
            @click.prevent="isAdding = false"
          />
        </label>
        <LevelFilter
          ref="LevelFilterEl"
          v-model="levelsToAdd"
          :multiselect="true"
          @update:model-value="update"
        />
        <button
          :disabled="!hasLevel || levelsToAdd.length < 1"
          class="btn btn-link small-text ml-2 mr-4 mt-4"
          type="button"
          @click.prevent="addLevel"
        >
          <i class="zmdi zmdi-plus mr-1" />
          <span>{{ $pgettext('Button — New level filter add','Lägg till') }}</span>
        </button>
      </div>
    </transition>
    <button
      v-if="!isAdding"
      class="btn btn-link small-text mr-4"
      type="button"
      @click.prevent="isAdding = !isAdding"
    >
      <i class="zmdi zmdi-plus mr-1" />
      <span>{{ $pgettext('Button — NewLevelFilter', 'Lägg till avgränsning på formulär') }}</span>
    </button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { translateTerm } from 'Utils/general';
import { FILTER_OPERATOR_EXCLUDE, FILTER_OPERATOR_INCLUDE } from 'Utils/filterBox';
import LevelFilter from 'Components/parts/filters/LevelFilter';
import TabsNavigation from 'Components/parts/widgets/TabsNavigation';

export default {
  components: {
    LevelFilter,
    TabsNavigation,
  },
  props: {
    filter: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['add-filter', 'update:filter'],
  data() {
    return {
      levelsToAdd: [],
      isAdding: false,
      activeTab: FILTER_OPERATOR_INCLUDE,
      tabs: [
        {
          value: FILTER_OPERATOR_INCLUDE,
          title: this.$gettext('Inkludera'),
        },
        {
          value: FILTER_OPERATOR_EXCLUDE,
          title: this.$gettext('Exkludera'),
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['segmentLevels']),
    hasLevel() {
      return this.levelsToAdd?.[0]?.value;
    },
  },
  watch: {
    filter: {
      deep: true,
      handler(newFilter) {
        if (newFilter.length) this.setup(newFilter);
      },
    },
  },
  methods: {
    addLevel() {
      this.$emit('add-filter', { levels: this.levelsToAdd.map((levelObject) => levelObject.value), operator: this.activeTab });
      this.isAdding = false;
      this.clear();
    },
    update(levels) {
      this.$emit('update:filter', levels);
    },
    clear() {
      this.levelsToAdd = [];
      this.$refs?.LevelFilterEl?.clear?.();
    },
    async setup(filter) {
      const lvls = await this.segmentLevels;
      this.levelsToAdd = lvls
        .filter((level) => filter.includes(level))
        .map((level) => ({ value: level, label: translateTerm(level) }));
    },
  },
};
</script>
