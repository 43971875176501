import Model from 'API/model';
// import cardsAPI from 'API/cards';
import {
  postUrl,
  patchUrl,
  deleteUrl,
  getBaseUrl,
  createClass,
} from 'Utils/api';
import { handleAuthError } from 'Utils/authHelpers';
import { striphtml } from 'Utils/general';
import { store } from '@/store';
// import gettext from '@/gettext';

// const { $gettext, $pgettext } = gettext;

export function stripBoardLabel(boardLabel) {
  return striphtml(boardLabel);
}

function retroactivelyAddedBoardMetadata(meta) {
  // ? If a board property isn't in customers board.metadata, it will be added retroactively
  if (!meta) return {};
  let r = { ...meta };
  if (r.filter?.benchmark) { // ! Retroactively moved 5 nov 2021
    r.benchmark = r.filter?.benchmark || {};
    delete r.filter.benchmark; // ? Deletes key after move
  }
  return r;
}

export const DEFAULT_BOARD_METADATA = {
  compare: null, // ? Empty object means compare: date
  benchmark: {},
  filter: {
    date: { type: 'relative', offset: null, span: { months: 12 } },
    tags: {},
    answers: {},
  },
};

class Board extends Model {
  constructor(data) {
    super(data);
    this.icon = '<i class="zmdi zmdi-key pr-2"></i>';
    this.sections = this.sections ?? [];
    this.metadata = { // ? Can’t use DEFAULT_BOARD_METADATA for some weird JS reason. But keep it updated!
      compare: null, // ? Empty object means compare: date
      benchmark: {},
      filter: {
        date: { type: 'relative', offset: null, span: { months: 12 } },
        tags: {},
        answers: {},
      },
      ...this.metadata,
      ...retroactivelyAddedBoardMetadata(this.metadata),
    };
  }
}

export const createBoard = createClass?.(Board);

function create({
  name,
  segment,
  isPublic,
  metadata = DEFAULT_BOARD_METADATA,
}) {
  const { compare, benchmark, filter } = metadata;
  return postUrl('/databoard/api/boards/', {
    name: stripBoardLabel(name),
    segment,
    is_public: isPublic,
    metadata: { compare, benchmark, filter },
  }).then(createBoard);
}

function list(segment = null, page = 1, pageSize = 50) {
  return getBaseUrl('/databoard/api/boards/').query({ segment, page, page_size: pageSize })
    .then((response) => response?.body || [], handleAuthError);
}

function get(id) {
  return getBaseUrl(`/databoard/api/boards/${id}/`)
    .then((response) => response.body, handleAuthError)
    .then(createBoard);
}

export function update(id, {
  name, segment, metadata, isPublic,
}) {
  return patchUrl(`/databoard/api/boards/${id}/`, {
    ...(name && { name: stripBoardLabel(name) }),
    ...(segment && { segment }),
    ...(metadata && { metadata }),
    ...(isPublic != null && { is_public: isPublic }),
  })
    .then(createBoard);
}

function updateMetadata(id, metadata) { // if you only want to update the metadata
  return patchUrl(`/databoard/api/boards/${id}/`, { metadata })
    .then(createBoard);
}

function remove(id) {
  return deleteUrl(`/databoard/api/boards/${id}/`);
}
function clone({ board, name, segment, is_public, is_overview }) {
  return postUrl(`/databoard/api/boards/${board}/copy/`, {
    name,
    segment,
    is_public,
    is_overview,
  });
}

const _api = {
  get,
  list,
  update,
  updateMetadata, // ? will we need to only patch the metadata.filter?
  remove,
  create,
  clone,
};

Board.prototype._api = _api;

export default _api;

/* eslint-disable no-nested-ternary */
export const sortBoardsFn = (a, b) => (
  a?.is_overview
    ? -1
    : b?.is_overview
      ? 1
      : 0);
/* eslint-enable no-nested-ternary */

export function newBoard(name, isPublic = true) {
  // when name is false or falsy => silent fail user canceled prompt
  if (!name) return Promise.resolve();
  return _api.create({ name: stripBoardLabel(name), segment: store.getters.segmentId, isPublic })
    .then((board) => {
      if (board !== undefined) store.dispatch('fetchBoards', { clearBoards: false });
      return board;
    });
}

export function newBoardForSegment(name, segmentId, isPublic = true) {
  // when name is false or falsy => silent fail user canceled prompt
  if (!name || !segmentId) return Promise.resolve();
  return _api.create({ name: stripBoardLabel(name), segment: segmentId, isPublic })
    .then((board) => {
      if (board !== undefined) store.dispatch('fetchAllBoardsBySegmentId', { segmentId, clearBoards: false });
      return board;
    });
}

// export async function cloneBoardForSegment(board, segmentId, segmentName, { isPublic, cards, boardName }) {
//   if (!board || !segmentId) return Promise.resolve();
//   let createdBoard = Promise.resolve();

//   let listedCards = JSON.parse(JSON.stringify(cards)) || [];

//   const clonedBoardName = boardName || board.name || '';

//   try {
//     // ? List cards if not included as argument
//     if (listedCards.length === 0) listedCards = await cardsAPI.list(board.id, 1);

//     // ? Create board
//     createdBoard = await _api.create({
//       name: (board.segment === segmentId && clonedBoardName === board.name)
//         ? `${stripBoardLabel(clonedBoardName, isPublic)} (${$gettext('kopia')})`
//         : stripBoardLabel(clonedBoardName, isPublic),
//       segment: segmentId,
//       isPublic,
//       metadata: board.metadata,
//     });
//     // ? Create cards
//     if (createdBoard !== undefined) {
//       await Promise.all(listedCards.map(
//         async (card, i) => cardsAPI.create({ ...card, order: i, board: createdBoard.id }),
//       ));
//     }
//   } catch (err) {
//     throw new Error($pgettext('Error — clone board submit', 'Nätverksproblem'));
//   }
//   return createdBoard;
// }
