<template>
  <div
    class="filter-dropdowns-columns"
    v-bind="$attrs"
  >
    <FilterDropdownsDate
      v-model:date-metadata="dateMetadata"
      class="filter-dropdowns-column date-column"
      :is-card="isCard"
      :is-temporary="isTemporary"
      :is-disabled="isDisabled"
    />
  </div>
</template>

<script setup>
import { ref, computed, watch, onActivated, onDeactivated } from 'vue';
import { useVModels } from '@vueuse/core';
import { isEqual, isEqualWith, isArray, isEmpty, isObject, debounce } from 'lodash-es';
import { klona } from 'klona';
import boardAPI, { DEFAULT_BOARD_METADATA } from 'API/boards';
import { DEFAULT_CARD_METADATA } from 'API/cards';
import { isEqualCustomizerChill } from 'Utils/filterBox';
import { store } from '@/store';
import FilterDropdownsDate from './FilterDropdownsDate';

const props = defineProps({
  metadata: {
    type: Object,
    default: () => ({}),
  },
  boardId: Number,
  cardId: Number,
  hasChanges: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:metadata', 'update:hasChanges']);
const modalCard = computed(() => store.getters.modalCard);

const isCard = computed(() => props.cardId > 0);
const isBoard = computed(() => props.boardId > 0);
const isTemporary = computed(() => !isBoard.value && !isCard.value);
const isModalCard = computed(() => modalCard.value?.metadata?.graphType !== undefined);

const isDisabled = computed(() => {
  if (isTemporary.value) return false;
  return !store.getters.isEditMode;
});

const question = ref(null);
const getQuestion = async (questionId) => store.dispatch('getQuestionById', questionId);
if (isModalCard.value) question.value = getQuestion(store.getters.modalCard?.metadata?.question);
watch(
  () => store.getters.modalCard?.metadata?.question,
  async (newVal) => { question.value = await getQuestion(newVal); },
  { immediate: true },
);

let originalMetadata = null; // used for resetting changes
const tempMetadata = ref(null);
const storeMetadata = computed(() => {
  if (isBoard.value) return store.getters.getBoardById(props.boardId)?.metadata ?? DEFAULT_BOARD_METADATA;
  if (isCard.value) return store.getters.modalCard?.metadata ?? DEFAULT_CARD_METADATA;
  return tempMetadata.value ?? {}; // DEFAULT_BOARD_METADATA?
});

/** removeEmptyArrays — Mutating passed object */
const removeEmptyArrays = (obj) => {
  if (!isObject(obj)) return obj;
  Object.keys(obj).forEach((key) => {
    if (isObject(obj[key])) removeEmptyArrays(obj[key]);
    if (isArray(obj[key]) && isEmpty(obj[key])) delete obj[key];
  });
  return obj;
};

/** cleanChanges — Not mutating passed object */
function cleanChanges(metadata) {
  const modifiedMetadata = klona(metadata);
  removeEmptyArrays(modifiedMetadata.filter ?? {});
  removeEmptyArrays(modifiedMetadata.benchmark ?? {});
  return modifiedMetadata;
}

function getOnlyFilter(filter) {
  if (filter == null) return {};
  const { date, ...rest } = filter; // perhaps `level` as well?
  return rest;
}

const updateCardStoreMetadata = (payload = {}) => {
  store.dispatch('setModalCardPath', ({
    path: 'metadata',
    value: {
      ...storeMetadata.value,
      ...payload,
    },
  }));
};
const updateBoardStoreMetadata = (payload = {}) => {
  const board = klona(store.getters.getBoardById(props.boardId));
  const metadata = payload;
  store.dispatch('setBoard', ({ board: { ...board, metadata } }));
};
const debouncedUpdateCardStoreMetadata = debounce(updateCardStoreMetadata, 50);
const debouncedUpdateBoardStoreMetadata = debounce(updateBoardStoreMetadata, 50);
const debounceSetMetadata = (data) => {
  if (isCard.value) debouncedUpdateCardStoreMetadata({ ...storeMetadata.value, ...data });
  else debouncedUpdateBoardStoreMetadata({ ...storeMetadata.value, ...data });
};

const dateMetadata = isTemporary.value ? ref({}) : computed({
  get: isCard.value
    ? () => (storeMetadata.value?.filter?.date ?? {})
    : () => (storeMetadata.value?.filter?.date ?? DEFAULT_BOARD_METADATA.filter.date),
  set: (newVal) => (newVal
    ? debounceSetMetadata({ filter: { ...storeMetadata.value?.filter, date: newVal } })
    : null),
});

const combinedMetadata = computed(() => ({
  filter: {
    date: dateMetadata.value,
  },
}));

const shouldSetOriginalMetadata = ref(true);
const isMounting = ref(true);
const { hasChanges } = useVModels(props, emit);
const localMetadata = computed(() => {
  if (isTemporary.value) return combinedMetadata.value;
  if (isCard.value) return props.metadata; // Should be same as `store.getters.modalCard.metadata`
  return storeMetadata.value;
});

onActivated(async () => {
  originalMetadata = null;
  tempMetadata.value = null;
  shouldSetOriginalMetadata.value = true;
  isMounting.value = true;
});
onDeactivated(() => { hasChanges.value = false; });
watch(
  [
    localMetadata,
    () => props.boardId,
    () => store.getters.segmentId,
  ],
  ([newMeta, newBoardId, newSegmentId], [_, oldBoardId, oldSegmentId]) => {
    const newMetaVal = klona(newMeta);
    if (newBoardId && newBoardId !== oldBoardId) {
      shouldSetOriginalMetadata.value = true;
    }
    if (shouldSetOriginalMetadata.value && !isEmpty(newMetaVal)) {
      originalMetadata = cleanChanges(Object.freeze(newMetaVal));
      tempMetadata.value = originalMetadata ?? {};
      shouldSetOriginalMetadata.value = false;
    }

    if (!isTemporary.value && !isMounting.value) {
      const changingSegment = newSegmentId !== oldSegmentId;
      if (
        !changingSegment
          && !isEqualWith(newMetaVal, originalMetadata, isEqualCustomizerChill)
      ) {
        hasChanges.value = true;
      } else {
        hasChanges.value = false;
      }
    }
    if (isMounting.value && !isEmpty(newMetaVal)) isMounting.value = false;
  },
  { immediate: false, deep: true },
);

if (isTemporary.value) {
  watch(
    () => combinedMetadata.value,
    (newVal, oldVal) => {
      if (!isEqual(newVal, oldVal)) {
        tempMetadata.value = { ...tempMetadata.value, ...newVal };
        emit('update:metadata', tempMetadata.value);
      }
    },
    { deep: true },
  );
}

async function saveCardChanges(cardId) {
  // Save all changes made to provided cardId
  console.error('saveCardChanges() is not used yet. Needs implementation if you want to use it', cardId); // eslint-disable-line no-console
  // const card = await cardAPI.updateMetadata(cardId, storeMetadata.value);
  // originalMetadata = Object.freeze(klona(card.metadata));
}

async function saveBoardChanges(boardId) {
  const metadata = cleanChanges(storeMetadata.value);
  try {
    store.dispatch('setFilterFetchingState', true);
    hasChanges.value = false;
    const board = await boardAPI.updateMetadata(boardId, metadata);
    store.dispatch('setBoard', ({ board }));
    originalMetadata = Object.freeze(klona(board.metadata));
    store.dispatch('setFilterFetchingState', false);
    return Promise.resolve(board);
  } catch (error) {
    hasChanges.value = true;
    store.dispatch('setFilterFetchingState', false);
    return Promise.reject(error);
  }
}

function saveFilterChanges() {
  if (isCard.value) saveCardChanges(props.cardId);
  else if (isBoard.value) saveBoardChanges(props.boardId);
  else throw new Error('Can’t save without boardId or cardId');
}
function resetFilterChanges() {
  if (isCard.value) updateCardStoreMetadata(originalMetadata);
  if (isBoard.value) updateBoardStoreMetadata(originalMetadata);
  else tempMetadata.value = originalMetadata;
  this.$refs?.FilterDropdownsFilterEl?.clearBadgeRefs?.();
}

defineExpose({
  saveFilterChanges,
  resetFilterChanges,
});
</script>
