<template>
  <div
    class="tc-table-wrapper tc-table-wrapper-border tc-table-wrapper--grid"
    :class="{ 'tc-table-responsive': isResponsive }"
  >
    <table
      :aria-label="ariaLabel ?? null"
      role="table"
      class="tc-table tc-table-hover tc-table-airy"
      :style="{ '--tc-table-column-count': headers.length - 1 }"
    >
      <TcTableHeader
        :headers="headers"
        :rows="rows"
        :sort-config="sortConfig"
        @update:sort="sortConfig = $event"
      />
      <tbody class="tc-table-body">
        <template v-if="loading">
          <tr
            v-for="trIndex in headers.length"
            :key="trIndex"
            class="tc-table-row tc-table-loading-row loading"
          >
            <td
              v-for="(tdIndex, zeroIndex) in headers.length"
              :key="tdIndex"
              v-tc-stick-to.invert="trIndex === 1 && tdIndex === 1 ? '.tc-table' : null"
            >
              <span class="tc-table-cell-label">{{ headers?.[zeroIndex].label }}</span>
              <div
                v-if="tdIndex === 2"
                class="tc-table-cell-value tc-loading-badge"
              >
                <span class="badge no-user-select">Loading</span>
              </div>
              <div
                v-else-if="tdIndex === 7"
                class="tc-table-cell-value loading-item"
              >
                <div class="btn-group">
                  <button class="btn btn-secondary">
                    <i class="zmdi zmdi-open-in-new" />
                    <span class="ml-1">
                      {{ $gettext('Förhandsvisa') }}
                    </span>
                  </button>
                  <button class="btn btn-secondary btn-icon">
                    <i-material-symbols-bar-chart-rounded />
                  </button>
                </div>
              </div>
              <div
                v-else
                class="tc-table-cell-value tc-loading-text tc-loading-text-inherit tc-loading-text-on-dark"
                :class="tdIndex === 1 ? 'size-fill' : 'size-xsmall'"
              />
            </td>
          </tr>
        </template>
        <template v-else-if="!rows.length">
          <tr class="tc-table-empty-text">
            <td>
              <span>{{ $gettext('Inga resultat') }}</span>
            </td>
          </tr>
        </template>
        <template v-else>
          <template
            v-for="row in sortedRows"
            :key="row.slug"
          >
            <TcTableRow
              :row="row"
              :rows="sortedRows"
              :headers="headers"
              :is-row-toggled="rowsToggled.includes(row.slug)"
              :toggle-row="toggleRow"
            >
              <template
                v-for="(_, colIndex) in headers"
                #[`column-${colIndex}`]="slotProps"
              >
                <slot
                  :name="'column-' + colIndex"
                  :value="slotProps.value"
                  :row="row"
                >
                  {{ slotProps.value ?? '-' }}
                </slot>
              </template>
            </TcTableRow>
            <TcTableSubRows
              v-if="row?.children.paginator?.stack?.length"
              :row="row"
              :is-row-toggled="rowsToggled.includes(row.slug)"
              :headers="headers"
            >
              <template
                v-for="(header, subColIndex) in headers"
                #[`column-sub-${subColIndex}`]="slotProps"
              >
                <slot
                  :name="'column-sub-' + subColIndex"
                  :value="slotProps.value"
                >
                  <span :key="header">{{ slotProps.value ?? '-' }}</span>
                </slot>
              </template>
            </TcTableSubRows>
          </template>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { sortBy } from 'lodash-es';
import TcTableSubRows from 'Components/parts/table/TcTableSubRows';
import TcTableRow from 'Components/parts/table/TcTableRow';
import TcTableHeader from 'Components/parts/table/TcTableHeader';

/*
    if you want to format a column differently, you can use slots e.g.

    <TcTable :headers="headers" :rows="rows"">
        <template #column-0="{ value }"> <-- Slot usage for the first column
            <strong>{{ value }}</strong>  <---- Bold formatting for first column
        </template>
        <template #column-2="{ value }"> <-- Slot usage for the third column
            <span>{{ formatText(value) }}</span> <-- Run your helperFn to format the text
        </template>
    </TcTable>
*/

const props = defineProps({
  ariaLabel: {
    type: String,
    default: null,
  },
  headers: {
    type: Array,
    required: true,
  },
  rows: {
    type: Array,
    required: true,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  isResponsive: {
    type: Boolean,
    default: false,
  },
});

const sortConfig = ref({
  column: null, // Index of the column to sort
  order: 'asc', // 'asc' for ascending, 'desc' for descending
});

const sortedRows = computed(() => {
  const { column, order } = sortConfig.value;

  if (column === null) return props.rows;

  const sortFn = props.headers[column]?.sortFn;
  let sorted = props.rows;

  if (typeof sortFn === 'function') sorted = sortFn(props.rows, order);
  else sorted = sortBy(props.rows, (row) => row.data[column]);

  return order === 'asc' ? sorted : sorted.reverse();
});

const rowsToggled = ref([]);

const toggleRow = (row) => {
  if (rowsToggled.value.includes(row)) {
    rowsToggled.value = rowsToggled.value.filter((r) => r !== row);
  } else {
    rowsToggled.value = [...rowsToggled.value, row];
  }
};
</script>
