<template>
  <div class="board-filters filter-box">
    <StrokedArea
      :title="$pgettext('Title — Resizing', 'Du har osparade ändringar')"
      :active="!loading && !isOrganizing && hasChanges"
      theme="info"
      class="stroked-area-fixed"
      :button="[
        {
          text: $pgettext('Button — Save', 'Spara'),
          tooltip: isSharedReport
            ? $pgettext('Tooltip — Save Shared', 'Spara den delade rapporten')
            : $pgettext('Tooltip — Save Private', 'Spara den privata rapporten'),
          action: saveFilterChanges
        },
        {
          text: $pgettext('Button — Reset', 'Återställ'),
          tooltip: $pgettext('Tooltip — Reset', 'Återställ filter till den senast sparade versionen'),
          action: resetFilterChanges
        }
      ]"
    />

    <div class="badge-list-title hide-screen show-print">
      <i class="zmdi mr-2 zmdi-filter-list" />
      <h5
        class="inline-block"
        v-text="$pgettext('Title — Board filters print','Rapporten är filtrerad på')"
      />
    </div>
    <FilterDropdownsSkeleton v-if="loading" />
    <KeepAlive v-else>
      <FilterDropdowns
        :key="boardId"
        ref="FilterDropdownsEl"
        v-model:has-changes="hasChanges"
        :metadata="contextMetadata"
        :board-id="boardId"
        @update:metadata="$emit('update:metadata', $event)"
      />
    </KeepAlive>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import StrokedArea from 'Components/parts/widgets/StrokedArea';
import FilterDropdowns from 'Components/parts/filter-dropdowns/FilterDropdowns';
import FilterDropdownsSkeleton from 'Components/parts/filter-dropdowns/FilterDropdownsSkeleton';

export default {
  name: 'BoardFilters',
  components: {
    StrokedArea,
    FilterDropdowns,
    FilterDropdownsSkeleton,
  },
  props: {
    loading: Boolean,
    contextMetadata: {
      type: Object,
      default: () => ({}),
    },
    boardId: Number,
  },
  emits: ['update:metadata'],
  data() {
    return {
      hasChanges: false,
    };
  },
  computed: {
    ...mapState({
      isOrganizing: (state) => state.boards.isOrganizing,
    }),
    ...mapGetters([
      'fetchingProxies',
      'customerProxies',
      'customerSegments',
      'topics',
      'getBoardById',
    ]),
    isSharedReport() {
      return this.getBoardById(this.boardId)?.is_public ?? true;
    },
  },
  watch: {
    hasChanges: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) this.setUnsavedChanges(newVal);
      },
    },
    // boardId: {
    //   handler(newVal, oldVal) {
    //     if (newVal !== oldVal) this.setUnsavedChanges(false);
    //   },
    // },
  },
  mounted() {
    this.fetchAllBenchmark('industries').catch((err) => {
      console.error('[TC]', err); // eslint-disable-line no-console
    });
  },
  methods: {
    ...mapActions([
      'fetchAllBenchmark',
      'getQuestionById',
      'setUnsavedChanges',
    ]),
    saveFilterChanges() {
      this.$refs?.FilterDropdownsEl?.saveFilterChanges?.();
    },
    resetFilterChanges() {
      this.$refs?.FilterDropdownsEl?.resetFilterChanges?.();
    },
  },
};
</script>
