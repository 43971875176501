import { isArray, isNaN } from 'lodash-es';
import { endOfDay, endOfMonth, endOfYear } from 'date-fns';
import { isTopicable } from 'API/topics';
import { prefixNPSValue, roundNumber } from 'Utils/general';
import { adjustForUTCOffset } from 'Utils/dateHelpers';
import { getPureFilterKey } from 'Utils/filterBox';
import gettext from '@/gettext';
import { getStepNPSContext, getStepNPSName } from './constants';
import IconCalendarClock from '~icons/material-symbols/calendar-clock-outline-rounded.svg';
import IconNewspaper from '~icons/material-symbols/newspaper-rounded.svg';
import IconHandshake from '~icons/material-symbols/handshake-outline-rounded.svg';
import IconGroups from '~icons/material-symbols/groups-outline-rounded.svg';
import IconChatError from '~icons/material-symbols/chat-error-outline-rounded.svg';
import IconPersonSearch from '~icons/material-symbols/person-search-outline-rounded.svg';
import IconFactCheck from '~icons/material-symbols/fact-check-outline-rounded.svg';
import IconDoorOpen from '~icons/material-symbols/door-open-outline-rounded.svg';

const { $gettext, $pgettext } = gettext;

/**
 * labeToDate
 * Turns label (2018, 2017-12) to array with start and end date
 */
function labelToDateRange(label) {
  if (!label) {
    return false;
  }
  const matches = label.match(/^([0-9]{4})-?([0-9]{2})?-?([0-9]{2})?/);
  if (matches === null) {
    return false;
  }
  let startDate = adjustForUTCOffset(new Date(label));

  // day
  if (matches[3] !== undefined) {
    return [startDate, new Date(endOfDay(startDate))];
  }
  // month
  if (matches[2] !== undefined) {
    return [startDate, new Date(endOfMonth(startDate))];
  }
  // year
  if (matches[1] !== undefined) {
    return [startDate, new Date(endOfYear(startDate))];
  }

  return false;
}

/**
 * Sum of object values
 * Takes object with numeric values to calc sum of
 * also takes filter function to check before adding sum.
 *
 * @param object (Object) To calc sum of
 * @param filter (Function) To test current iteration with
 * @return sum (Number)
 */
function getSumWithFilter(object, filter = null) {
  return Object.entries(object).reduce((sum, [key, val]) => {
    if (typeof val === 'number') {
      if (typeof filter === 'function') {
        if (filter(key, val)) {
          sum += val;
          return sum;
        }
      } else {
        sum += val;
        return sum;
      }
    }
    return sum;
  }, 0);
}

/**
 * Sum of object values
 * Takes object with numeric values to calc sum of
 *
 * @param object (Object) To calc sum of
 * @return sum (Number)
 */
function getSum(object) { // ? Focus on performance here
  let sum = 0;
  const keys = Object.keys(object);
  for (let i = 0; i < keys.length; i++) {
    if (typeof object[keys[i]] === 'number') sum += object[keys[i]];
  }
  return sum;
}

/**
 * Sum of object keys
 * Takes object with numeric values to calc sum of keys (key * value)
 * also takes filter function to check before adding sum.
 *
 * @param object (Object) To calc sum of
 * @param filter (Function) To test current iteration with
 * @return sum (Number)
 */
function getSumKeys(object, filter = null) {
  let sum = 0;
  Object.keys(object).forEach((key) => {
    key = parseInt(key, 10);
    if (isNaN(key)) {
      return;
    }
    let val = object[key];
    if (typeof val === 'number') {
      if (typeof filter === 'function') {
        if (filter(key, val)) {
          sum += key * val;
        }
      } else {
        sum += key * val;
      }
    }
  });
  return sum;
}

/**
 * Get percentage of key in stats object
 * as decimal value with alot of decimals
 *
 * @param key (String) Value to get percentage of
 * @param object (Object) To get total of
 */
function getKeyPercentage(key, object) {
  let total = getSum(object);
  return (object[key] || 0) / total;
}

/**
 * Get percentagees of all two decimals rounded
 *
 * @param object (Object) To get percentages copy of
 */
function getPercentages(object) {
  let percentages = { ...object };
  Object.keys(percentages).forEach((key) => {
    const value = getKeyPercentage(key, object);
    const displayValue = `${roundNumber(value * 100, 1)}%`;
    percentages[key] = {
      value,
      displayValue,
    };
  });
  return percentages;
}

function round(number, ndigits, base = 10) {
  return Math.round(number * (base ** ndigits)) / (base ** ndigits);
}

/**
 * Get average value
 *
 * @param answers (Object) Stats object with answers
 * @return (Number) The average value
 */
function getAverage(answers) {
  let count = getSum(answers);
  let total = getSumKeys(answers);
  return total ? round(total / count, 2) : null;
}

/**
 * Get CNPS value
 * Take stats object with answers from 1-10
 * and turns to CNPS value.
 *
 * @param answers (Object) Stats object with answers
 * @return (Number) The CNPS value
 */
function getCNPS(answers) {
  let lo = getSumWithFilter(answers, (key) => key < 7);
  let hi = getSumWithFilter(answers, (key) => key > 8);
  let total = getSum(answers);
  return total ? roundNumber(((hi / total) - (lo / total)) * 100) : null;
}

function getCNPSDisplayValue(value) {
  if (value === null) return null;
  return prefixNPSValue(value);
}

function getCNPSObject(answers) {
  let value = getCNPS(answers);
  let displayValue = getCNPSDisplayValue(value);
  return {
    value,
    displayValue,
  };
}

/**
 ** graphTypeElement - get the element matching the listed components
 * @param {object} GRAPH_TYPE_COMPONENTS, list of imported comps to choose from
 * @param {string} graphType, the chosen comp to use
 * @returns imported vue component
 */
function graphTypeElement(GRAPH_TYPE_COMPONENTS = {}, graphType = '') {
  const elem = GRAPH_TYPE_COMPONENTS[graphType];
  // eslint-disable-next-line no-console
  if (elem === undefined) console.error(`[TC] graphType ${graphType} is an invalid graphType`);
  return elem;
}

/** @enum
 * @property {string} T3LineChart
 * @property {string} T3BarChart
 * @property {string} T3FunnelChart
 * @property {string} T3MiniValue
 * @property {string} T3PieChart
*/
export const T3_GRAPH_TYPES = {
  T3BarChart: 'T3BarChart',
  T3LineChart: 'T3LineChart',
  T3FunnelChart: 'T3FunnelChart',
  T3MiniValue: 'T3MiniValue',
  T3PieChart: 'T3PieChart',
  T3PillChart: 'T3PillChart',
};

export const JBL_METRICS = [
  {
    value: 'job-ad-views',
    name: 'Job ad views & applications',
    description: 'Show job ad views and applications',
  },
  {
    value: 'candidates-over-time',
    name: 'Candidates over time',
    description: 'Visualize trends over time',
  },
  {
    value: 'time-to-fill',
    name: 'Time to fill',
    description: 'Show time to fill',
  },
  {
    value: 'time-in-workflow',
    name: 'Time in workflow',
    description: 'Show time in workflow',
  },
  {
    value: 'pass-through-rate',
    name: 'Pass through rate',
    description: 'Show pass through rate',
  },
  {
    value: 'top-sources',
    name: 'Top sources',
    description: 'Show top sources',
  },
  {
    value: 'events-conducted',
    name: 'Events conducted',
    description: 'Show events conducted',
  },
  {
    value: 'lost-reasons',
    name: 'Lost reasons',
    description: 'Show lost reasons',
  },
  {
    value: 'devices',
    name: 'Devices',
    description: 'Show devices',
  },
  {
    value: 'dropoff-rates',
    name: 'Dropoff rates',
    description: 'Show dropoff rates',
  },
  {
    value: 'published-jobs',
    name: 'Published jobs',
    description: 'Show published jobs',
  },
  {
    value: 'total-candidates',
    name: 'Total candidates',
    description: 'Show total candidates',
  },
  {
    value: 'open-jobs',
    name: 'Open jobs',
    description: 'Show open jobs',
  },
  {
    value: 'total-hires',
    name: 'Total hires',
    description: 'Show total hires',
  },
  {
    value: 'rejection-communicated',
    name: 'Rejection communicated',
    description: 'Show rejection communicated',
  },
  {
    value: 'time-to-hire',
    name: 'Time to hire',
    description: 'Show time to hire',
  },
  {
    value: 'candidates-per-job',
    name: 'Candidates per job',
    description: 'Show candidates per job',
  },
  {
    value: 'application-completion',
    name: 'Application completion',
    description: 'Show application completion',
  },
];

/** @enum
 * @property {string} queryTable
 * @property {string} lineGraph
 * @property {string} barGraph
 * @property {string} singleValue
 * @property {string} freeText
*/
export const GRAPH_TYPES = {
  queryTable: 'QueryTable',
  lineGraph: 'LineGraph',
  barGraph: 'BarGraph',
  singleValue: 'SingleValue',
  freeText: 'FreeText',
  donutGraph: 'DonutGraph',
};
/** @enum
 * @property {string} SWRanking
 * @property {string} PerformanceScore
 * @property {string} RankingGauge
 * @property {string} ResponseRate
*/
export const KEY_METRIC_GRAPH_TYPES = {
  SWRanking: 'SWRanking',
  PerformanceScore: 'PerformanceScore',
  RankingGauge: 'RankingGauge',
  ResponseRate: 'ResponseRate',
};
/** @enum
 * @property {string} GoalBar
*/
export const GOAL_GRAPH_TYPES = {
  GoalBar: 'GoalBar',
};
/** @enum
 * @property {string} MarkdownNote
*/
export const NOTE_GRAPH_TYPES = {
  MarkdownNote: 'MarkdownNote',
};

/**
 * @param {object} specificSettings, sets what settable is sent in
 * @returns all settable settings
 */
export const GRAPH_TYPES_SETTINGS = ({ type, ...args } = {}) => ({
  // ? Goal cards
  GoalBar: {},

  // ? Key Metric cards
  SWRanking: {
    // level: level || 'all_steps',
  },
  PerformanceScore: {},
  RankingGauge: {},
  ResponseRate: {
    type: type ?? 'radialBar', // radialBar / line / bar
  },

  // ? Question cards
  QueryTable: {
    sort: {},
  },
  LineGraph: {},
  BarGraph: {},
  SingleValue: {},
  FreeText: {},
});
export const allFilters = ['date', 'answers', 'tags', 'customerProxies', 'segment']; // 'topics'
export const allBenchmarks = ['sector', 'size', 'industry', 'location'];

// ? Used to filter out filterbadges that can’t be applied to certain Key Metric cards
export const KEY_METRIC_ALLOWLIST_BADGES = {
  [KEY_METRIC_GRAPH_TYPES.SWRanking]: ['step', ...allFilters, ...allBenchmarks, 'general'],
  [KEY_METRIC_GRAPH_TYPES.PerformanceScore]: ['date', 'segment', 'step', ...allBenchmarks, 'general'],
  [KEY_METRIC_GRAPH_TYPES.RankingGauge]: ['date', 'step', ...allBenchmarks, 'general'],
  [KEY_METRIC_GRAPH_TYPES.ResponseRate]: ['date', 'tags', 'customerProxies', 'segment', 'step', ...allBenchmarks, 'general'],
};

// ? Used to filter out steps/levels that can’t be applied to certain Key Metric cards
export const KEY_METRIC_ALLOWLIST_LEVELS = {
  [KEY_METRIC_GRAPH_TYPES.SWRanking]: { any_step: true },
  [KEY_METRIC_GRAPH_TYPES.PerformanceScore]: { any_step: true },
  [KEY_METRIC_GRAPH_TYPES.RankingGauge]: { any_step: false, specific: ['step1', 'step2', 'step5'], stepRequired: true },
  [KEY_METRIC_GRAPH_TYPES.ResponseRate]: { any_step: true, stepRequired: true },
};

// ? Used to filter out options that can’t be applied to certain Key Metric cards
// ? Can be any of ['inherit', 'global', 'company', 'custom', 'hide'] and/or with an added `-readonly` suffix to indicate that the option is read-only
export const KEY_METRIC_ALLOWLIST_BENCHMARKABLES = {
  [KEY_METRIC_GRAPH_TYPES.SWRanking]: ['global', 'company'],
  [KEY_METRIC_GRAPH_TYPES.PerformanceScore]: ['global', 'company'],
  [KEY_METRIC_GRAPH_TYPES.RankingGauge]: ['global'],
  [KEY_METRIC_GRAPH_TYPES.ResponseRate]: ['global'], // Add custom? Also add in KEY_METRIC_ALLOWLIST_BADGES if so
};

// ? Used to filter out filterbadges that can’t be applied to certain question cards
export const QUESTION_ALLOWLIST_BADGES = {
  list: [...allFilters],
  yesno: [...allFilters, ...allBenchmarks],
  listmany: [...allFilters],
  cnps: [...allFilters, ...allBenchmarks],
  rating: [...allFilters, ...allBenchmarks],
  text: ['topics', ...allFilters],
};
// ? Enable/disable graph-types for different question types
export function QUESTION_GRAPH_TYPES(question) {
  if (!question) return [];
  const questionType = (question.options || {}).cnps ? 'cnps' : (question.question_type || '').toLowerCase();
  if (!questionType) return [];
  const questionId = question?.id;
  const includeTopicTextGraphs = (
    questionId && isTopicable(questionId)
      ? [GRAPH_TYPES.lineGraph, GRAPH_TYPES.barGraph]
      : []
  );
  return {
    list: [GRAPH_TYPES.queryTable, GRAPH_TYPES.barGraph, GRAPH_TYPES.lineGraph, GRAPH_TYPES.donutGraph],
    yesno: [GRAPH_TYPES.queryTable, GRAPH_TYPES.barGraph, GRAPH_TYPES.lineGraph, GRAPH_TYPES.singleValue],
    listmany: [GRAPH_TYPES.queryTable, GRAPH_TYPES.barGraph, GRAPH_TYPES.lineGraph],
    cnps: [GRAPH_TYPES.queryTable, GRAPH_TYPES.lineGraph, GRAPH_TYPES.singleValue, GRAPH_TYPES.barGraph],
    rating: [GRAPH_TYPES.queryTable, GRAPH_TYPES.lineGraph, GRAPH_TYPES.singleValue, GRAPH_TYPES.barGraph],
    text: [GRAPH_TYPES.freeText, ...includeTopicTextGraphs],
  }[questionType] || [];
}

export const QUESTION_GRAPH_TYPE_SCALES = {
  list: '0% — 100%',
  yesno: '0% — 100%',
  listmany: '0% — >100%',
  cnps: '-100 — +100',
  rating: '0 — 10',
  text: '',
};

export const LINE_LIMIT = 5; // ? Arbitrary limit for amount of lines in line graphs

function showSwitchableGraphType(flag, question) {
  if (!flag || !question?.question_type) return false;
  return QUESTION_GRAPH_TYPES(question)?.indexOf(flag) > -1;
}

function canSwitchGraphType(question) {
  const hasType = question && typeof question.question_type === 'string' && question.question_type.toLowerCase();
  return hasType ? QUESTION_GRAPH_TYPES(question).length > 1 : false;
}

// ? Enable/disable show-flags for different question types
const QUESTION_TYPE_FLAGS = {
  list: ['count', 'percentages', 'baseValues', 'numberCell', 'expandedHeader', 'anonymizedRows', 'erroredRows', 'orderAsc'],
  listmany: ['count', 'percentages', 'baseValues', 'numberCell', 'expandedHeader', 'anonymizedRows', 'erroredRows', 'orderAsc'],
  yesno: ['count', 'percentages', 'baseValues', 'benchmarks', 'anonymizedRows', 'erroredRows', 'series', 'orderAsc'],
  cnps: ['count', 'cnps', 'cnpsBar', 'benchmarks', 'anonymizedRows', 'erroredRows', 'series', 'orderAsc'],
  rating: ['average', 'count', 'benchmarks', 'anonymizedRows', 'erroredRows', 'series', 'orderAsc'],
  text: ['pageSize', 'showLabel', 'showTopics', 'translate', 'orderAsc'],
};

const QUESTION_GRAPH_TYPE_FLAGS = {
  [GRAPH_TYPES.queryTable]: ['average', 'count', 'cnps', 'cnpsBar', 'benchmarks', 'percentages', 'baseValues', 'numberCell', 'expandedHeader', 'anonymizedRows', 'erroredRows'],
  [GRAPH_TYPES.barGraph]: ['count', 'erroredRows', 'orderAsc'],
  [GRAPH_TYPES.lineGraph]: ['series', 'baseValues'],
  [GRAPH_TYPES.singleValue]: ['count', 'baseValues'],
  [GRAPH_TYPES.freeText]: ['pageSize', 'showLabel', 'showTopics', 'translate'],
};

// ? Enable/disable show-flags for different Question graph types
const GRAPH_TYPE_FLAGS = (flags = {}, graphType = '', blackList = []) => {
  if (blackList.length) return flags[graphType].filter((flag) => !blackList.includes(flag));
  return flags[graphType];
};

function showQuestionFlag(flag, question, graphType = '', blackList = []) {
  if (!flag || !question || !graphType) return false;
  const qType = question?.options?.cnps ? 'cnps' : question.question_type;
  if (flag === 'showTopics') {
    return isTopicable(question.id)
      && QUESTION_TYPE_FLAGS[qType]?.includes(flag)
      && GRAPH_TYPE_FLAGS(QUESTION_GRAPH_TYPE_FLAGS, graphType, blackList)?.includes(flag);
  }
  return QUESTION_TYPE_FLAGS[qType]?.includes(flag)
    && GRAPH_TYPE_FLAGS(QUESTION_GRAPH_TYPE_FLAGS, graphType, blackList)?.includes(flag);
}

function show(key, question, graphType, showObject, operation = 'and') {
  if (key === undefined) return false;
  const showList = Object.keys(showObject);
  const keys = isArray(key) ? key : [key];
  let valid = false;
  let active = false;
  let flag = '';
  for (let i = 0; i < keys.length; i++) {
    flag = keys[i];
    active = showList.indexOf(flag) > -1 && showObject[flag];
    valid = question.question_type && showQuestionFlag(flag, question, graphType);
    if (valid && active) {
      if (operation === 'or') return true;
      // else assert last return
    } else if (operation === 'and') {
      return false;
    }
  }
  return operation === 'and';
}

// ? Enable/disable show-flags for different chart types
const CHART_FLAGS = {
  bar: ['count', 'orderAsc', 'benchmarks'],
  radialBar: ['benchmarks'],
  line: ['benchmarks'],
  funnelGraph: ['count', 'benchmarks'],
};

// ? Enable/disable show-flags for different Key Metric graph types
const KEY_METRIC_GRAPH_TYPE_FLAGS = {
  [KEY_METRIC_GRAPH_TYPES.SWRanking]: ['onlyBenchmarkableQuestions'],
  // [KEY_METRIC_GRAPH_TYPES.PerformanceScore]: [],
  [KEY_METRIC_GRAPH_TYPES.RankingGauge]: ['question'],
  [KEY_METRIC_GRAPH_TYPES.ResponseRate]: ['count', 'orderAsc', 'benchmarks'],
};

function showKeyMetricFlag(flag, graphType = '', chartType = '', blackList = []) {
  if (!flag || !graphType) return false;
  let chartTypeChecked = true;
  if (chartType && !CHART_FLAGS[chartType]?.includes(flag)) chartTypeChecked = false;
  return chartTypeChecked && GRAPH_TYPE_FLAGS(KEY_METRIC_GRAPH_TYPE_FLAGS, graphType, blackList)?.includes(flag);
}

/**
 * @typedef {object} Field
 * @property {string} path, the path inside metadata.show.?.[slug].?[path]
 * @property {Function} label, visible label for the setting
 * @property {boolean|number|Array} value, default value, also sets type of setting
 * @property {Array} options, optional list of options to use as default if value is number
 * @property {Array} subfields, optional array of {Field}
 * @property {string} icon, optional prepended icon. ex "zmdi-translate"
 * @property {boolean} beta, optional mark with beta pill
*/
/**
 * @typedef {object} FlagTemplate - groups of fields/settings
 * @property {string} slug, the path inside metadata.show.?[slug]
 * @property {Function} title, visible title for the settings group
 * @property {Array} fields, array of {Field}
*/
/**
 * @enum {Array} FlagTemplates - array of {FlagTemplate}
*/
export const FLAG_TEMPLATES = [
  {
    slug: 'freetext',
    title: () => '',
    fields: [
      {
        path: 'pageSize',
        label: () => $gettext('Svar/sida'),
        value: 10,
        options: [5, 10, 15, 20, 50, 100],
      }, {
        path: 'showLabel',
        label: () => $gettext('Visa annonstitel'),
        value: true,
      }, {
        path: 'translate',
        label: () => $gettext('Översätt automatiskt'),
        value: false,
        icon: 'zmdi-translate',
      }, {
        path: 'showTopics',
        label: () => $gettext('Visa textkategorier'),
        value: true,
        beta: true,
      },
    ],
  },
  {
    slug: 'RankingGauge',
    title: () => '',
    fields: [
      {
        path: 'question',
        label: () => $gettext('Visa fråga'),
        value: [],
        // options: [], // filled in by another component
      },
    ],
  },
  {
    slug: 'SWRanking',
    title: () => '',
    fields: [
      {
        path: 'onlyBenchmarkableQuestions',
        label: () => $gettext('Visa endast benchmark-frågor'),
        value: false,
      },
    ],
  },
  {
    slug: 'cells',
    title: () => $gettext('Celler'),
    fields: [{
      path: 'numberCell',
      label: () => $gettext('Smalare celler'),
      value: true,
      subfields: [{
        path: 'columns.expandedHeader',
        label: () => $gettext('Visa hela titlar'),
        value: true,
      }],
    },
    {
      path: 'percentages',
      label: () => $gettext('Visa som procent'),
      value: false,
    }],
  },
  // {
  //   slug: 'graph',
  //   title: () => $gettext('Graf'),
  //   fields: [{
  //     path: 'graph.series',
  //     label: () => $gettext('Visa serier'),
  //     value: [],
  //     options: [], // filled in by CardFlagOptions
  //   },
  // {
  //   path: 'graph.baseValues',
  //   label: () => $gettext('Visa svarsalternativ'),
  //   value: [],
  //   // options: [], // filled in by another component
  // }],
  // },
  {
    slug: 'rows',
    title: () => $gettext('Rader'),
    fields: [{
      path: 'rows.anonymizedRows',
      label: () => $gettext('Visa anonymiserade resultat'),
      value: false,
      icon: 'zmdi-eye-off',
    },
    {
      path: 'rows.erroredRows',
      label: () => $gettext('Visa rader utan resultat'),
      value: false,
      icon: 'zmdi-cloud-off',
    }],
  },
  {
    slug: 'columns',
    title: () => $gettext('Kolumner'),
    fields: [{
      path: 'columns.average',
      label: () => $gettext('Genomsnitt'),
      value: true,
    },
    {
      path: 'columns.benchmarks',
      label: () => $gettext('Benchmarks'),
      value: true,
    },
    {
      path: 'columns.count',
      label: () => $gettext('Totalt antal'),
      value: true,
    },
    {
      path: 'columns.cnps',
      label: (step) => getStepNPSName(step),
      value: false,
    },
    {
      path: 'columns.cnpsBar',
      label: (step) => $pgettext('Col Header — NPS Bar', '%{npsShort} stapel', getStepNPSContext(step)),
      value: false,
    },
    {
      path: 'columns.baseValues',
      label: () => $gettext('Visa svarsalternativ'),
      value: [],
      // options: [], // filled in by another component
    }],
  },
  {
    slug: 'sort',
    title: () => $gettext('Sortering'),
    fields: [
      // {
      //   path: 'sort.sortBy',
      //   label: () => $gettext('Sortera på'),
      //   value: 'total',
      //   options: ['total', 'average', 'count', 'cnps'],
      // },
      {
        path: 'sort.orderAsc',
        label: () => $gettext('Stigande ordning'),
        value: true,
        icon: 'zmdi-sort-amount-asc',
      },
    ],
  },
];

function getAllowedFilters(filter = {}, allowlist = []) {
  return Object.entries(filter).reduce((acc, [fKey, fVal]) => {
    const key = getPureFilterKey(fKey);
    if (allowlist.includes(key)) acc[fKey] = fVal;
    return acc;
  }, {});
}

function getAllowlistCompiledFilter({ filter, cardType, graphTypeSelected = '', questionType = '' }) {
  let allowlist = {
    keyMetric: KEY_METRIC_ALLOWLIST_BADGES?.[graphTypeSelected],
    question: QUESTION_ALLOWLIST_BADGES[questionType],
    goal: allFilters,
  }?.[cardType] || [];

  if (graphTypeSelected !== GRAPH_TYPES.freeText) allowlist = allowlist.filter((allowed) => allowed !== 'topics');
  if (filter?.general) return getAllowedFilters({ general: filter.general }, allowlist);
  return getAllowedFilters(filter, allowlist);
}

function canUseFilterBox(allowlist = [], questionType = '', graphType = '') {
  if (typeof allowlist === 'string') {
    if (allowlist === 'allFilters') allowlist = allFilters;
    else if (allowlist === 'allBenchmarks') allowlist = allBenchmarks;
  }
  const allowlistBadges = graphType
    ? KEY_METRIC_ALLOWLIST_BADGES?.[graphType]
    : QUESTION_ALLOWLIST_BADGES?.[questionType] || [];
  return allowlist.some((allowed) => allowlistBadges?.includes(allowed)) ?? false;
}

const CARD_ICONS = {
  'time-to-hire': IconCalendarClock, // icon-[material-symbols--calendar-clock-outline-rounded]
  'published-jobs': IconNewspaper, // icon-[material-symbols--newspaper-rounded]
  'total-hires': IconHandshake, // icon-[material-symbols--handshake-outline-rounded]
  'total-candidates': IconGroups, // icon-[material-symbols--groups-outline-rounded]
  'rejection-communicated': IconChatError, // icon-[material-symbols--chat-error-outline-rounded]
  'candidates-per-job': IconPersonSearch, // icon-[material-symbols--person-search-outline-rounded]
  'application-completion': IconFactCheck, // icon-[material-symbols--fact-check-outline-rounded]
  'open-jobs': IconDoorOpen, // icon-[material-symbols--door-open-outline-rounded]
};

/**
 * Get the icon for a specific metric
 * @param {string} key
 * @returns {string|Component} icon class or icon component
 */
export function getCardIcon(key) {
  return CARD_ICONS?.[key] || '';
}

export {
  getSum,
  getCNPS,
  show,
  showQuestionFlag,
  showKeyMetricFlag,
  getAverage,
  getCNPSObject,
  getPercentages,
  getKeyPercentage,
  labelToDateRange,
  getCNPSDisplayValue,
  graphTypeElement,
  canSwitchGraphType,
  showSwitchableGraphType,
  getAllowlistCompiledFilter,
  canUseFilterBox,
};
