<template>
  <div>
    <header
      v-if="board"
      ref="boardHeaderEl"
      v-element-size="onHeaderResize"
      class="page-content-header page-content-header-fixed"
      :class="{ shadowed: hasScrolled }"
    >
      <div class="flex gap-2 justify-space-between align-items-end">
        <div class="mt-4">
          <h4
            v-if="isPrintViewRoute && segmentName"
            class="pretty-subtle-text"
          >
            {{ segmentName }}
          </h4>
          <h1
            class="h3 mb-0"
            :class="{
              'tc-loading-text tc-loading-text-inherit tc-loading-text-on-dark size-small':
                loading || board.name == null,
            }"
          >
            <i
              v-if="board?.is_overview"
              v-tooltip="{
                content: () =>
                  $pgettext(
                    'Tooltip - Compass icon',
                    'Översikt — Visas som Översikt för alla i segmentet',
                  ),
                strategy: 'fixed',
                container: 'body',
              }"
              class="zmdi zmdi-compass cursor-help mr-1"
            />
            <i
              v-if="!board.is_public && !loading"
              v-tooltip="{
                content: () =>
                  $pgettext(
                    'Tooltip - Key icon',
                    'Privat — Visas endast för dig',
                  ),
                strategy: 'fixed',
                container: 'body',
              }"
              class="zmdi zmdi-key cursor-help mr-1"
            />
            <span
              v-if="board.name != null"
              v-tooltip="{
                content: isEditingName
                  ? ''
                  : () =>
                    $pgettext(
                      'Tooltip — edit report name',
                      'Dubbelklicka för att ändra namn',
                    ),
                strategy: 'fixed',
                container: 'body',
              }"
              @click="isEditingName ? null : isEditingName = isEditMode"
            >
              <content-editable
                :key="board.name"
                v-model:content="boardName"
                class="no-wrap"
                :is-editing="isEditingName && !(loading || isRemovingBoard)"
                @cancel="isEditingName = false"
                @save="onSaveName($event, board)"
              />
            </span>
            <span v-else>—</span>
            <i
              v-show="fetchingFilters"
              class="zmdi zmdi-spinner tc-color-brand zmdi-hc-spin ml-2"
            />
          </h1>
        </div>
        <div
          v-if="!isPrintViewRoute"
          class="page-toolbar tc-toolbar"
          :class="{
            'gap-2': !isMobile,
            'gap-1': isMobile,
          }"
        >
          <radio-button
            v-tooltip="{
              content: hintTooltipContent || modeTooltipContent,
              delay: { show: 500, hide: 0 },
              strategy: 'fixed',
              container: 'body',
              shown: hintAboutEditMode,
            }"
            :options="[
              {
                label: $pgettext('Button — Board view mode', 'Visa'),
                icon: 'zmdi-eye',
                value: 'view',
              },
              {
                label: $pgettext('Button — Board edit mode', 'Ändra'),
                icon: 'zmdi-edit',
                value: 'edit',
              },
            ]"
            :disabled="board.name == null || disabledButtons"
            :class="{
              'focus-briefly': hintAboutEditMode,
            }"
            :value="mode"
            @change="onToggleMode"
          />

          <div
            v-if="showAllButtons"
            class="btn-group"
          >
            <button
              v-tooltip="{
                content: () =>
                  $pgettext(
                    'Tooltip — Board create report',
                    'Dela en rapport eller prenumerera på den',
                  ),
                strategy: 'fixed',
              }"
              class="btn btn-toolbar btn-primary"
              :class="{ 'btn-icon': isMobile, 'btn-icon-text': !isMobile }"
              :aria-disabled="board.name == null || disabledButtons"
              :aria-label="
                $pgettext('Button — Board create report', 'Dela rapport')
              "
              @click.prevent="openReportModal"
            >
              <i class="zmdi zmdi-share" />
              <span class="hidden-small-down">{{
                $pgettext("Button — Board create report", "Dela rapport")
              }}</span>
            </button>
          </div>

          <VMenu
            :triggers="['click', 'focus', 'hover', 'touch']"
            :skidding="-65"
            :distance="4"
            :auto-hide="false"
            :dispose-timeout="5000"
            strategy="fixed"
          >
            <div class="btn-group h-full">
              <button
                :title="$gettext('Egenskaper')"
                data-testid="settingsButton"
                class="btn btn-icon h-full"
                :aria-disabled="disabledButtons"
              >
                <i class="zmdi zmdi-settings" />
              </button>
            </div>

            <template #popper>
              <dropdown-board-settings
                v-if="!loading"
                :board="board"
                :disabled-buttons="disabledButtons"
                @edit-name="() => (isEditingName = true)"
                @fetch-boards="refetchBoards"
              />
              <div
                v-else
                v-text="$gettext('Laddar…')"
              />
            </template>
          </VMenu>
        </div>
      </div>
      <div class="flex">
        <board-filters
          ref="BoardFiltersEl"
          :context-metadata="processedBoard.metadata"
          :board-id="Number(boardId) || null"
          :loading="fetchingBoards || loading || isRemovingBoard"
        />
      </div>
    </header>
    <hr
      ref="boardHeaderOffsetEl"
      style="margin-top: var(--header-offset-height, 66px)"
    >
    <StrokedArea
      :active="isEditMode"
      theme="brand"
      class="stroked-area-fixed"
    />
    <StrokedArea
      :title="$pgettext('Title — Resizing', 'Ändrar storlekar')"
      :active="isOrganizing"
      theme="warning"
      class="stroked-area-fixed"
      :button="{
        text: $pgettext('Button — Stop resizing', 'Avsluta'),
        action: () => setBoardIsOrganizing(false),
      }"
    />
    <placeholder-card
      v-if="
        isRemovingBoard || (!loading && isPrintViewRoute && board.name == null)
      "
      :placeholder-message="
        isRemovingBoard
          ? $pgettext('Message — Removing report', 'Tar bort rapporten…')
          : $pgettext(
            'Error — Print report (upper msg)',
            'Något gick fel med inladdningen av denna rapport',
          )
      "
      :card-type="isRemovingBoard ? 'hide' : 'card'"
      card-icon="zmdi-alert-octagon"
      :card-message="
        $pgettext(
          'Error — Print report (lower msg)',
          'Prova igen eller ladda ner en annan rapport',
        )
      "
      :cta="null"
    />
    <board-area
      v-if="!isRemovingBoard"
      ref="boardAreaEl"
      :key="board.id"
      is-in-board
      :board="processedBoard"
      class="mb-8"
      :drag-disabled="!isEditMode"
      @all-cards-loaded="setCardAmount"
    />
    <BabushkaPanes :loading="savingDrilldownBoard">
      <template #default="slotProps">
        <BabushkaPaneDrilldown
          :pane-props="slotProps.paneProps"
          :is-removing-board
          :drilldown-board-metadata="drilldownBoard.metadata"
          :board-id="Number(boardId)"
          @loading="(loading) => (savingDrilldownBoard = loading)"
        >
          <template #default="{ pane }">
            <board-area
              v-if="!isRemovingBoard && drilldownBoard"
              :key="`${board.id}-${pane.name}`"
              is-in-pane
              :board="drilldownBoard"
              class="mb-8"
              :drag-disabled="!isEditMode"
              @all-cards-loaded="setCardAmount"
            />
          </template>
        </BabushkaPaneDrilldown>
      </template>
    </BabushkaPanes>
  </div>
</template>

<script>
import { klona } from 'klona';
import { mapState, mapGetters, mapActions } from 'vuex';
import { useWindowScroll, useBreakpoints } from '@vueuse/core';
import { vElementSize } from '@vueuse/components'; // eslint-disable-line import/no-extraneous-dependencies
import { isEmpty } from 'lodash-es';
import eventBus from 'Utils/eventBus';
import { processRecurringDates } from 'Utils/date';
import { openReportModal } from 'Utils/card';
import { mergeFilterBoxRow } from 'Utils/filterBox';
import ContentEditable from 'Components/parts/widgets/ContentEditable';
import DropdownBoardSettings from 'Components/parts/dropdowns/DropdownBoardSettings';
import BoardFilters from 'Components/parts/filters/BoardFilters';
import BoardArea from 'Components/parts/board/BoardArea';
import PlaceholderCard from 'Components/parts/PlaceholderCard';
import StrokedArea from 'Components/parts/widgets/StrokedArea';
import RadioButton from 'Components/parts/form/RadioButton';
import BabushkaPaneDrilldown from 'Components/parts/babushka-pane/BabushkaPaneDrilldown';
import { destroyAllTooltips } from 'Components/parts/widgets/PerformantTooltip';
import setSiteTitle from '@/router/siteTitle';
import BabushkaPanes from '../parts/babushka-pane/BabushkaPanes';

export default {
  name: 'SegmentBoard',
  components: {
    ContentEditable,
    DropdownBoardSettings,
    BoardFilters,
    BoardArea,
    PlaceholderCard,
    StrokedArea,
    BabushkaPaneDrilldown,
    BabushkaPanes,
    RadioButton,
  },
  directives: {
    elementSize: vElementSize,
  },
  props: ['boardId'],
  setup() {
    const { y: windowScrollY } = useWindowScroll();
    const breakpoints = useBreakpoints({
      small: 544,
    });

    const isMobile = breakpoints.smallerOrEqual('small');

    return { windowScrollY, isMobile };
  },
  data() {
    return {
      board: {},
      subscription: {},
      loading: false,
      updating: false,
      cardAmount: false,
      isRemovingBoard: false,
      isEditingName: false,
      savingDrilldownBoard: false,
      boardName: '',
      hintTooltipContent: '',
    };
  },
  computed: {
    ...mapState({
      hintAboutEditMode: (state) => state.boards.hintAboutEditMode,
      isOrganizing: (state) => state.boards.isOrganizing,
      hasUnsavedChanges: (state) => state.modals.hasUnsavedChanges,
      hiddenParams: (state) => state.router.hiddenParams,
    }),
    ...mapGetters([
      'segmentId',
      'segmentName',
      'fetchingFilters',
      'fetchingBoards',
      'me',
      'getCardsByBoardId',
      'showPaywalls',
      'allOpenPanes',
      'isEditMode',
    ]),
    isSubscription() {
      return parseInt(this.$route.query.subscription, 10) || false;
    },
    processedBoard() {
      if (!this.isPrintViewRoute) return this.board;
      const board = klona(this.board);
      const { date } = this.board?.metadata?.filter || {};
      const { recurrence_rule } = this.subscription;
      if (
        !date?.unprocessedDate
        && !isEmpty(this.subscription)
        && !isEmpty(this.board)
        && !isEmpty(date)
        && recurrence_rule
      ) {
        const processedBoardDate = processRecurringDates(recurrence_rule, date);
        board.metadata.filter.date = {
          type: 'absolute',
          ...processedBoardDate,
          unprocessedDate: board.metadata.filter.date,
        };
      }
      return board;
    },
    disabledButtons() {
      return (
        this.isRemovingBoard
        || this.loading
        || !(this.cardAmount || this.cardAmount === 0)
      );
    },
    showAllButtons() {
      return !!this.getCardsByBoardId(Number(this.boardId))?.length || false;
    },
    isPrintViewRoute() {
      return this.$route?.name?.indexOf('print-') > -1;
    },
    hasScrolled() {
      return this.windowScrollY >= 10;
    },
    mode() {
      return this.isEditMode ? 'edit' : 'view';
    },
    modeTooltipContent() {
      return this.isEditMode
        ? this.$pgettext('Tooltip — Board isEditMode', 'Byt till visningsläge')
        : this.$pgettext('Tooltip — Board !isEditMode', 'Byt till ändringsläge');
    },
    drilldownBoard() {
      const dBoard = klona(this.board);
      dBoard.sections = this.$store.getters.getSectionsByBoardId(this.board.id);
      return this.mergePaneFiltersOntoDrilldownBoard(dBoard);
    },
  },
  watch: {
    $route(to, from) {
      destroyAllTooltips();
      if (to?.fullPath !== from?.fullPath) this.load(to.params.boardId);
    },
    boardId(idNew, idOld) {
      if (idNew !== idOld && !this.loading) this.load(idNew);
    },
    isEditMode(newVal) {
      if (newVal === false) this.onSaveName(this.boardName); // ? Save the name when exiting edit mode

      if (!newVal && this.hasUnsavedChanges) {
        // If going from edit to view, reset unsaved filter changes (after user confirmed leave)
        this.$refs.BoardFiltersEl?.resetFilterChanges();
      }
    },
    hintAboutEditMode(val) {
      if (val) {
        this.hintTooltipContent = this.$pgettext(
          'Tooltip — FilterDropdowns isDisabled',
          'Klicka på ÄNDRA-knappen för att byta till ändringsläge.',
        );
      } else {
        // Set timeout to prevent "hint" tooltip content changing just before hide
        setTimeout(() => {
          this.hintTooltipContent = null;
        }, 500);
      }
    },
  },
  created() {
    if (this.isPrintViewRoute && this.isSubscription) this.fetchSubscription();
    this.load(this.boardId);
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'SET_BOARD') {
        if (mutation.payload.board.id === this.board.id) {
          this.board = mutation.payload.board;
        }
      }
    });
    this.$nextTick(() => {
      eventBus.$on('removing-board', this.removingBoard);
      eventBus.$on('removed-board', this.removedBoard);
    });
  },
  beforeUnmount() {
    if (this.isEditMode) this.setBoardIsEditMode(false);
    this.cardAmount = false;
    this.unsubscribe();
    eventBus.$off('removing-board', this.removingBoard);
    eventBus.$off('removed-board', this.removedBoard);
  },
  methods: {
    ...mapActions([
      'notify',
      'openModal',
      'fetchBoard',
      'fetchAllBoardsBySegmentId',
      'setBoardIsOrganizing',
      'fetchTask',
      'setBoardIsEditMode',
      'closePane',
      'fetchAllBoardsBySegmentId',
    ]),
    onToggleMode(newMode) {
      this.setBoardIsEditMode(newMode === 'edit');
    },
    async fetchSubscription() {
      try {
        this.subscription = await this.fetchTask({
          taskType: 'subscription',
          taskId: this.isSubscription,
        });
      } catch (error) {
        this.subscription = {};
      }
    },
    async onSaveName(newName, board = this.board) {
      this.isEditingName = false;
      try {
        const newBoard = klona(board);
        if (newName === newBoard.name) return;
        if (typeof newBoard.save !== 'function') throw new Error(newName);
        newBoard.name = newName;
        await newBoard.save();
        this.load(this.boardId);
      } catch (err) {
        this.notify({
          type: 'pop',
          level: 'warning',
          text: this.$gettext('Kunde inte spara: %{msg}', {
            msg: err?.response?.body?.detail ?? err?.message ?? err,
          }),
        });
      }
    },
    removingBoard(boardId) {
      this.isRemovingBoard = true;
    },
    async removedBoard(boardId) {
      return this.fetchAllBoardsBySegmentId().then(() => {
        this.isRemovingBoard = false;
        this.$router.push({ name: 'overview' }).catch((err) => {});
        // ? Edge case board.is_overview can’t happen as it’s not possible to remove overview UI-wise
      });
    },
    async refetchBoards(id = this.boardId) {
      this.load(id);
      this.fetchAllBoardsBySegmentId();
    },
    setCardAmount(payload) {
      this.cardAmount = payload;
    },
    // createNewSection() {
    //   if (this.$refs.boardAreaEl && !this.disabledButtons) this.$refs.boardAreaEl.createNewSection();
    // },
    async load(boardId) {
      if (boardId === undefined) return;
      this.loading = true;
      this.isEditingName = false;
      this.setBoardIsOrganizing(false);

      try {
        const board = await this.fetchBoard({ boardId });
        if (board.segment === this.segmentId) {
          // ? Check that store and URL are in sync
          this.init(board);
        } else {
          this.$router.replace({
            name: 'board',
            params: { boardId, segmentId: board.segment },
          });
        }
      } catch (error) {
        this.notify({
          text: this.$gettext('Kunde inte hämta rapport (%{boardId}) 😕', {
            boardId,
          }),
          level: 'error',
        });
      } finally {
        this.loading = false;
      }
    },
    openReportModal() {
      if (
        this.showPaywalls
        || (this.board.name != null && !this.disabledButtons)
      ) {
        openReportModal({
          props: {
            segmentName: this.segmentName,
            label: this.board.name,
            boardId: this.boardId,
            boardFilterDate: this.board.metadata.filter.date,
          },
        });
      }
    },
    init(newBoard) {
      this.board = klona(newBoard);
      this.boardName = newBoard.name;
      if (newBoard?.name) setSiteTitle({ meta: { siteTitle: () => newBoard.name } });
      const boardCardAmount = Object.values(newBoard?.sections || {}).reduce(
        (cardAmount, section) => {
          cardAmount += section?.cards?.length ?? 0;
          return cardAmount;
        },
        0,
      );
      this.setCardAmount(boardCardAmount);
    },
    mergePaneFiltersOntoDrilldownBoard(board) {
      if (board?.metadata?.filter == null || isEmpty(board.metadata.filter)) return board;

      const newBoard = klona(board);
      const newMetadata = this.allOpenPanes.reduce((acc, pane) => {
        // ? Using allOpenPanes to get all filters, not just the current one.
        const drilldownDataAsBoxRow = {
          type:
            pane.drilldownData?.boxRowType ?? pane.drilldownData?.slug ?? '',
          key: pane.drilldownData?.boxRowKey ?? pane.drilldownData?.slug ?? '',
          value:
            pane.drilldownData?.boxRowValue ?? pane.drilldownData?.value ?? [],
        };
        return mergeFilterBoxRow(acc, drilldownDataAsBoxRow);
      }, newBoard.metadata.filter);

      newBoard.metadata.filter = newMetadata;
      return newBoard;
    },
    onHeaderResize({ width, height }) {
      let currentWidth = window?.document?.body?.getBoundingClientRect?.()?.width ?? width;
      let currentHeight = height || 90;
      let offsetPx = 16;
      if (currentWidth > 992) {
        // Breakpoint large, desktop view
        offsetPx = 24;
      }

      document.documentElement.style.setProperty(
        '--header-offset-height',
        `${Math.ceil(currentHeight - offsetPx)}px`,
      );
      if (this.$refs?.boardHeaderEl) {
        // ? Set header height so popper can calculate correct position
        const boardHeaderContainerRect = this.$refs.boardHeaderEl.getBoundingClientRect();
        document.documentElement.style.setProperty(
          '--header-height',
          `${Math.ceil(boardHeaderContainerRect.height)}px`,
        );
      }
    },
  },
};
</script>
