import request from 'superagent';
import { captureMessage } from '@/plugins/sentry';
import languageApi from 'API/userLanguage';
import { BASE_URL } from 'Utils/urls';
import { localStorageIsAvailable, getCookies, removeCookies } from 'Utils/storage';
import { isAuthenticated, handleAuthError, getToken, setToken, clearLoginLocalStorage } from 'Utils/authHelpers';
import { superagentSetDashboardOriginPage } from 'Utils/apiHelpers';
import { store } from '@/store';
import gettext from '@/gettext';
// ? Beware of importing here, could be causing circular dependencies

const { $pgettext } = gettext;

// login with user/pw combo, not OAuth login
export function loginUserPW(username, password) {
  return request
    .post(`${BASE_URL}/api-token-auth/`)
    .send({
      username,
      password,
    })
    .then((response) => setToken(response.body.token, 'token', response.body.refresh_token))
    .then(() => languageApi.getLang(true))
    .catch((err) => {
      if (err.response.body !== undefined) {
        return Promise.reject(err.response.body);
      }
      throw new Error('Inloggning misslyckades');
    });
}

export const catchLoginError = (next, to, error = $pgettext('Error — login catch', 'Kunde inte logga in, prova igen eller kontakta oss i chatten')) => {
  captureMessage(next, to, error); // ? Send to Sentry
  clearLoginLocalStorage();
  store.dispatch('setHiddenParams', {
    next: !to?.redirectedFrom ? to?.fullPath : undefined,
    error,
  });
  return next({ name: 'login' });
};

// ? [Circular dependency fixes]
function _postBaseUrl(url, data = null) {
  const token = getToken().token;
  let r = request
    .post(`${BASE_URL}${url}`)
    .use(superagentSetDashboardOriginPage)
    .accept('application/json')
    .on('abort', () => Promise.resolve());
  if (token) r = r.set('Authorization', `JWT ${token}`);

  if (data !== null) return r.send(data);
  return r;
}
function _postUrl(url, data = null) {
  return _postBaseUrl(url, data)
    .then((response) => response.body, handleAuthError)
    .catch((error) => {
      if (error.status >= 500) {
        store.dispatch('notify', {
          text: `${error.status} (${error.message})`,
          level: 'error',
        });
      }
      throw error;
    });
}
// ? [/Circular dependency fixes]

export function authRouterSetup(router, app) {
  router.beforeEach((to, from, next) => {
    if (to.path.indexOf('/otp') === 0) {
      isAuthenticated().then(
        () => languageApi.getLangIfNotInStore(app, true) // on resolve
          .then(() => next({ name: 'home' }))
          .catch((err) => catchLoginError(next, to, $pgettext('Error — login catch errCode', 'Error#%{errCode} — Kunde inte validera inloggningen, prova igen eller kontakta oss i chatten om detta händer ofta. %{err}', { err, errCode: 4001 }))),
        () => { // on reject
          if (to.params.email || localStorageIsAvailable && localStorage.getItem('emailAddress')) { // ? Require email param to somewhat make sure that an email has been sent
            next();
          } else {
            store.dispatch('setHiddenParams', {
              next: !to.redirectedFrom ? to.fullPath : undefined,
              error: $pgettext('Error — no email for /otp', 'Engångslösenordet har inte skickats då det inte fanns någon epostadress att skicka till'),
            });
            next({ name: 'login' });
          }
        },
      );
    } else if (to.path.indexOf('/login') === 0) {
      if (to.params.error) {
        clearLoginLocalStorage();
        store.dispatch('setHiddenParams', { error: to.params.error });
        next(); // ? Would loop otherwise
      } else {
        const tokenFromRoute = to.params.token || to.query.token;
        if (tokenFromRoute) setToken(tokenFromRoute, 'token', to.params.refresh_token || to.query.refresh_token);
        const cookies = getCookies(['at', 'rt']);
        if (cookies.at != null && cookies.rt != null && localStorageIsAvailable) {
          setToken(cookies.at, 'token', cookies.rt);
          removeCookies(['at', 'rt']); // ? Requires a client-side reload to work
        }
        isAuthenticated().then(
          () => languageApi.getLang(true)
            .then(() => {
              const invitation = localStorageIsAvailable && JSON.parse(localStorage.getItem('invite') ?? '{}');

              if (invitation.id && invitation.token) {
                const acceptFunction = invitation.type === 'segmentUser' // ? Not imported from files because of circular dependencies
                  ? (id, token) => _postUrl(`/feedback/accounts/api/segmentuserinvitation/${id}/accept/`, { token })
                  : (id, token) => _postUrl(`/feedback/accounts/api/customeruserinvitation/${id}/accept/`, { token });
                acceptFunction(invitation.id, invitation.token)
                  .then((resp) => {
                    if (!resp?.errCode && localStorageIsAvailable) localStorage.removeItem('invite');
                    next({ name: 'home' });
                  }).catch((err) => {
                    console.error('[TC] We had an issue with accepting your invitation.', err?.message ?? err); // eslint-disable-line no-console
                    next({ name: 'home' });
                  });
              } else {
                next({ name: 'home' });
              }
            })
            .catch((err) => catchLoginError(next, to, $pgettext('Error — login catch errCode', 'Error#%{errCode} — Kunde inte validera inloggningen, prova igen eller kontakta oss i chatten om detta händer ofta. %{err}', { err, errCode: 4002 }))),
          next,
        );
      }
    } else if (to.meta.printAuth || to.meta.embedAuth) {
      setToken(to.query.token);
      isAuthenticated('token')
        .then(languageApi.getLangIfNotInStore(app, true))
        .then(
          next, // on resolve
          () => {
            if (to.meta.printAuth) {
              store.dispatch('setHiddenParams', { error: $pgettext('Error — no valid printAuth', 'Kunde inte validera print-token') });
              return next({ name: 'print-error' });
            }
            store.dispatch('setHiddenParams', { error: $pgettext('Error — no valid embedAuth', 'Kunde inte validera embed-token') });
            return next({ name: 'embed-error' });
          }, // on reject
        );
    } else if (to.meta.auth) {
      isAuthenticated().then(
        () => languageApi.getLangIfNotInStore(app, true).then(() => next())
          .catch((err) => catchLoginError(next, to, $pgettext('Error — login catch errCode', 'Error#%{errCode} — Kunde inte validera inloggningen, prova igen eller kontakta oss i chatten om detta händer ofta. %{err}', { err, errCode: 4003 }))),
        () => {
          store.dispatch('setHiddenParams', {
            next: !to.redirectedFrom ? to.fullPath : undefined,
            error: getToken().isAuthenticated ? $pgettext('Error — no valid auth', 'Kunde inte validera inloggningen') : '',
          });
          return next({ name: 'login' });
        },

      );
    } else {
      isAuthenticated()
        .then(
          () => languageApi.getLangIfNotInStore(app, true).then(() => next())
            .catch((err) => catchLoginError(next, to, $pgettext('Error — login catch errCode', 'Error#%{errCode} — Kunde inte validera inloggningen, prova igen eller kontakta oss i chatten om detta händer ofta. %{err}', { err, errCode: 4004 }))),
          next(),
        )
        .catch(() => {
          // ? If not authenticated catchLoginError should probably be called here instead, but if it is and in invite flow, it will break the flow
          // TODO we should probably add another flow for invite, where we don't redirect to login if not authenticated
        });
    }
  });

  router.afterEach((to, from) => {
    if (
      (from.path.indexOf('/otp') === 0 || from.meta.auth)
      && (localStorageIsAvailable && localStorage.getItem('emailAddress'))
    ) {
      localStorage.removeItem('emailAddress');
    }
  });
}
