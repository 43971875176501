<template>
  <VMenu
    theme="select"
    :shown="isOpen"
    :triggers="[]"
    :popper-triggers="[]"
    auto-size="min"
    :skidding="-14"
    :distance="4"
    v-bind="popperSettings ?? {}"
  >
    <span
      v-bind="$attrs"
      ref="tcSelectInline"
      class="tc-select-inline"
      :class="{ active: isOpen }"
      @click.stop="toggle(true)"
      @keydown.page-up.stop.prevent="focus(0)"
      @keydown.page-down.stop.prevent="focus(-1)"
      @keydown.up.stop.prevent="toggle(true)"
      @keydown.down.stop.prevent="isOpen ? focus(0) : toggle(true)"
    >
      <span
        class="tc-select-inline-value"
        tabindex="0"
        @keyup.enter.stop="toggle(true)"
      >{{ inlineValue }}</span>
      <i
        v-if="hasSelected && fallback === false"
        class="zmdi zmdi-close-circle ml-1"
        tabindex="0"
        @keyup.enter.prevent.stop="clear"
        @click.prevent.stop="clear"
      />
      <i
        v-else
        class="zmdi zmdi-chevron-down ml-1"
      />
    </span>

    <template #popper>
      <transition name="fade-quick">
        <div
          v-if="isOpen"
          class="tc-select-inline-options"
          :style="align"
          @click.stop
        >
          <ul
            v-if="selectableOptions.length > 0"
            class="tc-select-inline-options-list"
          >
            <li
              v-if="selectableOptions.length === 1"
              class="tc-select-inline-item tc-select-no-options subtle-text"
              :disabled="true"
            >
              {{ noOptionsText || $gettext('Inga fler val finns') }}
            </li>
            <li
              v-for="(option, index) in selectableOptions"
              v-else
              ref="options"
              :key="option.id"
              class="tc-select-inline-item"
              :class="{ 'active': option.value === selected.value }"
              :disabled="isDisabled(option.value) || null"
              tabindex="0"
              @keyup.enter="select(option)"
              @keydown.up.stop.prevent="focus(index > 0 ? index - 1 : -1)"
              @keydown.down.stop.prevent="focus(index < selectableOptions.length - 1 ? index + 1 : 0)"
              @click="select(option)"
            >
              {{ option.label }}
              <i
                v-if="option.icon && option.icon[0] !== '<'"
                :class="`zmdi ${option.icon} pl-2`"
              />
            </li>
          </ul>
        </div>
      </transition>
    </template>
  </VMenu>
</template>

<script>
import { mapActions } from 'vuex';
import gettext from '@/gettext';

const { $gettext } = gettext;

export default {
  props: {
    modelValue: Array,
    alignOptions: Object,
    fallback: {
      type: [Object, Boolean, String],
      default: false,
    },
    options: {
      type: Array,
      required: true,
    },
    disabledOptions: {
      type: Array,
      default: () => ([]), // Only keys
    },
    noOptionsText: {
      type: String,
      default() {
        return $gettext('Inga fler val finns');
      },
    },
    placeholder: {
      type: String,
      default() {
        return $gettext('Välj');
      },
    },
    popperSettings: {
      type: Object,
      default: () => ({
        container: false,
        strategy: 'absolute',
      }),
    },
  },
  emits: ['update:model-value', 'open', 'close'],
  data() {
    return {
      isOpen: false,
      selected: [],
    };
  },
  computed: {
    align() {
      if (this.alignOptions) {
        let styling = '';
        Object.keys(this.alignOptions).forEach((opt) => {
          switch (opt) {
            case 'top':
              styling += 'FreeText ';
              break;
            case 'right':
              styling += 'left: initial; right:0; ';
              break;
            case 'bottom':
              break;
            case 'left':
              styling += 'left: 0; right: initial;';
              break;
            default:
              break;
          }
        });
        return styling;
      }
      return '';
    },
    inlineValue() {
      if (this.selected.length > 0) return this.selected?.[0]?.label;
      return this.fallback?.label || this.placeholder || this.$gettext('Välj');
    },
    selectableOptions() {
      return this.options.filter((option) => this.modelValue !== option);
      // if (this.disabledOptions.indexOf(option.value) > -1) return false;
    },
    hasSelected() {
      return Object.keys(this.selected).length > 0;
    },
    activeIndex() {
      return this.selectableOptions.findIndex((opt) => opt.value === this.selected?.[0]?.value) || 0;
    },
  },
  watch: {
    options: {
      deep: true,
      handler() {
        this.selected = this.options.filter((o) => o.value === this.modelValue?.[0].value) || [];
      },
    },
    value: {
      deep: true,
      handler(oldVal) {
        this.selected = this.options.filter((o) => o.value === oldVal?.[0]?.value) || [];
      },
    },
    selected: {
      deep: true,
      handler(val) {
        if (val.length === 0 && this.fallback !== false) {
          this.selected = [this.fallback];
          this.$emit('update:model-value', this.selected);
        }
      },
    },
  },
  mounted() {
    this.selected = this.options.filter((o) => o.value === this.modelValue?.[0]?.value) || [];
  },
  methods: {
    ...mapActions([
      'addCloseHandler',
      'removeCloseHandler',
      'removeAllCloseHandlers',
    ]),
    open() {
      this.$emit('open');
      this.isOpen = true;
      this.removeAllCloseHandlers();
      this.addCloseHandler(this.close);
    },
    close() {
      this.$emit('close');
      this.isOpen = false;
      this.removeCloseHandler(this.close);
    },
    toggle(andFocus = false) {
      let focusOn = 0;
      if (this.isOpen) {
        this.close();
        focusOn = andFocus === true ? null : andFocus;
      } else {
        this.open();
        focusOn = andFocus === true ? this.activeIndex : andFocus;
      }

      if (andFocus !== false) this.$nextTick(() => this.focus(focusOn));
    },
    focus(optionIndex = 0) {
      if (this.selectableOptions.length > 1) {
        let i = 0;
        if (optionIndex === null) { // ? Focus on tcSelectInline
          if (this.$refs.tcSelectInline) this.$refs.tcSelectInline.focus();
        } else {
          if (optionIndex === -1) i = this.selectableOptions.length - 1; // ? Focus last option if '-1'
          else if (optionIndex >= this.selectableOptions.length) i = this.selectableOptions.length - 1; // ? Focus last option if at the end
          else i = optionIndex;

          if (this.$refs?.options?.[i]) this.$refs.options[i].focus();
        }
      }
    },
    isDisabled(value) {
      return this.disabledOptions.indexOf(value) > -1;
    },
    clear() {
      this.selected = [];
      this.$emit('update:model-value', this.selected);
    },
    remove(option) {
      const index = this.selected.findIndex((o) => o.value === option.value);
      this.selected.splice(index, 1);
      this.$emit('update:model-value', this.selected);
      if (this.isOpen) {
        this.focus();
      }
    },
    select(option) {
      if (!this.isDisabled(option.value)) {
        if (!this.multiselect) {
          this.selected.forEach((o) => {
            this.remove(o);
          });
        }
        this.selected.push(option);
        this.$emit('update:model-value', this.selected);
        this.close();
      }
    },
  },
};
</script>
