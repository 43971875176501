<template>
  <li
    v-if="item.value"
    v-tooltip="{ content: badgeValuesTooltip, placement: 'top', html: true }"
    class="badge badge-filter"
    :class="{
      'badge-overlapping': item.isCardFilter,
      'badge-error': error,
    }"
  >
    <!-- eslint-disable-next-line max-len -->
    <span class="badge-compare-key">
      <span class="badge-compare--label">{{ item.label }}</span><span
        class="badge-compare--with"
        v-text="` ${$pgettext('Badge — Compare with', 'Med')}`"
      />
    </span>
    <span class="badge-compare-value">{{ value }}<i-material-symbols-translate
      v-if="error"
      aria-label="Error"
      class="ml-1 valign-bottom tc-color-red"
    /></span>
  </li>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { striphtml } from 'Utils/general';

export default {
  name: 'BadgeListItemCompare',
  props: {
    item: Object,
  },
  data() {
    return {
      error: null,
    };
  },
  computed: {
    ...mapGetters(['segmentTagKeyVal']),
    value() {
      if (this.error) return this.item.value || '';

      if (this.item.slug === 'compare__tag') {
        if (!this.segmentTagKeyVal?.[this.item.value]) {
          // eslint-disable-next-line vue/no-async-in-computed-properties
          this.segmentTagKeyTranslation(this.item.value).catch((err) => { this.error = err; }); // ? Computed looping error otherwise
        }
        return this.segmentTagKeyVal?.[this.item.value] || this.item.value;
      }

      return this.item.value;
    },
    badgeValuesTooltip() {
      if (this.error) return striphtml(this.error);
      return `<strong>Compare – ${striphtml(this.item.label)}</strong>: ${striphtml(this.item.value)}`;
    },
  },
  methods: {
    ...mapActions(['segmentTagKeyTranslation']),
  },
};
</script>
