<template>
  <span class="compare-date-filter small-text">
    <span
      v-tooltip.bottom-start="$pgettext(
        'Tooltip — Date select in header',
        'Perioden som är vald i denna datumväljaren restrikterar vad som visas undertill'
      )"
      class="compare-date-filter-title cursor-help mr-1"
    >
      <i
        v-if="dateIcon"
        class="zmdi"
        :class="dateIcon"
      />
      {{ dateLabel }}
    </span>
    <span
      class="compare-date-filter-date"
    >
      <tc-date-picker
        v-model:date-object="selectedDate"
        class="inline-block"
        :popper-settings="{ strategy: 'absolute' }"
        @update:date-object="update"
      /> <i class="zmdi zmdi-chevron-down" />
    </span>
  </span>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { captureMessage } from '@/plugins/sentry';
import eventBus from 'Utils/eventBus';
import TcDatePicker from 'Components/parts/TcDatePicker';
import gettext from '@/gettext';

const { $gettext } = gettext;

export default {
  components: {
    TcDatePicker,
  },
  props: {
    dateLabel: {
      type: String,
      default: () => $gettext('Datum'),
    },
    dateIcon: {
      type: String,
      default: 'zmdi-calendar',
    },
  },
  data() {
    return {
      selectedDate: {
        type: 'relative',
        offset: null,
        span: {
          months: 12,
        },
      },
    };
  },
  computed: {
    ...mapGetters(['segmentFilter']),
  },
  mounted() {
    if (this.segmentFilter?.date) this.selectedDate = this.segmentFilter.date;
  },
  methods: {
    ...mapActions([
      'updateFilter',
    ]),
    async update() {
      try {
        await this.updateFilter({
          date: this.selectedDate,
        });
        eventBus.$emit('update:filter');
      } catch (err) {
        captureMessage('[TC] CompareDateFilter couldn’t updateFilter', err);
      }
    },
  },
};
</script>
