<template>
  <card>
    <template #header>
      <h3 class="p-card">
        {{ title }}
      </h3>
    </template>
    <template #body>
      <form
        class="p-card"
        @submit.prevent="submit"
      >
        <div class="form-group">
          <label
            v-if="label"
            class="control-label"
          >{{ label }}</label>
          <tc-select
            v-if="typeof options === 'function'"
            v-model="selected"
            :on-change="options"
          />
          <input
            v-else
            ref="input"
            v-model="input"
            tabindex
            class="form-control form-control-filled"
            type="text"
            @keydown.tab.shift.exact.prevent="$refs.confirm && $refs.confirm.focus()"
          >
          <slot />
          <p
            v-if="helperText"
            class="subtle-text small-text mt-2"
            v-text="helperText"
          />
        </div>
        <div class="modal-dialog-buttons">
          <div class="mt-2">
            <button
              ref="dismiss"
              tabindex
              type="button"
              class="btn btn-text"
              :class="{ 'btn-link-bw': theme === 'danger' }"
              @keydown.tab.shift.exact="dismissStartTrap"
              @click.prevent="dismiss"
            >
              {{ dismissText || $gettext('Avbryt') }}
            </button>
            <button
              ref="confirm"
              tabindex
              type="submit"
              class="btn"
              :class="{ 'btn-failure': theme === 'danger' }"
              :disabled="loadState || input.length === 0 && selected.length === 0"
              @keydown.tab.exact.prevent="confirmEndTrap"
            >
              <i
                v-if="loadState"
                class="zmdi zmdi-spinner zmdi-hc-spin"
              /> {{ confirmText || $gettext('Skicka') }}
            </button>
          </div>
        </div>
      </form>
    </template>
  </card>
</template>

<script>
export default {
  props: {
    title: String,
    options: Function,
    selectedOption: Object,
    confirmText: String,
    dismissText: String,
    helperText: String,
    label: String,
    loadState: {
      default: false,
      type: Boolean,
    },
    theme: String, // 'primary' || 'danger'
  },
  emits: ['dismiss'],
  data() {
    return {
      input: '',
      selected: [],
    };
  },
  computed: {
    value() {
      return this.input || (this.selected[0] || {}).value;
    },
  },
  watch: {
    title() {
      this.reset();
    },
    options() {
      this.reset();
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this?.selectedOption) this.selected = [this.selectedOption];
      if (this.$refs.input) {
        this.$refs.input.focus();
        return;
      }
      if (this.$refs.confirm) {
        this.$refs.confirm.focus();
      }
    });
  },
  methods: {
    submit() {
      if (this.value) {
        this.$emit('dismiss', this.value);
      }
    },
    dismiss() {
      this.$emit('dismiss', false);
    },
    confirmEndTrap(e) {
      if (this.$refs.input) this.$refs.input.focus();
      else this.$refs.dismiss.focus();
    },
    dismissStartTrap(e) {
      if (typeof this.options === 'function' && this.$refs.confirm) {
        e.preventDefault();
        this.$refs.confirm.focus();
      }
    },
    reset() {
      this.input = '';
      this.selected = [];
    },
  },
};
</script>
