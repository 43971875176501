<template>
  <teleport
    defer
    to="#teleport-outside-main"
    :disabled="false"
  >
    <transition-group name="fade-in">
      <div
        v-for="(pane, idx) in store.getters.allOpenPanes"
        :key="pane.name"
        class="pane-underlay"
        :style="`z-index: ${45 + idx};`"
        @click="closePane"
      />
    </transition-group>
    <transition-group name="slide-pane">
      <aside
        v-for="(pane, idx) in store.getters.allOpenPanes"
        :key="pane.name"
        v-tc-loader-pill="loading"
        class="main-cols aside-pane py-4 px-4 tc-loader-pill-wrapper"
        :style="`z-index: ${45 + idx};`"
      >
        <slot
          :pane-props="{ pane, idx }"
        />
      </aside>
    </transition-group>
  </teleport>
</template>

<script setup>
import { onKeyStroke } from '@vueuse/core';
import { store } from '@/store';

const props = defineProps({
  loading: Boolean,
});

const closePane = () => store.dispatch('closePane');

onKeyStroke('Escape', (e) => closePane());

</script>
