<template>
  <form>
    <div class="flexy-row justify-start align-items-baseline">
      <h3 class="flexy-col pr-0 mb-0">
        {{ $pgettext('Feedback filter answers [verb]','Filtrera respondenter') }}
      </h3>
      <button
        class="flexy-col btn btn-link"
        type="button"
        @click.stop.prevent="clearFilters"
      >
        <i class="zmdi zmdi-rotate-left" />
        {{ $pgettext('Clear filtering [verb]','Rensa filter') }}
      </button>
    </div>
    <div class="row">
      <div class="col-md-3">
        <div class="form-group mb-2">
          <label for="statuses">
            {{ $gettext('Status') }}
          </label>
          <chip-set
            v-if="statusOptions && statusOptions.length > 0"
            id="statuses"
            v-model="statuses"
            :outline="true"
            type="filter"
          >
            <chip
              v-for="status in statusOptions"
              :key="status.value"
              :value="status.value"
              :disabled="levelsLoading"
            >
              <!-- eslint-disable-next-line max-len -->
              <span :class="{ 'tc-loading-text tc-loading-text-inherit tc-loading-text-on-dark size-inherit': levelsLoading }">
                {{ status.label }}
              </span>
            </chip>
          </chip-set>
        </div>
      </div>
      <div class="col-md-9">
        <div class="form-group mb-2">
          <label for="levels">{{ $gettext('Formulär') }}</label>
          <chip-set
            v-if="!levelsLoading && sortedCustomerAllstepsOptions && sortedCustomerAllstepsOptions.length > 0"
            id="levels"
            v-model="levels"
            :outline="true"
            type="filter"
          >
            <chip
              v-for="step in sortedCustomerAllstepsOptions"
              :key="step.value"
              v-tooltip="step?.inactive
                ? $pgettext(
                  'Tooltip — Inactive step',
                  'Detta formulär är pausat. Det finns möjligtvis data kvar ifall du vill kolla in ändå.'
                ) : null"
              :value="step.value"
              :disabled="levelsLoading"
            >
              <i
                v-if="step?.inactive"
                class="zmdi zmdi-pause-circle mr-1"
              />
              <!-- eslint-disable-next-line max-len -->
              <span :class="{ 'tc-loading-text tc-loading-text-inherit tc-loading-text-on-dark size-inherit': levelsLoading }">
                {{ step.label }}
              </span>
            </chip>
          </chip-set>
        </div>
      </div>
    </div>
    <hr class="my-6">
  </form>
</template>

<script>
import { mapGetters } from 'vuex';
import Chip from '../chips/Chip';
import ChipSet from '../chips/ChipSet';

export default {
  name: 'FollowUpFilter',
  components: {
    Chip,
    ChipSet,
  },
  data() {
    return {
      statusOptions: [
        {
          label: this.$pgettext('label for statusOptions', 'Hanterad'),
          value: 'handled',
        },
        {
          label: this.$pgettext('label for statusOptions', 'Ej hanterad'),
          value: 'unhandled',
        },
      ],
      statuses: [],
      levels: [],
      levelsLoading: true,
    };
  },
  computed: {
    ...mapGetters([
      'customerProxies',
      'customerAllStepsOptions',
    ]),
    sortedCustomerAllstepsOptions() {
      return [...this.customerAllStepsOptions].sort((a, b) => {
        if (a.inactive && !b.inactive) return 1;
        if (!a.inactive && b.inactive) return -1;
        return 0;
      });
    },
    routeQuery() {
      return this.$route.query;
    },
    query() {
      const trueArray = ['handled'];
      let q = {};
      if (this.levels.length > 0) q.level = this.levels.join(',');
      if (this.statuses.length > 0) {
        this.statuses.forEach((status) => {
          let state = trueArray.includes(status).toString();
          if (status === 'handled' || status === 'unhandled') {
            q.handled = q.handled?.length > 0
              ? `${q.handled},${state}` : state;
          }
        });
      }
      return q;
    },
  },
  watch: {
    routeQuery: {
      immediate: true,
      handler(query) {
        this.$nextTick(() => {
          this.setFromQueryVars(query);
        });
      },
    },
    query(query) {
      this.update(query);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.levelsLoading = false;
    });
  },
  methods: {
    clearFilters() {
      this.$router.replace({ name: this.$route.name })
        .catch((err) => {});
    },
    update(query) {
      this.$router.replace({ name: this.$route.name, query, params: this.$route.params })
        .catch((err) => {});
    },
    setFromQueryVars(query) {
      const handledOptions = ['handled', 'unhandled'];
      const statusNotInQuery = !Object.keys(query)
        .some((q) => ['handled'].includes(q));
      if (query.level !== undefined) this.levels = query.level.split(',');
      else this.levels = [];
      if (query.handled !== undefined && !this.statuses.find((status) => handledOptions.includes(status))) {
        this.statuses = [...this.statuses, ...this.getQueryKeyValues(query, 'handled', handledOptions)];
      }
      if (statusNotInQuery) this.statuses = [];
    },
    getQueryKeyValues(query, key = '', statusOptions = []) {
      let arr = query[key].split(','); // ['false', 'true']
      return this.statusOptions // false, true, false, true, false, true
        .filter((opt) => statusOptions.includes(opt.value) && arr.includes(String(opt.state)))
        .map((opt) => opt.value);
    },
  },
};
</script>
