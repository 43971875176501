<template>
  <div class="p-card">
    <div
      v-if="actions && actions.length > 0"
      ref="actions"
      class="hide-print flexy-row-actions align-items-baseline justify-space-between"
    >
      <div class="px-2 filter-popover-boxes no-wrap">
        <p class="small-text subtle-text inline-block pr-2 no-wrap">
          Jobylon data
        </p>
      </div>
      <div
        class="filter-popover-boxes filter-popover-btns"
      >
        <template v-if="actions.indexOf('downloadPng') > -1">
          <button
            v-tooltip="downloadingCurrentCard
              ? $gettext('Laddar ner cardet som bild')
              : $gettext('Ladda ner cardet som bild')"
            :aria-disabled="downloadingCurrentCard || cardLoading"
            class="btn btn-icon-text btn-thin btn-secondary strong"
            :tabindex="visible ? null : -1"
            @click.prevent="downloadCardPng"
          >
            <span v-if="!downloadingCurrentCard">
              <i class="zmdi zmdi-download ml-0" /> {{ $gettext('Ladda ner') }}
            </span>
            <span v-else>
              <i class="zmdi zmdi-spinner zmdi-hc-spin ml-0" />
              {{ $gettext('Laddar ner') }}
            </span>
          </button>
        </template>
        <template v-if="actions.indexOf('editButton') > -1">
          <button
            v-tooltip="$gettext('Ändra cardet')"
            class="btn btn-primary btn-thin btn-icon-text"
            :tabindex="visible ? null : -1"
            @click.prevent="openCardEditModal"
          >
            <i class="zmdi zmdi-edit ml-0" /> <span>{{ $gettext('Ändra') }}</span>
          </button>
        </template>
        <template v-if="actions.indexOf('removeButton') > -1">
          <button
            v-tooltip="$gettext('Ta bort cardet')"
            class="btn btn-failure btn-thin btn-icon btn-icon-sm"
            :tabindex="visible ? null : -1"
            @click.prevent="deleteCard"
          >
            <i class="zmdi zmdi-delete ml-0" />
          </button>
        </template>
      </div>
    </div>
    <h3
      :class="{
        'text-wrap': card.metadata.graphType.selected !== 'T3MiniValue',
        'no-wrap mt-2': card.metadata.graphType.selected === 'T3MiniValue'
      }"
      class="tc-card-header-flex-title h2"
      :title="name || card.metadata.name"
    >
      <template v-if="cardIcon">
        <i
          v-if="typeof cardIcon === 'string'"
          class="mr-1"
          :class="cardIcon"
        />
        <component
          :is="cardIcon ?? 'span'"
          v-else
          class="mr-1 valign-middle"
        />
      </template>
      <span>
        {{ name || card.metadata.name }}
      </span>
    </h3>
    <p
      v-if="description || card.metadata.description"
      class="text-wrap-paragraphable subtle-text my-0 small-text"
    >
      {{ description || card.metadata.description }}
    </p>
    <!-- <card-filter-badges
      :context-benchmark="allowlistedContextBenchmark"
      :card-metadata="card.metadata"
      :compiled-benchmark="allowlistedCompiledBenchmark"
      :compiled-filter="allowlistedCompiledFilter"
      :compiled-compare="allowlistedCompiledCompare"
      :aria-disabled="!isTemporaryBoard && (isInBoard && !isEditMode)"
      @open-edit-modal="isTemporaryBoard || (isInBoard && isEditMode) ? openCardEditModal($event) : null"
    /> -->
    <div
      v-if="canGroupBy"
      class="my-1"
    >
      <label class="mr-1">{{ $gettext('Baserat på:') }}</label>
      <tc-select-inline
        class="text-sm"
        :options="selectableGroupBy"
        :fallback="selectableGroupBy[0]"
        @update:model-value="$emit('group-by-change', $event)"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
// import { processText } from 'Utils/general';
import { getAllowlistCompiledFilter, KEY_METRIC_GRAPH_TYPES, getCardIcon } from 'Utils/graph';
import dynamicImport from 'Utils/dynamicImport';
import { clone } from 'Utils/card';
import eventBus from 'Utils/eventBus';
import { confirm } from 'Modal/dialogBoxes';
// import CardFilterBadges from 'Components/parts/filters/CardFilterBadges';
import TcSelectInline from 'Components/parts/TCSelectInline';

export default {
  components: {
    // CardFilterBadges,
    TcSelectInline,
  },
  props: {
    cardLoading: Boolean,
    card: {
      type: Object,
      required: true,
    },
    board: Object,
    actions: Array,
    links: Array,
    visible: {
      type: Boolean,
      default: true,
    },
    contextFilter: {
      type: Object,
      default: () => ({}),
    },
    contextBenchmark: {
      type: Object,
      default: () => ({}),
    },
    contextCompare: [Object, null],
    compiledFilter: {
      type: Object,
      default: () => ({}),
    },
    compiledBenchmark: {
      type: Object,
      default: () => ({}),
    },
    compiledCompare: [Object, null],
    hydrateProps: { // ? Fill in comp from outside
      type: [Object, null],
      default: null,
    },
    isInBoard: {
      type: Boolean,
      default: false,
    },
    previewMode: {
      type: Boolean,
      default: false,
    },
    temporaryCard: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      required: false,
    },
    description: {
      type: String,
      required: false,
    },
    canGroupBy: {
      type: Boolean,
      default: false,
    },
    selectableGroupBy: {
      type: Array,
      default: () => [],
    },
    selectedGroupBy: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['update-drag-area', 'download-card-png', 'group-by-change'],
  data() {
    return {
      question: {},
    };
  },
  computed: {
    ...mapGetters([
      'textFilter',
      'downloadingCard',
      'isEditMode',
    ]),
    downloadingCurrentCard() {
      return this.downloadingCard.includes(this.card.id);
    },
    isTemporaryBoard() { return this.isInBoard && this.temporaryCard; },
    allowlistedCompiledFilter() {
      return getAllowlistCompiledFilter({ filter: this.compiledFilter, graphTypeSelected: this.graphType, cardType: 'keyMetric' });
    },
    allowlistedCompiledBenchmark() {
      return getAllowlistCompiledFilter({ filter: this.compiledBenchmark, graphTypeSelected: this.graphType, cardType: 'keyMetric' });
    },
    allowlistedCompiledCompare() {
      if (['line', 'bar'].includes(this.card.metadata.graphType?.settings?.[KEY_METRIC_GRAPH_TYPES.ResponseRate]?.type)) return this.compiledCompare;
      return null;
    },
    allowlistedContextBenchmark() {
      return getAllowlistCompiledFilter({ filter: this.contextBenchmark, graphTypeSelected: this.graphType, cardType: 'keyMetric' });
    },
    asideSettings() {
      const settings = [];
      if (this.links && this.links.length > 0) {
        settings.push({
          value: 'links',
          label: this.$pgettext('Title — Card Actions', 'Alternativ'),
          context: this.links.reduce((acc, link) => {
            if (link === 'clone') {
              acc.push({
                click: clone,
                label: this.$gettext('Duplicera cardet'),
              });
            }
            return acc;
          }, []),
        });
      }
      settings.push({
        value: 'flags',
        label: this.$gettext('Visa'),
      });
      return settings;
    },
    graphType() {
      return this.card?.metadata?.graphType?.selected || '';
    },
    cardIcon() {
      if (this.graphType === 'T3MiniValue') return getCardIcon(this.card?.metadata?.metric);
      return '';
    },
  },
  methods: {
    ...mapActions([
      'addCloseHandler',
      'removeCloseHandler',
      'removeCard',
      'notify',
      'openModal',
      'setDownloadingCard',
    ]),
    downloadCardPng() {
      if (this.downloadingCurrentCard || this.cardLoading) return;
      this.setDownloadingCard(this.card.id);
      if (this.graphType === KEY_METRIC_GRAPH_TYPES.SWRanking
        || this.graphType === KEY_METRIC_GRAPH_TYPES.PerformanceScore) {
        eventBus.$emit(`expand-card-${this.card.id}`);
      } else this.$emit('download-card-png');
    },
    deleteCard() {
      confirm({
        title: this.$pgettext('Confirm — Delete card title', 'Är du säker på att du vill ta bort cardet?'),
        confirmText: this.$pgettext('Confirm — Delete card confirmText', 'Ta bort card'),
        dismissText: this.$pgettext('Confirm — Delete card dismissText', 'Avbryt'),
        theme: 'danger',
      })
        .then((confirmed) => {
          if (!confirmed) return Promise.resolve();
          return this.removeCard({ cardId: this.card.id, card: this.card })
            .then(() => { eventBus.$emit('remove-card', this.card); })
            .catch((err) => {
              this.notify({
                type: 'pop',
                level: 'error',
                text: this.$gettext('Kunde inte ta bort cardet. %{err}', { err: err?.response?.body?.detail || err?.response || err }),
              });
            });
        });
    },
    openCardEditModal() {
      const CardEditDetails = dynamicImport(() => import(/* webpackChunkName: "CardEditDetails", webpackPrefetch: true */ 'Components/parts/filters/CardEditDetails'));
      this.openModal({
        name: 'CardEditDetails',
        component: CardEditDetails,
        size: 'xlarge',
        dataObject: {
          props: {
            card: this.card,
            board: this.board,
            contextFilter: this.contextFilter,
            contextBenchmark: this.contextBenchmark,
            contextCompare: this.contextCompare,
            asideSettings: this.asideSettings,
            hydrateProps: this.hydrateProps,
            temporaryCard: this.temporaryCard,
          },
        },
        callback: (newDataObject) => {
          this.$nextTick(() => {
            if (this.isInBoard) this.$emit('update-drag-area');
          });
        },
      });
    },
  },
};
</script>
