<template>
  <header id="print-header">
    <h1 class="h4">
      <a
        :href="reportUrl"
        tabindex="1"
      >
        {{ $pgettext('Print — Header title', 'Gå till rapport') }}<i class="zmdi zmdi-open-in-new pl-2" />
      </a>
    </h1>
    <time
      class="h4"
      :datetime="currentDate.value"
    >
      {{ currentDate.label }}
    </time>
  </header>
</template>

<script>
import { mapGetters } from 'vuex';
import { format } from 'Utils/dateHelpers';
import { SITE_URL } from 'Utils/urls';

export default {
  data() {
    return {
      reportUrl: SITE_URL,
    };
  },
  computed: {
    ...mapGetters(['segmentId']),
    currentDate() {
      return {
        label: format(new Date(), 'd MMM yyyy'), // date.format('ll - LTS'),
        value: format(new Date(), "yyyy-MM-dd'T'HH:mm:ssxxx"),
      };
    },
  },
  mounted() {
    if (this.$route.params.boardId) {
      this.reportUrl = `${SITE_URL}/segment/${this.segmentId}/board/${this.$route.params.boardId}`;
    }
  },
};
</script>
