<template>
  <li
    class="tc-list-group-item no-border tc-list-group-item-slim grid tc-list-group-item-hover"
    :class="{
      'current': isCurrentSegment,
      'disabled': item.isOnlyInternalBenchmark,
    }"
  >
    <div class="pr-0">
      <h3 class="tabular-nums mb-0">
        {{ placement }}.
      </h3>
    </div>
    <div class="no-wrap">
      <!-- v-tooltip="{
        theme: 'ellipsis-full',
        content: usecase === 'performance' ? processText(item.title) : '',
      }" -->
      <p
        v-if="item.isOnlyInternalBenchmark"
        v-tooltip="$pgettext(
          'Tooltip — Perf Indicator custom question',
          'Denna fråga kan bara jämföras internt, då ert företag är de enda som använder den.'
        )"
        class="pretty-subtle-text small-text inline-block mb-1 cursor-help"
      >
        {{ $pgettext(
          'Text — Perf Indicator custom question',
          'Endast intern benchmark'
        ) }}
        <i class="zmdi zmdi-hc-fw zmdi-help-outline" />
      </p>
      <h3
        :title="processText(item.title)"
        class="mb-0 text-left no-wrap"
        :class="{
          'tc-loading-text tc-loading-text-on-dark size-xsmall inline': skeletonLoader,
          'stop-animation': !loading
        }"
      >
        <span
          v-if="item?.step"
          :title="translateTerm(item.step)"
          class="subtle-text"
          :class="{
            'tc-loading-text tc-loading-text-inherit tc-loading-text-transparent size-xsmall': skeletonLoader,
            'stop-animation': !loading
          }"
          v-text="`${translateTerm(item.step)} — `"
        />
        {{ processText(item.title) }}
      </h3>
    </div>
    <div class="tc-performance-score-indicator-wrapper">
      <performance-score-indicator
        :score="item.score"
        :comparable-option="comparableOption"
        :comment="item.comment"
        :is-only-internal-benchmark="item.isOnlyInternalBenchmark"
        :show-compare="true"
        :usecase="usecase"
        :respondent-count="item.respondent_count"
      />
    </div>
  </li>
</template>

<script>
import { mapGetters } from 'vuex';
import { translateTerm, processText } from 'Utils/general';
import PerformanceScoreIndicator from 'Components/parts/graph/PerformanceScoreIndicator';

export default {
  name: 'PerformanceScoreTableRow',
  components: {
    PerformanceScoreIndicator,
  },
  props: {
    item: Object,
    placement: Number,
    loading: {
      type: Boolean,
      default: false,
    },
    skeletonLoader: {
      type: Boolean,
      default: false,
    },
    comparableOption: Array,
    usecase: {
      type: String,
      default: 'performance',
      validator: (val) => ['performance', 'swot'].includes(val),
    },
  },
  computed: {
    ...mapGetters([
      'segmentName',
    ]),
    isCurrentSegment() {
      return this.segmentName === this.item.title;
    },
  },
  methods: {
    processText,
    translateTerm,
  },
};
</script>
