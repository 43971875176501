<template>
  <div class="tc-search-box-wrapper mb-2">
    <div class="tc-search-wrapper">
      <div
        ref="container"
        class="tc-select"
        @click.stop="toggleInput(true)"
      >
        <div
          class="tc-select-control form-control-wrap"
          data-input="true"
        >
          <div class="tc-select-values-wrapper ">
            <span class="tc-select-input">

              <input
                ref="searchField"
                v-model="searchTerm"
                class="form-control"
                type="search"
                :placeholder="placeholder"
                @keypress.enter.stop.prevent="submitForm"
                @keyup.esc="toggleInput(false)"
                @click.stop="toggleInput(true)"
                @input="(e) => { if (e.srcElement.value === '') toggleInput(true)}"
              >
            </span>
          </div>
          <button
            v-if="searchTerm"
            class="form-control-clear-beside"
            :title="$pgettext('Tooltip — Clear beside search', 'Töm sökningen')"
            @click.stop.prevent="clearSearch"
          >
            <i class="zmdi zmdi-close-circle zmdi-hc-lg" />
          </button>
        </div>
      </div>
      <button
        ref="submitBtn"
        class="btn btn-primary"
        type="submit"
        @click="emit('update:term', searchTerm)"
      >
        <i class="zmdi zmdi-search" /><span>{{ $gettext('Sök') }}</span>
      </button>
    </div>
  </div>
</template>

<script setup>
import { watch, onMounted, ref } from 'vue';
import gettext from '@/gettext';

const { $gettext, $pgettext } = gettext;

const props = defineProps({
  term: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: () => gettext.$gettext('Sök på fullständig email'),
  },
});

const emit = defineEmits(['update:term']);

const searchTerm = ref('');
const inputActive = ref(false);

watch(() => props.term, (term) => {
  if (term !== undefined && term !== searchTerm.value) searchTerm.value = term;
});

watch(() => searchTerm.value, (newTerm) => {
  if (!newTerm.length && props.term.length) emit('update:term', '');
});

onMounted(() => {
  searchTerm.value = props.term;
});
const searchField = ref(null);
const focus = (el = searchField) => {
  if (el) setTimeout(() => el?.focus?.(), 1);
};

function toggleInput(force = null) {
  if (force !== null) inputActive.value = force;
  else inputActive.value = !inputActive.value;
  if (inputActive.value) {
    document.addEventListener('click', (e) => toggleInput(false));
    focus();
  } else {
    document.removeEventListener('click', (e) => toggleInput(false));
    if (searchField.value) searchField.value.blur();
  }
}

const submitBtn = ref(null);
const submitForm = (evt) => {
  if (submitBtn.value) submitBtn.value.click();
};

const clearSearch = (evt) => {
  searchTerm.value = '';
  emit('update:term', '');
};

defineExpose({
  clearSearch,
});
</script>
