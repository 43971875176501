import { h as createElement } from 'vue';
import { mapGetters, mapState } from 'vuex';
import { getAllowlistCompiledFilter } from 'Utils/graph';
import { filterMerge } from 'Utils/general';
import eventBus from 'Utils/eventBus';
import Card from 'Components/parts/Card';
import requestATSData from '@/api/ats';
import T3Chart from '../graph/T3Chart';
import T3ResultHeader from './T3ResultHeader';

const T3Result = {
  name: 'T3Result',
  props: {
    actions: Array,
    links: Array,
    card: {
      type: Object,
      required: true,
    },
    board: Object,
    contextFilter: {
      type: Object,
      default: () => ({}),
    },
    contextBenchmark: {
      type: Object,
      default: () => ({}),
    },
    contextCompare: {
      type: [Object, null],
      required: false,
    },
    name: {
      type: String,
      required: false,
    },
    description: {
      type: String,
      required: false,
    },
    enableOverlay: {
      type: Boolean,
      default: false,
    },
    isDraggable: {
      type: Boolean,
      default: false,
    },
    temporaryCard: {
      type: Boolean,
      default: false,
    },
    isInBoard: {
      type: Boolean,
      default: false,
    },
    previewMode: {
      type: Boolean,
      default: false,
    },
    hydrateProps: null,
  },
  emits: ['toggle', 'update:col-class', 'update-drag-area', 'loaded', 'download-card-png'],
  data() {
    return {
      loading: false,
      hydratePropsInternal: null,
      responseData: [],
      selectedGroupBy: [],
    };
  },
  mounted() {
    eventBus.$on(`download-card-${this.card.id}`, () => this.$emit('download-card-png'));
    if (this.canGroupBy && this.selectableGroupBy.length > 0 && this.selectedGroupBy.length === 0) {
      this.selectedGroupBy = [this.selectableGroupBy[0]];
    }
    this.load();
  },
  unmounted() {
    eventBus.$off(`download-card-${this.card.id}`);
  },
  computed: {
    ...mapState({
      isOverlayed: (state) => state.boards.isOrganizing,
    }),
    ...mapGetters([
      'segmentId',
      'segmentFilter',
    ]),
    // hasGraph() {
    //   return this.card.metadata?.graphType.selected
    //     && graphTypeElement(GRAPH_TYPE_COMPONENTS, this.card.metadata.graphType.selected);
    // },
    colClass() {
      return this.card.metadata.colClass || 'col-xs-12';
    },
    graphType() { return this.card.metadata.graphType?.selected ?? ''; },
    compiledFilter() {
      return getAllowlistCompiledFilter({ filter: filterMerge(this.segmentFilter, this.contextFilter, this.card.metadata?.filter), graphTypeSelected: this.card.metadata.graphType.selected, cardType: 'JBL' }); // eslint-disable-line max-len
    },
    compiledBenchmark() {
      return getAllowlistCompiledFilter({ filter: filterMerge(this.contextBenchmark, this.card.metadata?.benchmark), graphTypeSelected: this.card.metadata.graphType.selected, cardType: 'JBL' }); // eslint-disable-line max-len
    },
    compiledCompare() {
      if (this.card.metadata?.compare != null) { // ? Doesn’t merge compare values currently
        return { ...this.card.metadata.compare };
      }
      return this.contextCompare;
    },
    canGroupBy() {
      const groupableMetrics = ['time-in-workflow', 'pass-through-rate', 'events-conducted', 'top-sources', 'devices'];
      return groupableMetrics.includes(this.card.metadata.metric);
    },
    selectableGroupBy() {
      switch (this.card.metadata.metric) {
        case 'time-in-workflow':
        case 'events-conducted':
        case 'pass-through-rate':
          return [
            { label: 'Status', value: 'status' },
            { label: 'Status type', value: 'status type' },
          ];
        case 'top-sources':
          return [
            { label: 'Applicants', value: 'Applicants' },
            { label: 'Hired', value: 'Hired' },
            { label: 'Rejected', value: 'Rejected' },
            { label: 'Views', value: 'Views' },
            { label: 'In progress', value: 'In progress' },
          ];
        case 'devices':
          return [
            { label: 'Applicants', value: 'Applicants' },
            { label: 'Views', value: 'Views' },
          ];
        default:
          return [];
      }
    },
  },
  methods: {
    load() {
      this.loading = true;

      const timeout = Math.floor(Math.random() * 5000) + 1000;

      const req = () => requestATSData(this.card.metadata.metric, { groupBy: this.selectedGroupBy[0]?.value ?? [] })
        .then((data) => {
          this.loading = false;
          this.$emit('loaded');
          this.responseData = data;
        })
        .catch((error) => {
          this.loading = false;
          throw error;
        });

      setTimeout(req, timeout);
    },
    handleGroupByChange(value) {
      this.selectedGroupBy = value;
      this.load();
    },
  },
  render() {
    return createElement(
      Card,
      {
        class: 'tc-card-key-metric-wrapper',
        isDraggable: this.isDraggable || null,
        isOverlayed: this.isOverlayed || null,
        enableOverlay: this.enableOverlay || null,
        colClass: this.colClass,
        onToggle: (card) => this.$emit('toggle', card),
        'onUpdate:col-class': (colClass) => this.$emit('update:col-class', colClass),
      },
      {
        header: () => createElement(T3ResultHeader, {
          actions: this.actions,
          links: this.links,
          card: this.card,
          board: this.board,
          name: this.name,
          description: this.description,
          visible: !this.isOverlayed,
          isInBoard: this.isInBoard || null,
          previewMode: this.previewMode || null,
          contextFilter: this.contextFilter,
          contextBenchmark: this.contextBenchmark,
          contextCompare: this.contextCompare,
          compiledFilter: this.compiledFilter,
          compiledBenchmark: this.compiledBenchmark,
          compiledCompare: this.compiledCompare,
          hydrateProps: this.hydrateProps || this.hydratePropsInternal,
          temporaryCard: this.temporaryCard || null,
          cardLoading: this.loading,
          canGroupBy: this.canGroupBy,
          selectableGroupBy: this.selectableGroupBy,
          selectedGroupBy: this.selectedGroupBy,
          onGroupByChange: this.handleGroupByChange,
          'onUpdate-drag-area': () => this.$emit('update-drag-area'),
          'onDownload-card-png': () => {
            this.$emit('download-card-png');
          },
        }),
        body: () => createElement(T3Chart, {
          ref: 't3Chart',
          card: this.card,
          data: this.responseData,
          groupBy: this.selectedGroupBy,
          loading: this.loading,
        }),
      },
    );
  },
};
export default T3Result;
