<template>
  <div
    ref="cnpsBarEl"
    class="tc-cnps-bar"
    :class="{ 'tc-cnps-bar-inactive': !active }"
    @mouseover="createTooltip($refs?.cnpsBarEl, {
      content: tooltipTranslations.detractors
        + tooltipTranslations.passives
        + tooltipTranslations.promoters,
      container: 'body',
      html: true,
    })"
    @mouseleave="destroyTooltip($refs?.cnpsBarEl)"
  >
    <div
      class="tc-cnps-bar-detractors"
      :style="`width: ${npsStats.detractors.percentage}%`"
    />
    <div
      class="tc-cnps-bar-passives"
      :style="`width: ${npsStats.passives.percentage}%`"
    />
    <div
      class="tc-cnps-bar-promoters"
      :style="`width: ${npsStats.promoters.percentage}%`"
    />
  </div>
</template>
<script>
import { vOnClickOutside } from '@vueuse/components';
import { getSum } from 'Utils/graph';
import { NPS_NAMES } from 'Utils/question';
import { createTooltip, destroyTooltip } from 'Components/parts/widgets/PerformantTooltip';

export default {
  directives: {
    onClickOutside: vOnClickOutside,
  },
  props: {
    stats: {
      type: [Object, undefined],
    },
  },
  computed: {
    tooltipTranslations() {
      return {
        detractors: `<p class="mb-2"><i class="zmdi zmdi-circle tc-cnps-text-detractors mr-1"></i> ${NPS_NAMES().detractor} : <strong>${this.npsStats.detractors.percentage.toFixed(2)}%</strong> (${this.npsStats.detractors.count})</p>`,
        passives: `<p class="mb-2"><i class="zmdi zmdi-circle tc-cnps-text-passives mr-1"></i>  ${NPS_NAMES().passive} : <strong>${this.npsStats.passives.percentage.toFixed(2)}%</strong> (${this.npsStats.passives.count})</p>`,
        promoters: `<p><i class="zmdi zmdi-circle tc-cnps-text-promoters mr-1"></i> ${NPS_NAMES().promoter} : <strong>${this.npsStats.promoters.percentage.toFixed(2)}%</strong> (${this.npsStats.promoters.count})</p>`,
      };
    },
    total() {
      return this.stats === undefined ? 0 : getSum(this.stats);
    },
    active() {
      return this.stats !== undefined && this.total > 0;
    },
    npsStats() {
      if (this.stats === undefined || this.total === 0) {
        return {
          detractors: {
            percentage: 0,
            count: 0,
          },
          passives: {
            percentage: 0,
            count: 0,
          },
          promoters: {
            percentage: 0,
            count: 0,
          },
        };
      }

      const detractorsCount = this.stats.detractor ?? 0;
      const promotersCount = this.stats.promoter ?? 0;
      const detractorsPercentage = detractorsCount / this.total * 100;
      const promotersPercentage = promotersCount / this.total * 100;

      return {
        detractors: {
          count: detractorsCount,
          percentage: detractorsPercentage,
        },
        promoters: {
          count: promotersCount,
          percentage: promotersPercentage,
        },
        passives: {
          count: this.total - promotersCount - detractorsCount,
          percentage: 100 - detractorsPercentage - promotersPercentage,
        },
      };
    },
  },
  methods: {
    createTooltip,
    destroyTooltip,
  },
};
</script>
