<template>
  <form
    :aria-disabled="disabledForm || null"
    @submit.prevent="createInvites"
  >
    <fieldset
      class="form-group"
      :disabled="disabledForm"
    >
      <template
        v-for="(input, index) in emails"
        :key="`input${index}`"
      >
        <invite-input
          :ref="`input${index}`"
          v-model:email="emails[index]"
          type="segment"
        />
      </template>
      <div
        v-if="type === 'segment'"
        class="form-group"
      >
        <button
          class="btn btn-ghost"
          @click.prevent="addInput()"
        >
          {{ $gettext('Lägg till fler emails') }}
        </button>
      </div>
      <div
        v-if="type === 'customer'"
        class="form-group"
      >
        <label class="btn-group btn-group-toggle mt-6 mb-8">
          <button
            type="button"
            class="btn btn-toggle"
            :class="{ 'toggled': admin }"
            @click.prevent="admin = !admin"
          />
          <span>{{ $gettext('Bjud in som administratör') }}</span>
        </label>
      </div>
      <div class="form-group">
        <div class="row">
          <div class="col-md-6 offset-md-6">
            <button
              type="submit"
              class="btn btn-block"
              :class="{'btn-loading': loading, 'btn-loaded': finishedLoading}"
              :disabled="loading > 0"
            >
              <i class="zmdi zmdi-mail-send mr-2" /><span>{{ $gettext('Skicka inbjudningar') }}</span>
            </button>
          </div>
        </div>
      </div>
    </fieldset>
  </form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { create as customerInviteCreate } from 'API/customerUserInvitation';
import { create as segmentInviteCreate } from 'API/segmentUserInvitation';
import { handleErrorResponse } from 'Utils/views';
import InviteInput from './UserInviteInput';

class InviteEmail {
  constructor(e = null, required = false) {
    this.email = e;
    this.valid = true;
    this.validationMessage = '';
    this.required = required;
    this.autofocus = required;
    this.sending = false;
  }
}

export default {
  components: {
    InviteInput,
  },
  props: {
    type: {
      type: String,
      default: 'segment',
    },
    singleSegmentId: {
      required: false,
      type: [Number, String],
    },
    disabledForm: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['sent'],
  data() {
    return {
      emails: [],
      admin: false,
      loading: false,
      finishedLoading: false,
      buttonTimeout: 2000,
      createInviteAPI: {},
      scopeId: false,
    };
  },
  computed: {
    ...mapGetters([
      'segmentId',
      'customerId',
      'segment',
    ]),
  },
  watch: {
    type: {
      immediate: true,
      handler(val) { if (val === 'segment') this.admin = true; },
    },
    segmentId(newVal) {
      if (this.type === 'segment') this.scopeId = newVal;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.load();
    });
  },
  methods: {
    ...mapActions([
      'notify',
    ]),
    renderInputs(pastedText) {
      let mails = pastedText.split(/\r?\n/);
      mails.forEach((mail) => {
        this.addInput(mail);
      });
    },
    clearInput(index) {
      this.emails[index].email = '';
      // focus if first
      if (index === 0 && this.$refs?.input0?.[0]?.$refs?.email) {
        this.$refs.input0[0].$refs.email.focus();
      }
    },
    addInput(value = null) {
      this.emails.push(new InviteEmail(value));
    },
    createInvites(e, emails = this.emails) {
      if (this.disabledForm) {
        e.preventDefault();
        e.stopPropagation();
        return;
      }
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        this.finishedLoading = true;
      }, this.buttonTimeout);
      emails.forEach((email, index) => {
        // remove server side validation stuff
        email.valid = true;
        email.validationMessage = '';
        setTimeout(() => {
          this.$refs[`input${index}`][0].updateValidity();
        }, 1);
        // supress request
        if (!email.email) {
          return;
        }
        // or make new request
        this.createInviteAPI(this.scopeId, email.email, this.admin)
          .then((response) => {
            this.successMessage(response, email, index);
          }, (error) => this.handleError(error, email));
      });
    },
    handleError(error, email) {
      // output notification of failure
      let translated = this.$gettext('Inbjudan misslyckades. (%{em})');
      let em = email.email;

      return this.notify({
        text: this.$gettextInterpolate(translated, { em }),
        level: 'error',
      }).then(() => {
        // general errors
        if ((error?.response?.body || {})?.email === undefined) {
          email.valid = true;
          email.validationMessage = '';
          handleErrorResponse(error, true);
        } else {
          // form error
          email.valid = false;
          email.validationMessage = error?.response?.body?.email?.[0] || '';
        }
      });
    },
    successMessage(response, email, index) {
      if (this.type !== 'segment') this.admin = false;
      this.clearInput(index);
      let translated = this.$gettext('Inbjudan skickad till %{email}');
      this.notify({
        title: this.$gettext('Inbjudan skickad!'),
        text: this.$gettextInterpolate(translated, { email: response.email }),
        level: 'success',
        scrub: true,
        type: 'pop',
      })
        .then(() => {
          this.$emit('sent');
        });
    },
    load() {
      const segmentId = this.singleSegmentId ? this.singleSegmentId : this.segmentId;
      this.createInviteAPI = this.type === 'segment' ? segmentInviteCreate : customerInviteCreate;
      this.scopeId = this.type === 'segment' ? segmentId : this.customerId;
      this.emails.push(new InviteEmail('', true));
      if (this.type === 'segment') {
        this.addInput();
        this.addInput();
      }
      setTimeout(() => {
        if (this.$refs?.input0?.[0]?.$refs?.email) this.$refs.input0[0].$refs.email.focus();
      }, 100);
    },
  },
};
</script>
