<template>
  <li
    v-tooltip.left="props.option?.tooltip ? {
      content: props.option.tooltip,
      container: 'body',
      distance: 6,
    } : false"
    class="dropdown-select-list-item tc-select-options-list-item tc-select-values-wrapper"
    :class="{
      'tc-color-brand': props.option?.selected,
      'disabled': props.option?.disabled,
    }"
    :aria-disabled="props.option?.disabled"
    v-bind="$attrs"
    @click="select(props.option.value, typeof props.option?.sublist === 'function')"
    @keypress.enter="select(props.option.value, typeof props.option?.sublist === 'function')"
  >
    <i
      v-if="props.option?.selected"
      class="zmdi zmdi-hc-fw zmdi-check mr-1"
    />
    <svg
      v-else-if="props.option?.icon === 'zmdi-check-all'"
      width="16"
      height="16"
      class="zmdi zmdi-hc-fw mr-1"
      viewBox="0 0 16 16"
    >
      <use
        xlink:href="#fluent-comment-multiple-checkmark-16-regular"
        fill="currentColor"
      />
    </svg>
    <svg
      v-else-if="props.option?.icon === 'zmdi-format-list-numbered'"
      width="16"
      height="16"
      class="zmdi zmdi-hc-fw mr-1"
      viewBox="0 0 12 12"
    >
      <use
        xlink:href="#fluent-comment-checkmark-12-regular"
        fill="currentColor"
      />
    </svg>
    <i
      v-else-if="props.option?.icon"
      class="zmdi zmdi-hc-fw mr-1"
      :class="{ [props.option.icon]: true }"
    />
    <i
      v-else
      class="zmdi-hc-fw mr-1"
    />
    <p
      :id="`aria-${props.option.value}`"
      v-html="(props.option?.marked || props.option?.label)"
    />
    <span
      v-if="props.option?.sidelabel"
      class="item-sidelabel"
    >{{ props.option.sidelabel }}</span>
  </li>
</template>

<script setup>
const props = defineProps({
  option: {
    type: Object,
    default: () => ({}),
  },
});

const emit = defineEmits(['selected']);

const select = (selectedOption, isSublist = false) => {
  if (props.option?.disabled) return;
  emit('selected', selectedOption, isSublist);
};

</script>
