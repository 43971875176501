<template>
  <div class="p-card">
    <component
      :is="chart.component"
      v-if="chart.component && chart.props"
      :data="chart.data"
      v-bind="chart.props"
      portal="body"
    />
    <div v-else>
      <p>{{ props.card.metadata.graphType.selected }}</p>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import {
  T3BarChart,
  T3LineChart,
  T3PieChart,
  T3FunnelChart,
  T3MiniValueChart,
  T3PillChart,
} from '@trustcruit/t3-charts';

const JBL_GRAPH_TYPE_COMPONENTS = {
  T3BarChart,
  T3LineChart,
  T3PieChart,
  T3FunnelChart,
  T3MiniValue: T3MiniValueChart,
  T3PillChart,
};

const props = defineProps({
  card: Object,
  data: Object,
  groupBy: Object,
  loading: Boolean,
});

const graphType = computed(() => props.card.metadata.graphType);

const chartProps = computed(() => {
  switch (props.card.metadata.metric) {
    case 'job-ad-views':
      return {
        accessor: 'date',
        series: ['views', 'applications'],
        seriesFormatter: (key) => {
          const labels = {
            views: 'Views',
            applications: 'Applications',
          };
          return labels[key];
        },
        accessorFormatter: (value) => {
          const date = new Date(value);
          const month = `0${date.getMonth() + 1}`.slice(-2);
          const year = date.getFullYear();
          return `${year}-${month}`;
        },
        type: graphType.value?.settings[graphType.value?.selected]?.type ?? 'line',
      };
    case 'time-to-fill':
      return {
        accessor: 'key',
        series: ['count'],
        accessorFormatter: (value) => `${value} days`,
      };
    case 'time-in-workflow':
      return {
        accessor: 'key',
        series: ['count'],
        sectionGap: 2,
      };
    case 'candidates-over-time':
      return {
        accessor: 'date',
        series: ['applied', 'copied'],
        seriesFormatter: (key) => {
          const labels = {
            applied: 'Applied',
            copied: 'Copied',
          };
          return labels[key];
        },
      };
    case 'pass-through-rate':
      return {
        accessor: 'key',
        accessorFormatter: (key) => {
          const labels = {
            new: 'New',
            screening: 'Screening',
            interview: 'Interview',
            offer: 'Offer',
          };
          return labels[key] || key;
        },
        series: ['count'],
        minSegmentWidth: 170,
        loading: props.loading,
      };
    case 'top-sources':
      return {
        accessorFormatter: (key) => {
          const labels = {
            arbetsformedlingen: 'AF',
            direct: 'Direct',
            ledigajobb: 'ledigajobb.se',
            vakanser: 'Vakanser',
            linkedin: 'LinkedIn',
            monster: 'monster.se',
            upload: 'Upload',
          };
          return labels[key];
        },
        cornerRadius: 5,
      };
    case 'lost-reasons':
      return {
        accessor: 'key',
        series: ['count'],
        orientation: 'horizontal',
      };
    case 'devices':
      return {
        accessor: 'key',
        series: ['count'],
        accessorFormatter: (key) => (
          {
            desktop: 'Desktop',
            mobile: 'Mobile',
            other: 'Other',
          }?.[key] ?? key
        ),
        cornerRadius: 5,
      };
    case 'events-conducted':
      return {
        accessor: 'status',
        series: props.groupBy?.[0]?.value === 'status' ? ['value']
          : ['screening', 'testSent', 'testCompleted', 'firstInterview', 'interviewCompleted', 'rejected', 'hired', 'withdrawn', 'secondInterview'],
        seriesFormatter: (key) => {
          const labels = {
            value: 'All data',
            testSent: 'Test sent',
            testCompleted: 'Test completed',
            firstInterview: 'First interview',
            interviewCompleted: 'Interview completed',
            rejected: 'Rejected',
            hired: 'Hired',
            withdrawn: 'Withdrawn',
            secondInterview: 'Second interview',
          };
          return labels?.[key] ?? key;
        },
        loading: props.loading,
        orientation: 'horizontal',
        strategy: 'stacked',

      };
    case 'time-to-hire':
      return {
        accessor: '',
        series: ['value', 'trend'],
        unit: 'days',
      };
    case 'total-candidates':
      return {
        accessor: '',
        series: ['value', 'trend'],
      };
    case 'published-jobs':
      return {
        accessor: '',
        series: ['value', 'trend'],
      };
    case 'total-hires':
      return {
        accessor: '',
        series: ['value', 'trend'],
      };
    case 'rejection-communicated':
      return {
        accessor: '',
        series: ['value', 'trend'],
        unit: '%',
      };
    case 'candidates-per-job':
      return {
        accessor: '',
        series: ['value', 'trend'],
      };
    case 'open-jobs':
      return {
        accessor: '',
        series: ['value', 'trend'],
      };
    case 'application-completion':
      return {
        accessor: '',
        series: ['value', 'trend'],
        unit: '%',
      };
    case 'dropoff-rates':
      return {
        accessor: 'key',
        series: ['count', 'benchmarkPerc'],
        gapSize: 16,
        cornerRadius: 8,
      };
    default:
      return null;
  }
});

const data = computed(() => props.data);

const chart = computed(() => ({
  component: JBL_GRAPH_TYPE_COMPONENTS[props.card.metadata.graphType.selected],
  data: data.value,
  props: chartProps.value,
}));
</script>
